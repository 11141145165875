import dayjs from "dayjs"
import { validateCPF } from "../../utils"
import { ReactComponent as IconCheckO } from "../../assets/icon-primary-check-o.svg"
import { ReactComponent as IconWarning } from "../../assets/icon-simple-warning.svg"

export const getCurrentHealthStatus = (pet) => {
    if (pet?.plan?.status_id == 1 && pet.num_chip && !pet.chip_temporary_expire_at) {
        return "active"
    } else if (pet?.plan?.status_id == 1 && pet.chip_temporary_expire_at) {
        return "microchip_temporary"
    } else if (pet?.plan?.status_id == 2) {
        return "pending"
    } else if (pet?.plan?.status_id == 1 && !pet.num_chip && !pet.chip_temporary_expire_at) {
        return "microchip_pending"
    } else if (pet?.plan?.status_id == 3) {
        return "blocked"
    } else if (pet?.plan?.status_id == 4) {
        return "canceled"
    } else {
        return null
    }
}

export const getHealthStatusOptions = pet => ([
    {
        id: "active",
        icon: <IconCheckO width="20" height="20" fill="#17624C" />,
        className: "alert-success",
        details: true
    },
    {
        id: "microchip_temporary",
        icon: <IconCheckO width="20" height="20" fill="#17624C" />,
        className: "alert-success",
        aditionalContent: (
            <>
                Microchip provisório • Expira em: <b>{ pet?.chip_temporary_expire_at ? dayjs(pet.chip_temporary_expire_at).format("DD/MM/YYYY") : "Não informado" }</b>
            </>
        ),
        details: true
    },
    {
        id: "pending",
        icon: <IconWarning width="20" height="20" fill="#934814" />,
        className: "alert-warning",
        aditionalContent: (
            <>Assinatura Pendente</>
        )
    },
    {
        id: "microchip_pending",
        icon: <IconWarning width="20" height="20" fill="#934814" />,
        className: "alert-warning",
        aditionalContent: (
            <>Microchipagem Pendente</>
        )
    },
    {
        id: "blocked",
        icon: <IconWarning width="20" height="20" fill="#87172D" />,
        className: "alert-danger",
        aditionalContent: (
            <>Bloqueado</>
        )
    },
    {
        id: "canceled",
        icon: <IconWarning width="20" height="20" fill="#87172D" />,
        className: "alert-danger",
        aditionalContent: (
            <>Cancelado</>
        )
    }
])

export const validateMicrochip = (microchip) => {
    const sanitizedMicrochip = String(microchip).replaceAll(/[^a-zA-Z0-9]/g, "")
    if (!sanitizedMicrochip) {
        return null
    }
    if (sanitizedMicrochip.length < 6) {
        return null
    }
    if (sanitizedMicrochip.length > 15) {
        return null
    }
    // To prevent "não possui" be a valid
    const microchipMustBeHaveLeastThreeDigits = /[0-9]{3,}/g.test(sanitizedMicrochip)
    if (!microchipMustBeHaveLeastThreeDigits) {
        return null
    }
    return sanitizedMicrochip
}

export const getParamsToPetloveSaude = (microchip, cpf) => {
    const params = {}
    if (validateMicrochip(microchip)) {
        params.microchip = microchip.replaceAll(/[^a-zA-Z0-9]/g, "")
    } else if (validateCPF(cpf)) {
        params.cpf = cpf.replaceAll(/[^\d]/g, "")
    }
    if (Object.keys(params).length > 0) {
        return params
    } else {
        return null
    }
}

export const getPetloveSaudePlan = (microchip, cpf) => {
    return new Promise((resolve, reject) => {
        const params = getParamsToPetloveSaude(microchip, cpf)
        if (params) {
            Parse.Cloud.run("healthCustomer", params)
            .then(({ data }) => {
                resolve(data)
            })
            .catch(error => {
                console.error(error)
                resolve(null)
            })
        } else {
            resolve(null)
        }
    })
}

export const getPetloveSaudeCoverage = (microchip, cpf) => {
    const params = getParamsToPetloveSaude(microchip, cpf)
    if(params){
        return Parse.Cloud.run("healthCoverage", params)
        .catch(_error => {
            const error = error || "Não foi possível buscar as informações do plano Petlove Saúde."
            console.error(error)
            return Promise.reject(error)
        })
    } else {
        return null
    }
}

export const getOwnerReduxFromOwnerPetlove = (customer) => {
    if (!customer) {
        return null
    }
    const cpfFormattedRegex = /([0-9]{3}(\.|\-)){3}[0-9]{2}/gm
    if (cpfFormattedRegex.exec(customer?.cpf) == null) {
        const cpfRegex = /^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})$/gm
        const cpfSubstitution = `$1\.$2\.$3\-$4`
        customer.cpf = String(customer?.cpf).replace(cpfRegex, cpfSubstitution)
    }
    const phoneFormattedRegex = /\([0-9]{2}\)\ [0-9]{4,5}\-[0-9]{4}/gm
    if (phoneFormattedRegex.exec(customer?.phone || customer.cellphone) == null) {
        const cellphone = customer.phone?.length == 11 ? customer.phone : customer.cellphone
        const phone = customer.phone?.length == 11 ? customer.cellphone : customer.phone
        const phoneRegex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/gm
        const phoneSubstitution = `\($1\) $2\-$3`
        customer.phone = phone ? String(phone).replace(phoneRegex, phoneSubstitution) : null
        customer.cellphone = cellphone ? String(cellphone).replace(phoneRegex, phoneSubstitution) : null
    }
    const birthdateFormattedRegex = /([0-9]{2}\/){2}[0-9]{4}/gm
    if (birthdateFormattedRegex.exec(customer?.birth_date) == null) {
        const birthdate = customer.birth_date ? dayjs(customer.birth_date).format("DD/MM/YYYY") : ""
        customer.birth_date = birthdate
    }
    return customer
}

export const getPatientReduxFromPatientPetlove = (pet) => {
    if (!pet) {
        return null
    }
    return {
        ...pet,
        specie: pet.specie == "Cachorro" ? "Canino" : (pet.specie == "Gato" ? "Felino" : pet.specie),
        gender: pet.gender == "M" ? "Macho" : (pet.gender == "F" ? "Fêmea" : pet.gender),
        dt_birthday: pet.dt_birthday ? dayjs(pet.dt_birthday).toDate() : null
    }
}

export const getOwnerReduxFromOwnerVetsmart = (owner) => {
    return {
        id: owner?.id,
        name: owner?.get("name") || "",
        birthdate: owner?.get("birthdate") || "",
        cpf: owner?.get("cpf") || "",
        landlinePhone: owner?.get("landlinePhone") || "",
        phone: owner?.get("phone") || "",
        email: owner?.get("email") || "",
        rg: owner?.get("rg") || "",
        internationalId: owner?.get("internationalId") || "",
        zipCode: owner?.get("zipCode") || "",
        addressStreet: owner?.get("address") || "",
        addressNumber: owner?.get("addressNumber") || "",
        addressComplement: owner?.get("addressComplement") || "",
        neighborhood: owner?.get("neighborhood") || "",
        city: owner?.get("city") || "",
        stateId: owner?.get("stateId") || "",
        notes: owner?.get("notes") || "",
        referredBy: owner?.get("referredBy") || "",
        petloveSaudeId: null,
        petloveSaudeData: null,
        selected: null
    }
}