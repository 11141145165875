import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types';

import VSTooltip from '../vsTooltip/VSTooltip'
import VSDrawerCancel from './VSDrawerCancel'

import './VSDrawer.scss'
import { CircularProgress, SwipeableDrawer } from '@mui/material'
import VSDrawerSuccess from './VSDrawerSuccess';
import VSDrawerHeader from './VSDrawerHeader';
import VSDrawerFooter from './VSDrawerFooter';

export const STATUS_CANCEL  = "check_before_cancel"
export const STATUS_SAVED   = "check_after_saved"
export const STATUS_DEFAULT = "check_default"

const VSDrawer = forwardRef((props, ref) => {

    const {
        id,
        open,
        width,
        timeout,
        title,
        children,
        preview,
        cancel,
        submit,
        errors,
        onSubmit,
        onCancel,
        onError,
        onClose,
        onStatus,
        onClickOutside,
        onAfterSave,
        transitionDuration,
        withVSDrawerInfo,
        VSDrawerSuccessProps,
        VSDrawerCancelProps,
        VSDrawerFooterProps
    } = props

    const isValid = errors.length == 0
    const isSingular = errors.length == 1

    const isMounted = useRef(null)

    const [ status, setStatus ] = useState(STATUS_DEFAULT)
    const [ loading, setLoading ] = useState(false)
    const [ showTooltip, setShowTooltip ] = useState(false)

    useImperativeHandle(ref, () => ({
        onSubmit: onSubmitProgress,
     }));

    const onSubmitProgress = () => {
        if (onSubmit) {
            setLoading(true)
            onSubmit()
            .then((result) => {
                setLoading(false)
                if (timeout > 0) {
                    setStatus(STATUS_SAVED)
                    setTimeout(() => {
                        onAfterSave && onAfterSave(result)
                    }, timeout)
                } else {
                    onAfterSave && onAfterSave(result)
                }
            })
            .catch(error => {
                setLoading(false)
                onError && onError(error)
            })
        }
    }

    const clickOutsideAction = () => {
        if (onClickOutside) {
            onClickOutside()
        } else {
            closeAction()
        }
    }

    const closeAction = () => {
        if (onClose) {
            onClose()
        } else {
            cancelAction()
        }
    }

    const cancelAction = () => {
        onCancel && onCancel()
    }

    useEffect(() => {
        if (isMounted.current) {
            onStatus?.(status)
        }
    }, [ status ])

    useEffect(() => {
        if (props.status) {
            setStatus(props.status)
        }
    }, [ props.status ])

    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    const hasFooter = VSDrawerFooterProps?.children || VSDrawerFooterProps?.show !== false

    return (
        <SwipeableDrawer
            anchor="right"
            open={open}
            id={id}
            className="vsdrawer"
            disableSwipeToOpen={true}
            onOpen={() => null}
            onClose={() => clickOutsideAction()}
            PaperProps={{
                style: {
                    width: width
                }
            }}
            transitionDuration={transitionDuration}
        >
            <div className={`row m-0 h-100 ${ loading ? "vsdrawer-loading" : "" }`}>
                <div className={`col p-0 ${withVSDrawerInfo && status == STATUS_DEFAULT ? "vsdrawer-main" : ""}`}>
                    { status == STATUS_CANCEL && (
                        <VSDrawerCancel
                            {...VSDrawerCancelProps}
                            VSDrawerHeaderProps={{
                                width: width
                            }}
                            onConfirm={() => cancelAction()}
                            onCancel={() => setStatus(STATUS_DEFAULT)}
                        />
                    )}
                    { status == STATUS_SAVED && (
                        <VSDrawerSuccess
                            {...VSDrawerSuccessProps}
                            VSDrawerHeaderProps={{
                                width: width
                            }}
                            onClose={() => closeAction()}
                        />
                    )}
                    { status == STATUS_DEFAULT && (
                        <>
                            <VSDrawerHeader
                                title={ title }
                                width={ width }
                                disabled={loading}
                                onClose={() => closeAction() }
                            />
                            <div className={`vsdrawer-content ${title ? 'vsdrawer-content-hasHeader' : ''} ${hasFooter ? "vsdrawer-content-hasFooter" : ""}`}>
                                { children  }
                            </div>
                            <VSDrawerFooter {...VSDrawerFooterProps} >
                                { VSDrawerFooterProps?.children ? (
                                    VSDrawerFooterProps.children
                                ) : (
                                    <div className="row align-items-center gx-3">
                                        { VSDrawerFooterProps?.prepend }
                                        { cancel && (
                                            <div className={VSDrawerFooterProps?.prepend ? "col-6 col-xl-auto" : "col" }>
                                                <button
                                                    className="btn btn-cancel w-100"
                                                    onClick={() => {
                                                        if (VSDrawerCancelProps?.title) {
                                                            setStatus(STATUS_CANCEL)
                                                        } else {
                                                            cancelAction()
                                                        }
                                                    }}
                                                >
                                                    { cancel }
                                                </button>
                                            </div>
                                        )}
                                        <VSTooltip
                                            placement="top-start"
                                            open={showTooltip}
                                            onOpen={() => {
                                                if (!isValid) {
                                                    setShowTooltip(true)
                                                }
                                            }}
                                            onClose={() => setShowTooltip(false)}
                                            title={
                                                <div className="vsdrawer-error">
                                                    O{ isSingular ? "" : "s" } campo{ isSingular ? "" : "s" } abaixo 
                                                    est{ isSingular ? "á" : "ão" } com erro no preenchimento:
                                                    <ul>
                                                        { errors.map((error, index) => (
                                                            <li key={index}>{ error }</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            }
                                        >
                                            <div className={VSDrawerFooterProps?.prepend ? "col-6 col-xl-auto" : "col" }>
                                                <button className={`btn btn-save position-relative w-100 ${ !isValid && "disabled" }`} onClick={() => isValid && onSubmitProgress()}>
                                                    { loading ? (
                                                        <CircularProgress size="20px" />
                                                    ) : (
                                                        submit
                                                    )}
                                                </button>
                                            </div>
                                        </VSTooltip>
                                    </div>
                                )}
                            </VSDrawerFooter>
                        </>
                    )}
                </div>
                { preview }
            </div>
        </SwipeableDrawer>
    )
})

VSDrawer.propTypes = {
    id: PropTypes.string,
    open: PropTypes.bool,
    width: PropTypes.string,
    timeout: PropTypes.number,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    children: PropTypes.node,
    preview: PropTypes.node,
    cancel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    submit: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    errors: PropTypes.array,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onError: PropTypes.func,
    onClose: PropTypes.func,
    onClickOutside: PropTypes.func,
    onAfterSave: PropTypes.func
};

VSDrawer.defaultProps = {
    id: "",
    open: true,
    width: "80%",
    timeout: 4000,
    title: "",
    children: <div></div>,
    preview: null,
    cancel: "Cancelar",
    submit: "Salvar",
    errors: [],
    onSubmit: () => null,
    onCancel: () => null,
    onError: () => null,
    onClose: null,
    onClickOutside: null,
    onAfterSave: (_) => null
}

export default VSDrawer;