import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { getPatient } from '../../Patient'

import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSError from '../../../../components/vsError/VSError';
import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple';
import { maskValues } from '../../../../components/vsTextFieldCurrency/functions';
import VSTooltip from '../../../../components/vsTooltip/VSTooltip';
import { getPetloveSaudeCoverage } from '../../../health/Health';
import PatientHealthCard from './PatientHealthCard';

import { ReactComponent as IconSquareMinus } from "../../../../assets/icon-square-minus.svg"
import { ReactComponent as IconSquarePlus } from "../../../../assets/icon-square-plus.svg"
import { ReactComponent as IconHealthYes } from "../../../../assets/icon-health-yes.svg"
import { ReactComponent as IconHealthNo } from "../../../../assets/icon-health-no.svg"
import { ReactComponent as IconHealthQuestion } from "../../../../assets/icon-health-question.svg"

import './HealthDetailOverlay.scss'
const HealthDetailOverlay = (props) => {

    const {
        id
    } = useParams()

    const {
        features,
        clinic,
        history,
        permissions
    } = props

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)

    const [ patient, setPatient ] = useState(null)
    const [ healthCoverage, setHealthCoverage ] = useState(null)
    const [ filteredHealthCoverage, setFilteredHealthCoverage ] = useState(null)
    
    const [ search, setSearch ] = useState('')
    const [ options, setOptions ] = useState([])

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id, clinic.object)
            .then(_patient => {
                setPatient(_patient)
            })
            .catch(error => {
                Swal.fire(
                    'Desculpe',
                    error,
                    'error'
                )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getPatientOrError"
                        })
                    })
            })
    }

    const getHealthCoverageOrError = async () => {
        if (patient && clinic.health?.available) {
            setLoading(true)
            setError(null)
            const _health = await getPetloveSaudeCoverage(patient.get("microchip"), patient.get("owner")?.get("cpf"))
            .catch((err) => {
                console.error(err)
                setError({
                    message: err?.message || err,
                    function: "getHealthCoverageOrError"
                })
            })
            
            setHealthCoverage(_health)
            setLoading(false)
        }
    }

    useEffect(() => {
        setOptions([...new Set(
            healthCoverage?.pets?.[0]?.categories?.reduce((carryAll, category) => 
                carryAll.concat(category?.groups?.reduce((carryGroups, group) => 
                    carryGroups.concat(group?.procedures?.map(procedure => procedure?.procedure))
                , []))
            , [])
        )])
        setFilteredHealthCoverage(healthCoverage)
    }, [ healthCoverage ])

    useEffect(() => {
        if (patient) {
            getHealthCoverageOrError()
        }
    }, [ patient ])

    useEffect(() => {
        if(!search){
            setFilteredHealthCoverage(healthCoverage)
        } else {
            const filteredOptions = options.filter(option => option?.toUpperCase().includes(search.toUpperCase()))
            const _filteredCoverage = JSON.parse(JSON.stringify(healthCoverage))
            if(_filteredCoverage?.pets?.[0]?.categories){
                _filteredCoverage.pets[0].categories = _filteredCoverage?.pets?.[0]?.categories?.map(category => {
                    const _category = category
                    _category.groups = _category?.groups?.map(group => {
                        const _group = group
                        _group.procedures = _group?.procedures?.filter(procedure => filteredOptions?.includes(procedure.procedure))
                        return _group
                    }).filter(group => group?.procedures?.length > 0)
                    return _category
                }).filter(category => category?.groups?.length > 0)
            }
            setFilteredHealthCoverage(_filteredCoverage)
        }
    }, [ search ])

    useEffect(() => {
        if (id) {
            getPatientOrError()
        }
    }, [])

    const accordionHeader = (title, expanded) => (
        <div className="row w-100 g-0">
            <div className="col">
                {title}
            </div>
            <div className="col-auto">
                {expanded ? (
                    <IconSquareMinus width="24px" height="24px" fill="var(--vsprontuario-secondary-color)" />
                ) : (
                    <IconSquarePlus width="24px" height="24px" fill="var(--vsprontuario-secondary-color)" />
                )}
            </div>
        </div>
    )

    return (
        <VSDrawer
            id="health-detail-overlay"
            width="60%"
            cancel="Cancelar"
            submit="Abrir Nova Venda"
            title="Detalhes do Plano de Saúde"
            onSubmit={() => {
                history.push(`/venda/${patient.id}/novo`)
                return Promise.resolve()
            }}
            onCancel={() => history.goBack()}
        >
            {loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch (error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            case "getHealthCoverageOrError":
                                getHealthCoverageOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <div className="row">
                    <div className="col-12 mb-3">
                        <PatientHealthCard pet={healthCoverage?.pets?.[0]} patient={patient} history={history} />
                    </div>
                    <div className="col-12 d-block d-md-none mb-3">
                        <div className="petlove-accordion">
                            <VSAccordionSimple
                                header={_ => (
                                    <div className="row px-3">
                                        <div className="col-auto pe-2">
                                            <IconHealthQuestion width={16} height={16} fill="#808080" />
                                        </div>
                                        <div className="col ps-0">Cobrada pela Petlove</div>
                                    </div>
                                )}
                            >
                                <div className="row px-3 mb-3">
                                    <div className="col-12">
                                        O valor será cobrado diretamente no método de pagamento associado a assinatura desse pet.
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="health-detail-title mb-2">Cobertura do plano</div>
                        <Autocomplete
                            value={search}
                            options={options}
                            freeSolo
                            getOptionLabel={_ => _}
                            onInput={({ target: { value } }) => setSearch(value)}
                            onChange={(_, value) => setSearch(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Pesquise um procedimento"
                                    label="Pesquise um procedimento"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                            // getOptionSelected={(option) => option.value === intervalUnit}
                        />
                    </div>
                    <div className="col-12">
                        {filteredHealthCoverage?.pets?.[0]?.categories?.map((category, categoryIndex, categories) => (
                            <>
                                <VSAccordionSimple
                                    hideIcon
                                    color="var(--vsprontuario-secondary-color)"
                                    header={expanded => accordionHeader(category?.name, expanded)}
                                    expanded={search || undefined}
                                >
                                    {category?.groups?.map(group => (
                                        <div className="row mb-2">
                                            <div className="col">
                                                <div className="health-detail-subtitle mb-3">
                                                    {group?.limit?.name || "Limite individual"}
                                                </div>
                                                {group?.limit && (
                                                    <div className="health-detail-group-limit p-3 mb-3">
                                                        <div className="row flex-sm-row flex-column">
                                                            <div className="col text-nowrap">
                                                                Limite Total do Grupo: <b>{group?.limit?.total}</b>
                                                            </div>
                                                            <div className="col text-nowrap">
                                                                Limite Disponível: <b>{group?.limit?.available}</b>
                                                            </div>
                                                            <div className="col text-nowrap">
                                                                Limite Utilizado: <b>{group?.limit?.used}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {group?.procedures?.map(procedure => (
                                                    <div className="health-detail-procedure mb-2">
                                                        <div className="row align-items-start g-0 flex-sm-row flex-column">
                                                            <div className="col procedure-name p-3">
                                                                {procedure?.procedure}
                                                            </div>
                                                            <div className="col-auto px-3 p-sm-3">
                                                                <div className="row align-items-start">
                                                                    <div className="col-auto procedure-info text-nowrap d-flex align-items-center">
                                                                        Carência {
                                                                            procedure?.shortage ? (
                                                                                <IconHealthYes width={16} height={16} fill="var(--vsprontuario-positive-color)" className="ms-2" /> 
                                                                            ) : (
                                                                                <IconHealthNo width={16} height={16} fill="var(--vsprontuario-negative-color)" className="ms-2" />
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="col-auto procedure-info text-nowrap d-flex align-items-center">
                                                                        Limite {
                                                                            procedure?.limit ? (
                                                                                <IconHealthYes width={16} height={16} fill="var(--vsprontuario-positive-color)" className="ms-1" /> 
                                                                            ) : (
                                                                                <IconHealthNo width={16} height={16} fill="var(--vsprontuario-negative-color)" className="ms-1" />
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto procedure-info text-nowrap p-3" style={{ width: 170 }}>
                                                                <div>Copart.: <b>{maskValues("pt-BR", procedure?.coparticipation, "BRL")[1]}</b></div>
                                                                {procedure?.coparticipationPetlove ? (
                                                                    <VSTooltip
                                                                        placement="bottom-start"
                                                                        PopperProps={{ className: "MuiTooltip-popper health-detail-tooltip" }}
                                                                        title="O valor será cobrado diretamente no método de pagamento associado a assinatura desse pet."
                                                                    >
                                                                        <div className="d-flex align-items-start mt-1">
                                                                            Cobrada pela Petlove
                                                                            <IconHealthQuestion width={16} height={16} fill="#808080" className="ms-1" />
                                                                        </div>
                                                                    </VSTooltip>
                                                                ) : undefined}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </VSAccordionSimple>
                                {categoryIndex < categories.length - 1 && (
                                    <div className="vsbox-separator"></div>
                                )}
                            </>
                        ))}
                    </div>
                </div>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions,
        features: state.features,
    };
};

export default connect(mapStateToProps)(HealthDetailOverlay);