import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom'
import { getPatient } from '../../../patient/Patient';

import { ReactComponent as IconPatientAppointment } from '../../../../assets/icon-patient-appointment.svg'
import { ReactComponent as IconAdd } from "../../../../assets/icon-primary-add-o.svg"

import { CircularProgress } from "@mui/material";
import PatientSelectQuery from '../../../patient/PatientSelectQuery';
import { PatientQueryToggle } from '../../../patient/PatientWideInfo';
import VSError from '../../../../components/vsError/VSError';
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';
import { dayjs, saveOnTracker } from '../../../../utils';
import VSList from '../../../../components/vsList/VSList';
import { AIAppointmentView } from '../../../patient/tabs/appointment/AppointmentView';
import VSEmpty from '../../../../components/vsEmpty/VSEmpty';

import './VirtualAssistantAnamnesisFormat.scss';
const VirtualAssistantAnamnesisFormat = props => {

    const {
        id_animal
    } = useParams()

    const { state } = useLocation()

    const {
        user,
        clinic,
        history,
        permissions,
        setDrawerError
    } = props

    const isAnimalSelected = id_animal != "selecionar"

    const filter = useRef(null)
    const isMounted = useRef(false)
    const rowsPerPage = 5

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ patient, setPatient ] = useState(null)

    const [ trackerSource, setTrackerSource ] = useState(state?.trackerSource ? state?.trackerSource : "")
    const [ trackerSourceDetail, setTrackerSourceDetail ] = useState("")

    const getPatientOrError = () => {
        if (permissions?.check(ACL.PATIENT, ACL.Rule.VIEW)) {
            setLoading(true)
            setError(null)
    
            if (isAnimalSelected) {
                getPatient(id_animal, clinic.object)
                .then(_patient => {
                    setPatient(_patient)
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error?.message,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getPatientOrError"
                        })
                    })
                })
            } else {
                setLoading(false)
            }
        }
    }

    const getAppointment = (_, dateFrom, dateTo, orderBy, page) => {
        return new Promise((resolve, reject) => {
            var query = new Parse.Query("Appointment")
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            query.equalTo("patient", patient);
            if (dateFrom) {
                query.greaterThanOrEqualTo("documentDate", dateFrom)
            }
            if (dateTo) {
                query.lessThanOrEqualTo("documentDate", dateTo)
            }
            // switch (orderBy) {
            //     case OrderBy_Oldest:
            //         query.ascending("documentDate");
            //         break;
            //     default:
                    query.descending("documentDate");
            // }
            query.addAscending("objectId")
            query.count()
            .then(_total => {
                query.skip((page - 1) * rowsPerPage)
                query.limit(rowsPerPage)
                query.include("createdBy")
                query.include("changedBy")
                query.find()
                .then(_appointments => {
                    if (isMounted.current) {
                        resolve({
                            items: _appointments,
                            total: _total
                        })
                    }
                })
                .catch(error => {
                    console.error(error)
                    reject(error)
                })
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (patient && !permissions?.check(ACL.PATIENT, ACL.Rule.EDIT, patient)) { // TODO: view or edit permission?
            permissions?.error()
        }
        if (permissions && !permissions.check(ACL.APPOINTMENT, ACL.Rule.VIEW)) {
            permissions?.error()
        }
    }, [ patient, permissions ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            if (isAnimalSelected) {
                getPatientOrError()
            }
        }
    }, [ clinic, id_animal ])

    useEffect(() => {
        if(state?.trackerSource){
            setTrackerSource(state?.trackerSource)
        }
    }, [ state ])

    useEffect(() => {
        isMounted.current = true
        document.addEventListener("Appointment__getList", onChange)
        return () => { 
            isMounted.current = false
            document.removeEventListener("Appointment__getList", onChange)
        }
    }, [])

    const getAppointmentCountTextForPatient = history => {
        const _appointmentsHistory = history && history?.filter(historyElement => historyElement.get("appointment"))
        return _appointmentsHistory && (
            <> e {_appointmentsHistory?.length > 0 ? <>possui <b>{_appointmentsHistory?.length} consultas</b></> : <b>não possui consultas</b>} em seu histórico</>
        )
    }

    return (
        <div id="virtual-assistant-differential-diagnosis">
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                !isAnimalSelected ? (
                    <PatientSelectQuery
                        getNextRoute={(_patient, source) => {
                            history.replace(`/assistente/organizar-anamnese/animal/${_patient?.id}?selecionado`)
                            
                            const _trackerSource = "Busca por Animal ou Tutor"
                            const _trackerSourceDetail = source == "BoxOwner" ? "Tutor" : source == "BoxPatient" ? "Animal" : ""
                            setTrackerSource(_trackerSource)
                            setTrackerSourceDetail(_trackerSourceDetail)
                            saveOnTracker("Clicou", "Assistente", "Organizar Anamnese", null, _trackerSource, null, _trackerSourceDetail, { patientId: _patient?.id })
                        }}
                        appendContentToBoxPatient={(_patient, _history) => 
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="va-differential-diagnosis-summary-box-patient-append">
                                        Este animal é paciente desde {dayjs(_patient?.get("createdAt")).format("YYYY")}{getAppointmentCountTextForPatient(_history)}.
                                    </div>
                                </div>
                            </div>
                        }
                        includePatientHistory
                        boxPatientCallOnSelectByBoxClick
                        history={history}
                    />
                ) : (
                    <>
                        <PatientQueryToggle
                            patient={patient}
                            onClick={() => history.replace(`/assistente/organizar-anamnese/animal/selecionar`)}
                        />
                        <div className="va-differential-diagnosis-title mb-4">Selecione qual a consulta que deseja organizar a anamnese:</div>
                        <VSList
                            ref={filter}
                            button={permissions?.check(ACL.APPOINTMENT, ACL.Rule.EDIT) && {
                                id: "add-appointment-button",
                                text: "Cadastrar nova consulta",
                                onClick: () => history.push(`/animal/${ patient.id }/consulta/novo`)
                            }}
                            interval={{
                                start: {
                                    text: "Início",
                                    value: null
                                },
                                end: {
                                    text: "Término",
                                    value: null
                                }
                            }}
                            pagination={{
                                rowsPerPage: rowsPerPage,
                                expand: true
                            }}
                            onChange={({ _, _start, _end, _orderBy, _page }) => {
                                return getAppointment(null, _start, _end, _orderBy, _page)
                            }}
                            renderItem={(item, expanded) => (
                                <AIAppointmentView
                                    object={item}
                                    history={history}
                                    onClick={() => {
                                        history.push(`/animal/${item.get("patient").id}/consulta/${item.id}?organizarAnamnese`, {
                                            trackerSource: trackerSource,
                                            trackerSourceDetail: trackerSourceDetail
                                        })
                                        saveOnTracker("Clicou", "Assistente", "Organizar Anamnese", "Selecionar Consulta", trackerSource, null, trackerSourceDetail, { appointmentId: item?.id })
                                    }}
                                    expanded={expanded}
                                />
                            )}
                            renderEmpty={() => (
                                <VSEmpty
                                    icon={<IconPatientAppointment width="32px" height="32px" />}
                                    title="Você ainda não possui nenhum registro de consulta realizada para este animal"
                                    text="Cadastre a primeira consulta e comece a acompanhar de perto a evolução do seu paciente"
                                >
                                    { permissions?.check(ACL.APPOINTMENT, ACL.Rule.EDIT) && (
                                        <button
                                            id="new-appointment-button"
                                            className="vsbox-btn vsbox-btn-primary"
                                            onClick={() => history.push(`/animal/${ patient.id }/consulta/novo`)}
                                        >
                                            <IconAdd width="14" height="14" className="me-2" />
                                            Cadastrar nova consulta
                                        </button>
                                    )}
                                </VSEmpty>
                            )}
                            renderNotFound={() => (
                                <VSEmpty
                                    icon={<IconPatientAppointment width="32px" height="32px" />}
                                    title="Não encontramos nenhum resultado para a sua busca"
                                    text="Tente refazer sua busca utilizando outro período ou crie um novo cadastro utilizando o botão abaixo"
                                >
                                    { permissions?.check(ACL.APPOINTMENT, ACL.Rule.EDIT) && (
                                        <button
                                            id="new-appointment-button"
                                            className="vsbox-btn vsbox-btn-primary"
                                            onClick={() => history.push(`/animal/${ patient.id }/consulta/novo`)}
                                        >
                                            <IconAdd width="14" height="14" className="me-2" />
                                            Cadastrar nova consulta
                                        </button>
                                    )}
                                </VSEmpty>
                            )}
                        />
                    </>
                )
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(VirtualAssistantAnamnesisFormat)