import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { dayjs } from "../../utils"

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconAgendaEdit } from "../../assets/icon-agenda-edit.svg"
import { Radio } from "@mui/material"

import VSPage from "../../components/vsPage/VSPage"
import HealthPatientsCard from "./HealthPatientsCard"
import PatientPhoto from "../../components/patientPhoto/PatientPhoto"
import { getAge } from "../patient/Patient"
import { setHealthImportPatient, setHealthImportPatientEdit, setHealthImportPatientSelect } from "../../store/actions"
import HealthCancel from "./HealthCancel"

import "./HealthPatient.scss"
const HealthPatient = (props) => {

    const {
        health,
        history
    } = props

    const {
        petlove,
        patients
    } = health

    const [ cancel, setCancel ] = useState(false)

    const [ selected, setSelected ] = useState({})
    const [ hasSubmited, setHasSubmited ] = useState(false)

    const addOwnerToPet = (pet) => {
        pet.owner = { ...petlove }
        delete pet.owner.pets
        return pet
    }

    const petIsAlreadyBeenUnified = (pet) => {
        return patients?.find(patient => patient?.petloveSaudeId == pet?.id && patient?.id)
    }

    const setPatient = (data) => {
        var birthdate = null
        if (data?.birthdate || data?.dt_birthday) {
            birthdate = data?.birthdate || data?.dt_birthday
            if (typeof birthdate == "string") {
                birthdate = dayjs(birthdate).toDate()
            }
        }
        return {
            ...data,
            name: data?.name,
            specie: data?.specie,
            race: data?.breed || data?.race,
            num_chip: data?.microchip || data?.num_chip,
            gender: data?.gender,
            dt_birthday: birthdate,
            avatar: data?.avatar,
            image: data?.image || data?.avatar,
            temperament: data?.temperament,
            size: data?.size,
            pelage: data?.pelage,
            castrated: data?.castrated,
            rga: data?.rga,
            otherInfo: data?.otherInfo,
            notes: data?.notes,
            diceased: data?.diceased
        }
    }

    const onSelect = (index, value, checkBeforeUpdate = true) => {
        const pet = petlove?.pets?.[index]
        if (checkBeforeUpdate) {
            const oldSelectedIsUnify = selected[index]?.option == "unify"
            const newSelectedIsNotUnify = value != "unify"
            if (
                oldSelectedIsUnify && 
                newSelectedIsNotUnify && 
                petIsAlreadyBeenUnified(pet)
            ) {
                Swal.fire({
                    title: "Tem certeza?",
                    text: "Ao alterar a opção selecionada, o animal unificado será perdido.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Sim, quero cancelar!",
                    cancelButtonText: "Não, quero manter"
                }).then((result) => {
                    if (result.isConfirmed) {
                        onSelect(index, value, false)
                    }
                });
                return
            }
        }
        const newSelected = {...selected}
        newSelected[index] = {
            error: false,
            helper: "",
            option: value
        }
        setSelected(newSelected)

        const newPatients = [...patients || []]
        if (value == "new") {
            const data = setPatient({
                ...pet,
                ...newPatients[index],
                id: null,
                petloveSaudeData: pet,
                selected: "new"
            })
            newPatients[index] = data
            props.dispatch(setHealthImportPatient(newPatients))
        } else if (value == "none") {
            newPatients[index] = undefined
            props.dispatch(setHealthImportPatient(newPatients))
        }
    }

    const validateOnTouch = () => {
        validate()
    }

    const validate = () => {
        var isValid = true
        const newSelected = {...selected}

        petlove?.pets?.map((pet, index) => {
            if (!newSelected[index]?.option) {
                isValid = false
                newSelected[index] = {
                    option: null,
                    error: true,
                    helper: "Nenhuma opção selecionada"
                }
            } else if (newSelected[index]?.option == "unify") {
                const petWasUnified = petIsAlreadyBeenUnified(pet)
                newSelected[index] = {
                    option: "unify",
                    error: !petWasUnified,
                    helper: petWasUnified ? "" : "O animal não foi unificado"
                }
                if (!petWasUnified) {
                    isValid = false
                }
            }
        })

        setSelected(newSelected)
        return isValid
    }

    const save = () => {
        setHasSubmited(true)
        if (validate()) {
            const newPatients = []
            petlove?.pets?.map((pet, index) => {
                newPatients.push({
                    ...pet,
                    ...patients[index],
                    petloveSaudeId: pet?.id,
                    petloveSaudeData: pet,
                    selected: selected[index]?.option
                })
            })
            props.dispatch(setHealthImportPatient(newPatients))
            history.push(`/petlove-saude/revisar`)
        }
    }

    useEffect(() => {
        validateOnTouch()
    }, [ JSON.stringify(Object.keys(selected).map(index => selected[index]?.option)) ])

    useEffect(() => {
        if (patients?.length) {
            const newSelected = { ...selected }
            patients.map((patient, index) => {
                newSelected[index] = {
                    error: false,
                    helper: "",
                    option: patient?.selected || selected[index]?.option
                }
            })
            setSelected(newSelected)
        }
    }, [ JSON.stringify(patients?.map(patient => patient?.selected)) ])

    return (
        <div id="health-patient">
            <VSPage
                title={(
                    <div className="row">
                        <div className="col d-none d-lg-block">
                            <span>Dados do(a) Tutor(a)</span>
                        </div>
                        <div className="col-auto d-none d-lg-block">
                            <IconArrowLeft width={8} height={12} fill="var(--vsprontuario-primary-color)" style={{ transform: "rotate(180deg)" }} />
                        </div>
                        <div className="col-auto">
                            <span className="current">Animais Vinculados</span>
                        </div>
                        <div className="col-auto d-none d-xl-block">
                            <IconArrowLeft width={8} height={12} fill="var(--vsprontuario-primary-color)" style={{ transform: "rotate(180deg)" }} />
                        </div>
                        <div className="col-auto d-none d-xl-block">
                            <span>Revisar Dados</span>
                        </div>
                    </div>
                )}
                onCancel={() => setCancel(true)}
                submit="Avançar"
                onSubmit={save}
            >
                <div className="row">
                    <div className="col-12 order-2 col-lg-8 order-lg-1">
                        <div className="row align-items-center">
                            <div className="col-12 mb-3">
                                <div className="title">
                                    2 - Animais vinculados ao Tutor do Plano de Saúde Petlove
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                Existem animais vinculados ao Tutor encontrados na base do <b>Plano de Saúde Petlove</b>, selecione o que você deseja fazer.
                            </div>
                            <div className="col mb-3">
                                <IconAgendaEdit className="me-1" />
                                <span className="owner-name">
                                    { health?.owner?.name }
                                </span>
                            </div>
                            <div className="col-auto mb-3">
                                <button className="btn-link" onClick={() => history.push(`/petlove-saude`)}>
                                    <IconPrimaryEdit width="18" height="18" className="me-2" />
                                    Editar Cadastro
                                </button>
                            </div>
                            <div className="col-12 mb-4">
                                <div className="subtitle mb-2">
                                    Animais vinculados ao(a) tutor(a)
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    { health?.petlove?.pets?.map((pet, index) => (
                                        <div key={index} className="col-12 col-lg-6 mb-3">
                                            <div className={`vsbox ${selected[index]?.error ? "vsbox-error" : ""}`}>
                                                <div className="vsbox-body mb-0">
                                                    <div className="vsbox-profile">
                                                        <div className="row align-items-start h-100">
                                                            <div className="col-auto">
                                                                <PatientPhoto patientImage={pet.avatar} patientSpecie={pet.specie} withBorder />
                                                            </div>
                                                            <div className="col ps-0">
                                                                <div className="vsbox-name">{ pet.name }</div>
                                                                <div className="vsbox-data">
                                                                    { pet.specie }
                                                                    { pet.race && (
                                                                        <> - { pet.race }</>
                                                                    )}
                                                                    { pet.gender && (
                                                                        <> - { pet.gender }</>
                                                                    )}
                                                                    { pet.dt_birthday && (
                                                                        <> - { getAge(pet.dt_birthday, true) }</>
                                                                    )}
                                                                </div>
                                                                <div className="vsbox-data">
                                                                    Microchip: { pet.num_chip || "Não informado" }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { health?.vetsmart?.patients?.length && (
                                                        <>
                                                            <div className="vsbox-separator my-3"></div>
                                                            <div className="row align-items-center cursor-pointer" onClick={() => onSelect(index, "unify")}>
                                                                <div className="col-auto ps-0 pe-2">
                                                                    <Radio
                                                                        checked={selected[index]?.option === 'unify'}
                                                                        className="py-0"
                                                                    />
                                                                </div>
                                                                <div className="col px-0">
                                                                    <div className="patient-option-text">
                                                                        Unificar com Animal já existente
                                                                    </div>
                                                                </div>
                                                                { selected[index]?.option == "unify" && (
                                                                    <div className="col-auto ps-1">
                                                                        <button
                                                                            className="btn-link"
                                                                            onClick={() => {
                                                                                const unified = petIsAlreadyBeenUnified(pet)
                                                                                if (unified) {
                                                                                    const petToEdit = setPatient({
                                                                                        ...pet,
                                                                                        ...unified,
                                                                                        id: unified.petloveSaudeId
                                                                                    })
                                                                                    const patientToEdit = unified.patient
                                                                                    patientToEdit.set("name", unified.name)
                                                                                    patientToEdit.set("specie", unified.specie)
                                                                                    patientToEdit.set("breed", unified.breed)
                                                                                    patientToEdit.set("microchip", unified.microchip)
                                                                                    patientToEdit.set("gender", unified.gender)
                                                                                    patientToEdit.set("birthdate", unified.birthdate)
                                                                                    patientToEdit.set("image", unified.image)
                                                                                    props.dispatch(setHealthImportPatientSelect(addOwnerToPet(petToEdit)))
                                                                                    props.dispatch(setHealthImportPatientEdit(patientToEdit))
                                                                                    history.push(`/petlove-saude/animal/editar`)
                                                                                } else {
                                                                                    props.dispatch(setHealthImportPatientSelect(addOwnerToPet(pet)))
                                                                                    history.push(`/petlove-saude/animal/selecionar`)
                                                                                }
                                                                            }}
                                                                        >
                                                                            <IconPrimaryEdit width="18" height="18" className="me-2" />
                                                                            <span className="d-none d-md-inline-block text-uppercase">Editar</span>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    ) }
                                                    <div className="vsbox-separator my-3"></div>
                                                    <div className="row align-items-center cursor-pointer" onClick={() => onSelect(index, "new")}>
                                                        <div className="col-auto ps-0 pe-2">
                                                            <Radio
                                                                checked={selected[index]?.option === 'new'}
                                                                className="py-0"
                                                            />
                                                        </div>
                                                        <div className="col px-0">
                                                            <div className="patient-option-text">
                                                                Importar como Novo Cadastro
                                                            </div>
                                                        </div>
                                                        { selected[index]?.option == "new" && (
                                                            <div className="col-auto ps-1">
                                                                <button
                                                                    className="btn-link"
                                                                    onClick={() => {
                                                                        const patient = patients[index]
                                                                        if (patient) {
                                                                            const filledPet = setPatient({
                                                                                ...pet,
                                                                                ...patient,
                                                                                id: pet.id,
                                                                            })
                                                                            props.dispatch(setHealthImportPatientSelect(addOwnerToPet(filledPet)))
                                                                            history.push(`/petlove-saude/animal/editar`)
                                                                        } else {
                                                                            props.dispatch(setHealthImportPatientSelect(addOwnerToPet(pet)))
                                                                            history.push(`/petlove-saude/animal/editar`)
                                                                        }
                                                                    }}
                                                                >
                                                                    <IconPrimaryEdit width="18" height="18" className="me-2" />
                                                                    <span className="d-none d-md-inline-block text-uppercase">Editar</span>
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="vsbox-separator my-3"></div>
                                                    <div className="row align-items-center cursor-pointer" onClick={() => onSelect(index, "none")}>
                                                        <div className="col-auto ps-0 pe-2">
                                                            <Radio
                                                                checked={selected[index]?.option === 'none'}
                                                                className="py-0"
                                                            />
                                                        </div>
                                                        <div className="col px-0">
                                                            <div className="patient-option-text">
                                                                Não fazer nada
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            { selected[index]?.error && (
                                                <p className="text-error">
                                                    { selected[index]?.helper }
                                                </p>
                                            )}
                                        </div>
                                    )) }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 order-1 col-lg-4 order-lg-2 mb-3">
                        <HealthPatientsCard />
                    </div>
                </div>
            </VSPage>
            { cancel && (
                <HealthCancel
                    page="Animais Vinculados"
                    onCancel={() => history.push(`/tutor${health?.vetsmart?.id ? "/" + health?.vetsmart.id : ""}`)}
                    onKeep={() => setCancel(false)}
                />
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        health: state.health
    };
};

export default connect(mapStateToProps)(HealthPatient)