import React from "react"
import PatientPhoto from "../../components/patientPhoto/PatientPhoto"
import { getAgeFromPatient, getHealthTagFromPatient } from "../patient/Patient"
import { dayjs } from "../../utils"

import "./BoxOwnerPatient.scss"
const BoxOwnerPatient = (props) => {

    const {
        patient,
        history,
        noAction,
        onSelected,
        selectPatientLabel,
        callOnSelectByBoxClick,
        appendContent
    } = props

    const specie = (patient.get("specie") || "").toLowerCase()

    const healthTag = getHealthTagFromPatient(patient)

    return (
        <div
            id="box-owner-patient"
            className={`h-100 box-border ${specie} ${noAction && "no-action"}`}
            onClick={() => !noAction && (
                !onSelected ? history.push(`/animal/${ patient.id }`) :
                callOnSelectByBoxClick ? onSelected(patient) : undefined
            )}
        >
            <div className="vsbox-profile">
                <div className="row align-items-start h-100">
                    <div className="col-auto">
                        <PatientPhoto patient={patient} withBorder />
                    </div>
                    <div className="col ps-0">
                        { healthTag && (
                            <div className="row mb-1">
                                <div className="col-auto">
                                    { healthTag }
                                </div>
                            </div>
                        )}
                        <div className="vsbox-name">{ patient.get("name") }</div>
                        <div className="vsbox-data">
                            <b>Raça:</b> { patient.get("breed") || "Não informado" }
                        </div>
                        <div className="vsbox-data">
                            <b>Idade:</b> { getAgeFromPatient(patient, true) || "Não informado" }
                        </div>
                        { onSelected && (
                            <button
                                className="vsbox-action mt-2"
                                onClick={() => !callOnSelectByBoxClick && onSelected(patient)}
                            >
                                {selectPatientLabel ? selectPatientLabel : "SELECIONAR ANIMAL"}
                            </button>
                        )}
                    </div>
                </div>
                {appendContent && appendContent(patient)}
            </div>
        </div>
    )
}

export const BoxOwnerPatientAnniversary = props => {
    const {
        patient,
        history,
        noAction,
        onSelected
    } = props

    const specie = (patient.get("specie") || "").toLowerCase()

    return (
        <div
            id="box-owner-patient-anniversary"
            className={`h-100 ${specie} ${noAction && "no-action"}`}
            onClick={() => !noAction && !onSelected && history.push(`/animal/${ patient.id }`)}
        >
            <div className="vsbox-profile">
                <div className="row align-items-center flex-nowrap h-100">
                    <div className="col-auto">
                        <PatientPhoto patient={patient} withBorder />
                    </div>
                    <div className="col ps-0">
                        <div className="vsbox-name text-wrap"><span className="birthdate">{dayjs(patient.get("birthdate")).format("DD/MM")}</span>{ patient.get("name") }</div>
                        <div className="vsbox-data">
                            { patient.get("breed") || "Não informado" } - { getAgeFromPatient(patient, true) || "Não informado" }
                        </div>
                        { onSelected && (
                            <button
                                className="vsbox-action mt-2"
                                onClick={() => onSelected(patient)}
                            >
                                SELECIONAR ANIMAL
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoxOwnerPatient