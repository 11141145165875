import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import { connect } from "react-redux"

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconSimpleWarning } from "../../assets/icon-simple-warning.svg"
import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"

import VSPage from "../../components/vsPage/VSPage"
import HealthPatientsCard from "./HealthPatientsCard"
import HealthCancel from "./HealthCancel"
import { setHealthImportOwner } from "../../store/actions"
import { dayjs } from "../../utils"

import "./HealthOwner.scss"
const HealthOwner = (props) => {

    const {
        health,
        history
    } = props

    const [ cancel, setCancel ] = useState(false)

    const [ news, setNews ] = useState([])
    const [ diffs, setDiffs ] = useState([])
    const [ equals, setEquals ] = useState([])

    const keys = [
        {
            title: "Nome",
            vetsmart: "name",
            petlove: "name"
        },
        {
            title: "Endereço",
            vetsmart: "addressStreet",
            petlove: "address.address"
        },
        {
            title: "Número do endereço",
            vetsmart: "addressNumber",
            petlove: "address.number"
        },
        {
            title: "Complemento do endereço",
            vetsmart: "addressComplement",
            petlove: "address.additional"
        },
        {
            title: "Bairro",
            vetsmart: "neighborhood",
            petlove: "address.neighborhood"
        },
        {
            title: "Cidade",
            vetsmart: "city",
            petlove: "address.city"
        },
        {
            title: "Estado",
            vetsmart: "stateId",
            petlove: "address.uf"
        },
        {
            title: "CEP",
            vetsmart: "zipCode",
            petlove: "address.cep"
        },
        {
            title: "E-mail",
            vetsmart: "email",
            petlove: "email"
        },
        {
            title: "CPF",
            vetsmart: "cpf",
            petlove: "cpf"
        },
        {
            title: "Celular",
            vetsmart: "phone",
            petlove: "cellphone"
        },
        {
            title: "Telefone",
            vetsmart: "landlinePhone",
            petlove: "phone"
        }
    ]

    const getValue = (data, keyOrArrayKey) => {
        const keys = keyOrArrayKey.split(".")
        var value = data
        keys.map(key => {
            value = value[key]
        })
        return value
    }

    useEffect(() => {
        if (health?.petlove) {
            const {
                petlove,
                vetsmart
            } = health
            const _ad = []
            const _eq = []
            const _ne = []
            keys.map(compare => {
                if (!vetsmart) {
                    _ad.push(compare)
                } else if (vetsmart.get(compare.vetsmart) == getValue(petlove, compare.petlove)) {
                    _eq.push(compare)
                } else {
                    _ne.push(compare)
                }
            })

            setEquals(_eq)
            setDiffs(_ne)
            setNews(_ad)
        }
    }, [ health ])

    return (
        <div id="health-owner">
            <VSPage
                // loading={loading}
                title={(
                    <div className="row">
                        <div className="col">
                            <span className="current">Dados do(a) Tutor(a)</span>
                        </div>
                        <div className="col-auto d-none d-lg-block">
                            <IconArrowLeft width={8} height={12} fill="var(--vsprontuario-primary-color)" style={{ transform: "rotate(180deg)" }} />
                        </div>
                        <div className="col-auto d-none d-lg-block">
                            <span>Animais Vinculados</span>
                        </div>
                        <div className="col-auto d-none d-xl-block">
                            <IconArrowLeft width={8} height={12} fill="var(--vsprontuario-primary-color)" style={{ transform: "rotate(180deg)" }} />
                        </div>
                        <div className="col-auto d-none d-xl-block">
                            <span>Revisar Dados</span>
                        </div>
                    </div>
                )}
                onCancel={() => setCancel(true)}
                submit="Avançar"
                onSubmit={() => {
                    var birthdate = null
                    if (health?.vetsmart?.get("birthdate")) {
                        birthdate = health?.vetsmart?.get("birthdate")
                    } else if (health?.petlove?.birth_date) {
                        birthdate = dayjs(health?.petlove?.birth_date, "DD/MM/YYYY").toDate()
                    } else {
                        birthdate = null
                    }
                    const owner = {
                        id: health?.vetsmart?.id,
                        name: health?.vetsmart?.get("name") || health?.petlove?.name || "",
                        birthdate: birthdate,
                        cpf: health?.vetsmart?.get("cpf") || health?.petlove?.cpf || "",
                        landlinePhone: health?.vetsmart?.get("landlinePhone") || health?.petlove?.phone || "",
                        phone: health?.vetsmart?.get("phone") || health?.petlove?.cellphone || "",
                        email: health?.vetsmart?.get("email") || health?.petlove?.email || "",
                        rg: health?.vetsmart?.get("rg") || "",
                        internationalId: health?.vetsmart?.get("internationalId") || "",
                        zipCode: health?.vetsmart?.get("zipCode") || health?.petlove?.address?.cep || "",
                        addressStreet: health?.vetsmart?.get("address") || health?.petlove?.address?.address || "",
                        addressNumber: health?.vetsmart?.get("addressNumber") || health?.petlove?.address?.number || "",
                        addressComplement: health?.vetsmart?.get("addressComplement") || health?.petlove?.address?.additional || "",
                        neighborhood: health?.vetsmart?.get("neighborhood") || health?.petlove?.address?.neighborhood || "",
                        city: health?.vetsmart?.get("city") || health?.petlove?.address?.city || "",
                        stateId: health?.vetsmart?.get("stateId") || health?.petlove?.address?.uf || "",
                        notes: health?.vetsmart?.get("notes") || "",
                        referredBy: health?.vetsmart?.get("referredBy") || "",
                        petloveSaudeId: health?.petlove?.public_id,
                        petloveSaudeData: health?.petlove,
                        selected: health?.vetsmart ? "none" : "new"
                    }
                    props.dispatch(setHealthImportOwner(owner))
                    history.push(`/petlove-saude/animal`)
                }}
            >
                <div className="row">
                    <div className="col-12 order-2 col-lg-8 order-lg-1">
                        <div className="row align-items-center">
                            <div className="col-12 mb-3">
                                <div className="title">
                                    1 - Atualizar cadastro do Tutor
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                Este cadastro possui uma conta na base do <b>Plano de Saúde Petlove</b> com informações semelhantes. Verifique e revise as informações que deseja atualizar no Cadastro do Tutor na sua conta do prontuário Vetsmart.
                            </div>
                            { news.length > 0 && (
                                <div className="col-12 mb-3">
                                    <div className="card">
                                        <div className="card-head">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    Informações do Cadastro
                                                </div>
                                                <div className="col-auto">
                                                    <button
                                                        className="btn-submit btn-square"
                                                        onClick={() => history.push(`/petlove-saude/editar`)}
                                                    >
                                                        <IconPrimaryEdit width="18" height="18" fill="#FFFFFF" className="me-2" />
                                                        Editar<span className="d-none d-md-inline-block">&nbsp;Informações</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                { news.map((data, index) => (
                                                    <div key={index} className="col-12 card-separator">
                                                        <div className="card-item">
                                                            { data.title }: <b>{ getValue(health?.petlove, data.petlove) || "Não informado" }</b>
                                                        </div>
                                                    </div>
                                                )) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            { diffs.length > 0 && (
                                <div className="col-12 mb-3">
                                    <div className="card card-compare">
                                        <div className="card-head">
                                            <div className="row align-items-center">
                                                <div className="col card-danger">
                                                    <IconSimpleWarning width={18} height={18} fill="#C40000" className="me-1" />
                                                    Informações diferentes
                                                </div>
                                                <div className="col-auto">
                                                    <button
                                                        className="btn-submit btn-square"
                                                        onClick={() => history.push(`/petlove-saude/editar`)}
                                                    >
                                                        <IconPrimaryEdit width="18" height="18" fill="#FFFFFF" className="me-2" />
                                                        Atualizar <span className="d-none d-md-inline-block">&nbsp;Informações</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 col-lg">
                                                    <div className="row">
                                                        <div className="col-12 card-separator">
                                                            <div className="card-item">
                                                                Cadastro Prontuário Vetsmart
                                                            </div>
                                                        </div>
                                                        { diffs.map((data, index) => (
                                                            <div key={index} className="col-12 card-separator">
                                                                <div className="card-item">
                                                                    { data.title }: <b>{ health?.vetsmart?.get(data.vetsmart) || "Não informado" }</b>
                                                                </div>
                                                            </div>
                                                        )) }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg">
                                                    <div className="row">
                                                        <div className="col-12 card-separator">
                                                            <div className="card-item">
                                                                Plano de Saúde Petlove
                                                            </div>
                                                        </div>
                                                        { diffs.map((data, index) => (
                                                            <div key={index} className="col-12 card-separator">
                                                                <div className="card-item">
                                                                    { data.title }: <b>{ getValue(health?.petlove, data.petlove) || "Não informado" }</b>
                                                                </div>
                                                            </div>
                                                        )) }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            { equals.length > 0 && (
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-head">
                                            <div className="row">
                                                <div className="col">Informações Iguais</div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                { equals.map((data, index) => (
                                                    <div key={index} className="col-12 card-separator">
                                                        <div className="card-item">
                                                            { data.title }: <b>{ health?.vetsmart?.get(data.vetsmart) || "Não informado" }</b>
                                                        </div>
                                                    </div>
                                                )) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 order-1 col-lg-4 order-lg-2 mb-3">
                        <HealthPatientsCard />
                    </div>
                </div>
            </VSPage>
            { cancel && (
                <HealthCancel
                    page="Dados do Tutor"
                    onCancel={() => history.push(`/tutor${health?.vetsmart?.id ? "/" + health?.vetsmart.id : ""}`)}
                    onKeep={() => setCancel(false)}
                />
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        health: state.health
    };
};

export default connect(mapStateToProps)(HealthOwner)