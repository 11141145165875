import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';

import VSDrawer, { STATUS_CANCEL, STATUS_DEFAULT } from '../../components/vsDrawer/VSDrawer';
import { resetHealthImport } from '../../store/actions';

const HealthCancel = (props) => {

    const {
        page,
        open,
        onCancel,
        onKeep
    } = props

    return (
        <VSDrawer
            open={open}
            status={STATUS_CANCEL}
            title={`Cancelar ${page}`}
            cancel={"Cancelar Alterações"}
            submit={"Continuar"}
            errors={[]}
            timeout={0}
            onSubmit={Promise.resolve()}
            onCancel={() => {
                props.dispatch(resetHealthImport())
                onCancel()
            }}
            onStatus={(status) => {
                if (status == STATUS_DEFAULT) {
                    onKeep()
                }
            }}
            onAfterSave={_ => {
                history.push(`/petlove-saude/animal`)
            }}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar a operação?",
                text: "As informações não serão salvas e esta operação não poderá ser desfeita.",
                confirm: `Cancelar ${page}`,
                cancel: `Voltar`
            }}
        >
        </VSDrawer>
    )
}

export default connect()(HealthCancel)