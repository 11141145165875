import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';

import './Setting.scss'
import './ClinicSetting.scss'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/styles';

import SettingPreview from './SettingPreview'
import ClinicSettingClinicInfo from './tabs/clinicinfo/ClinicSettingClinicInfo'
import ClinicSettingDocumentInfo from './tabs/documentinfo/ClinicSettingDocumentInfo'
import ClinicSettingStoreInfo from './tabs/storeinfo/ClinicSettingStoreInfo'
import ClinicSettingUserManagement from './tabs/usermanagement/ClinicSettingUserManagement'
import ClinicSettingSubscription from './tabs/subscription/ClinicSettingSubscription'

import VetForm from '../../pages/vet/VetForm';
import ProCustomerForm from '../../pages/pro/ProCustomerForm';
import ProPaymentForm from '../../pages/pro/ProPaymentForm';
import ProUpgradeForm from '../../pages/pro/ProUpgradeForm';
import VetProForm from '../vet/VetProForm';
import ClinicSettingWorkHour from './tabs/clinicworkhour/ClinicSettingWorkHour';
import { ACL } from '../../constants';
import ClinicSettingHealth from './tabs/health/ClinicSettingHealth';

const ClinicNameSubtitle = styled(({ className, children }) => (
    <div className={className}>{children}</div>
))(({ theme }) => ({
    display: 'inline-block',
    padding: '4px 16px',
    background: '#E6E6E6',
    borderRadius: '200px',
    color: 'rgba(0, 0, 0, 0.8)',
    marginTop: '5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
}))

const ClinicSetting = (props) => {

    const {
        features,
        user,
        clinic,
        history,
        permissions,
        location
    } = props

    const {
        tab = "dados-cadastrais"
    } = useParams()

    const [loadingForScroll, setLoadingForScroll] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoadingForScroll(false)
        }, 500);
    })

    useEffect(() => {
        if (clinic && user && permissions && tab != "gerenciar-acessos" && !permissions?.check(ACL.ADMIN, ACL.Rule.VIEW) && !clinic?.isOwner) {
            history.push('/configuracao-clinica/gerenciar-acessos')
        }
    }, [permissions, tab])

    if (loadingForScroll || !user?.object || !clinic?.object) {
        return (
            <div style={{ textAlign: "center", padding: '36px' }}>
                <CircularProgress size={28} thickness={2} color="primary" />
            </div>
        )
    }

    return (
        <div className="setting" id="clinic-setting">
            <div className="row setting-header">
                <div className="col-12 mb-3">
                    <div className="page-title">
                        Configurações da Clínica
                    </div>
                    <ClinicNameSubtitle>{clinic?.object?.get('name')}</ClinicNameSubtitle>
                </div>
                <div className="col-12">
                    <div className="row align-items-end mb-3 setting-tabs">
                        <div className="col">
                            <Tabs
                                className="vstabs"
                                value={tab}
                                // onChange={(_, selected) => null}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                            >
                                {(permissions?.check(ACL.ADMIN, ACL.Rule.VIEW) || clinic?.isOwner) && [
                                    <Tab
                                        value="dados-cadastrais"
                                        label="Dados Cadastrais"
                                        onClick={() => history.push('/configuracao-clinica/dados-cadastrais')}
                                    />,
                                    clinic?.object?.get("petloveSaudeAvailable") && <Tab
                                        value="convenios"
                                        label="Convênios"
                                        onClick={() => history.push('/configuracao-clinica/convenios')}
                                    />,
                                    <Tab
                                        value="informacoes-de-documentos"
                                        label="Informações de Documentos"
                                        onClick={() => history.push('/configuracao-clinica/informacoes-de-documentos')}
                                    />,
                                    <Tab
                                        value="opcao-de-compra"
                                        label="Opção de Compra"
                                        onClick={() => history.push('/configuracao-clinica/opcao-de-compra')}
                                    />]}
                                <Tab
                                    value="gerenciar-acessos"
                                    label="Gerenciar Acessos"
                                    onClick={() => history.push('/configuracao-clinica/gerenciar-acessos')}
                                />
                                {(permissions?.check(ACL.ADMIN, ACL.Rule.VIEW) || clinic?.isOwner) && [
                                    <Tab
                                        value="plano-e-cobranca"
                                        label="Plano e Cobrança"
                                        onClick={() => history.push('/configuracao-clinica/plano-e-cobranca')}
                                    />,
                                    <Tab
                                        value="horario-atendimento"
                                        label="Horário de Atendimento"
                                        onClick={() => history.push('/configuracao-clinica/horario-atendimento')}
                                    />]}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Switch {...props}>
                        {(permissions?.check(ACL.ADMIN, ACL.Rule.VIEW) || clinic?.isOwner) && [
                            <Route exact path="/configuracao-clinica/(dados-cadastrais)?" component={ClinicSettingClinicInfo} />,
                            clinic?.object?.get("petloveSaudeAvailable") && <Route exact path="/configuracao-clinica/convenios" component={ClinicSettingHealth} />,
                            <Route exact path="/configuracao-clinica/informacoes-de-documentos" component={ClinicSettingDocumentInfo} />,
                            <Route exact path="/configuracao-clinica/opcao-de-compra" component={ClinicSettingStoreInfo} />
                        ]}
                        <Route exact path="/configuracao-clinica/gerenciar-acessos/:id_usuario?/:tab?" render={routerProps => (
                            <>
                                <ClinicSettingUserManagement {...routerProps} />
                                {routerProps.match.params.id_usuario && (
                                    features?.unlimited_users ? (
                                        <VetProForm {...props} {...routerProps} />
                                    ) : (
                                        <VetForm {...props} {...routerProps} />
                                    )
                                )}
                            </>
                        )} />
                        {(permissions?.check(ACL.ADMIN, ACL.Rule.VIEW) || clinic?.isOwner) && (
                            <>
                                <Route exact path="/configuracao-clinica/plano-e-cobranca/:editar?" render={routerProps => (
                                    <>
                                    {routerProps.match.params.editar}
                                        <ClinicSettingSubscription {...routerProps} />
                                        {routerProps.match.params.editar?.startsWith("cliente") && (
                                            <ProCustomerForm {...props} {...routerProps} />
                                        )}
                                        {routerProps.match.params.editar?.startsWith("pagamento") && (
                                            <ProPaymentForm {...props} {...routerProps} />
                                        )}
                                        {routerProps.match.params.editar?.startsWith("upgrade") && (
                                            <ProUpgradeForm {...props} {...routerProps} />
                                        )}
                                    </>
                                )} />
                                <Route exact path="/configuracao-clinica/horario-atendimento" component={ClinicSettingWorkHour} />
                            </>
                        )}
                    </Switch>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        features: state.features,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(ClinicSetting);