import React, { useEffect, useState } from "react"

import { ReactComponent as IconCheckO } from "../../assets/icon-primary-check-o.svg"
import { ReactComponent as IconWarning } from "../../assets/icon-warning.svg"
import { ReactComponent as IconHealthCare } from "../../assets/icon-health-care.svg"
import { ReactComponent as IconHeart } from "../../assets/icon-heart.svg"

import "./HealthTag.scss"
const HealthTag = (props) => {

    const {
        status = "default", // success|error|default
    } = props

    return (
        <div id="health-tag">
            <div className={`tag tag-${status}`}>
                <div className="row">
                    <div className="col-auto pe-1">
                        { status == "success" ? (
                            <IconCheckO width={14} height={14} fill="#17624C" />
                        ) : status == "error" ? (
                            <IconWarning width={14} height={14} fill="#87172D" />
                        ) : status == "health" ? (
                            <IconHealthCare width={14} height={14} fill="var(--vsprontuario-secondary-color)" />
                        ) : (
                            <IconHeart width={14} height={14} fill="#4E2096" />
                        )}
                    </div>
                    <div className="col ps-0">
                        Plano de Saúde { status != "health" ? "Petlove" : "" }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HealthTag