import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

import { ReactComponent as IconClose } from "../../assets/icon-close.svg"

import VSDrawer from '../../components/vsDrawer/VSDrawer';
import PatientPhoto from '../../components/patientPhoto/PatientPhoto';
import { getAge } from '../patient/Patient';
import { CircularProgress, Radio } from '@mui/material';
import { IconRadioChecked, IconRadioUnchecked } from '../../components/vsRadio/VSRadio';
import { setHealthImportPatientEdit } from '../../store/actions';

import './HealthPatientSelect.scss'
import HealthAlert from './HealthAlert';
const HealthPatientSelect = (props) => {

    const {
        health,
        history
    } = props
    
    const {
        vetsmart,
        petlove,
        patients,
        pet
    } = health

    const [ selected, setSelected ] = useState(null)
    const [ errorSelected, setErrorSelected ] = useState(false)
    const [ histories, setHistories ] = useState(new Map())

    const patientHasAlreadyMerged = (patient) => {
        return patients?.find(_patient => _patient?.id == patient?.id)
    }

    const validate = () => {
        let isValid = true
        if (selected?.id) {
            setErrorSelected(false)
        } else {
            isValid = false
            setErrorSelected(true)
        }

        return isValid
    }

    const isValid = () => !errorSelected

    const fieldsWithErrorText = () => {
        let errors = []
        if (errorSelected) {
            errors.push("Animal")
        }
        return errors
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            if (validate()) {
                props.dispatch(setHealthImportPatientEdit(selected))
                resolve(selected)
            } else {
                reject()
            }
        })
    }

    useEffect(() => {
        validate()
    }, [ selected ])

    useEffect(() => {
        if (health?.vetsmart?.patients) {
            health?.vetsmart?.patients?.map((patient, index) => {
                const queryHistories = new Parse.Query("History")
                queryHistories.equalTo("clinic", patient.get("clinic"))
                queryHistories.equalTo("patient", patient)
                queryHistories.equalTo("isDeleted", false)
                queryHistories.count()
                .then(total => {
                    setHistories(new Map(histories.set(index, total)))
                })
            })
        }
    }, [ health ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="health-patient-select"
            title={"Unificar Cadastro de Animal"}
            cancel={"Cancelar Alterações"}
            timeout={0}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => {
                history.replace(`/petlove-saude/animal/editar`)
            }}
        >
            <div className="vssearch">
                <div className="vssearch-area">
                    <div className="row flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <div className="col-auto">
                            <PatientPhoto patientImage={pet?.avatar} patientSpecie={pet?.specie} withBorder />
                        </div>
                        <div className="col">
                            <div className="vssearch-text">
                                ID { pet?.id } • { pet?.name }
                            </div>
                            <div className="vssearch-result">
                                Tutor(a): { pet?.owner?.name || "Não informado" }<br />
                                Raça: { pet?.race || "Não informado" } | Microchip: { pet?.num_chip }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 mb-3">
                    <div className="input-title">
                        1 - Selecione o AnimaL que deseja unificar
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <p className="text-default">
                        Estes são os animais vinculados ao Tutor <b>que estão 
                        cadastrados na sua conta do prontuário Vetsmart</b>. 
                        Escolha qual animal deseja unificar com as informações 
                        da conta encontradas na base Petlove Saúde
                    </p>
                </div>
                { vetsmart?.patients?.map((patient, index) => {
                    const disabled = patientHasAlreadyMerged(patient)
                    return (
                        <div key={index} className="col-12 mb-3">
                            <div className={`vsbox ${ disabled ? "" : "cursor-pointer" }`} onClick={() => !disabled && setSelected(patient)}>
                                <div className="vsbox-body">
                                    <div className="vsbox-profile">
                                        <div className="row align-items-start h-100">
                                            <div className="col-auto pe-0 mb-3">
                                                <Radio
                                                    checkedIcon={IconRadioChecked(disabled)}
                                                    icon={IconRadioUnchecked(disabled)}
                                                    checked={disabled ? false : selected?.id === patient?.id}
                                                    disabled={disabled}
                                                    className="py-0"
                                                />
                                            </div>
                                            <div className="col-auto px-0 mb-3">
                                                <PatientPhoto patient={patient} withBorder />
                                            </div>
                                            <div className="col mb-3">
                                                <div className="vsbox-name mb-1">
                                                    ID { patient?.get("patientNumberId") } • { patient?.get("name") }
                                                </div>
                                                <div className="vsbox-data">
                                                    { patient?.get("specie") }
                                                    { patient?.get("breed") && (
                                                        <> - { patient?.get("breed") }</>
                                                    )}
                                                    { patient?.get("gender") && (
                                                        <> - { patient?.get("gender") }</>
                                                    )}
                                                    { patient?.get("birthdate") && (
                                                        <> - { getAge(patient?.get("birthdate"), true) }</>
                                                    )}
                                                    | Microchip: { patient?.get("microchip") }
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                { disabled ? (
                                                    <div className="vsbox-alert alert-default">
                                                        <IconClose width={24} height={24} fill="#999999" className="me-1" /> Este animal já está selecionado para ser unificado e não pode ser escolhido novamente.
                                                    </div>
                                                ) : (
                                                    <div className="vsbox-alert alert-success">
                                                        Este animal possui { histories.get(index) >= 0 ? (
                                                            <b>{ histories.get(index) } registro{ histories.get(index) == 1 ? "" : "s" }</b>
                                                        ) : (
                                                            <CircularProgress size={14} className="mx-1" />
                                                        )} em seu histórico.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                )}) }
                <div className="col-12">
                    <HealthAlert
                        type="info"
                        text="As informações unificadas se restringem a parte de cadastro, o histórico de registros dos animais cadastrados na clínica não sofrerão alterações."
                    />
                </div>
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        health: state.health
    };
};
  
export default connect(mapStateToProps)(HealthPatientSelect);