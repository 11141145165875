import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useParams } from "react-router"
import { connect } from "react-redux"

import { Alert, Autocomplete, FormControl, MenuItem, Select, TextField } from "@mui/material"

import { ReactComponent as IconArrowLeft } from "../../../../assets/icon-arrow-left.svg"
import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconExclamationO } from "../../../../assets/icon-exclamation-o.svg"
import { ReactComponent as IconTrash } from "../../../../assets/icon-primary-trash.svg"
import { ReactComponent as IconWarning } from "../../../../assets/icon-warning-o.svg"

import { APP_NAME, APP_DEVICE, APP_VERSION, ACL } from '../../../../constants'
import { getHealthTagFromPatient, getPatient, getSpecieIcon } from "../../Patient"
import { ESTIMATE, SALE, getSale } from "./Sale"
import { dayjs, formatNumber, formattedCurrency, mandatoryField, saveOnTracker } from "../../../../utils"
import { getCalendar } from "../../../calendar/Calendar"
import { getSupplies } from "../../../supply/SupplyTransaction"
import { getConvertedQuantity, getSupplyTransactionObject, getUnits } from "../../../supply/Supply"

import VSError from "../../../../components/vsError/VSError"
import VSPage from "../../../../components/vsPage/VSPage"
import VSTextFieldCounter from "../../../../components/vsTextFieldCounter/VSTextFieldCounter"
import VSAccordionSimple from "../../../../components/vsAccordion/VSAccordionSimple"
import VSTextFieldCurrency from "../../../../components/vsTextFieldCurrency/VSTextFieldCurrency"
import VSModal from "../../../../components/vsModal/VSModal"
import SupplyUnitView from "../../../supply/SupplyUnitView"

import "./SaleForm.scss"
const SaleForm = (props) => {

    const {
        id_animal,
        tipo,
        id_documento,
        id_agendamento
    } = useParams()

	const { 
		state
	} = useLocation();

    const {
        user,
        clinic,
        history,
        permissions
    } = props

    const update = id_documento !== "novo"
    const title = tipo === "orcamento" ? "Orçamento" : "Venda"
    const documentType = tipo === "orcamento" ? ESTIMATE : SALE
    const pronoum = tipo === "orcamento" ? "o" : "a"

    const redirectTo = state?.redirectTo || `/animal/${id_animal}/${tipo}`

    const [ loading, setLoading ] = useState(false)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ units, setUnits ] = useState([])
    const [ patient, setPatient ] = useState(null)
    const [ estimate, setEstimate ] = useState(null)
    const [ sale, setSale ] = useState(null)
    const [ calendar, setCalendar ] = useState(null)
    const [ supplyOptions, setSupplyOptions ] = useState([])

    const [ error, setError ] = useState(null)

    const [ services, setServices ] = useState([])
    const [ snapshotServices, setSnapshotServices ] = useState([])
    const [ errorServices, setErrorServices ] = useState(false)
    const [ helperServices, setHelperServices ] = useState("")
    const [ errorSupplies, setErrorSupplies ] = useState(false)
    const [ snapshotSuppliesByService, setSnapshotSuppliesByService ] = useState({})

    const [ notes, setNotes ] = useState("")

	const date = dayjs()
	const [ documentDay, setDocumentDay ] = useState(date.format("DD"))
	const [ documentMonth, setDocumentMonth ] = useState(date.format("MM"))
	const [ documentYear, setDocumentYear ] = useState(date.format("YYYY"))
	const [ documentDate, setDocumentDate ] = useState(date.toDate())
	const [ documentDateHelperText, setDocumentDateHelperText ] = useState("")
	const [ documentDateError, setDocumentDateError ] = useState(false)

    const [ hasPayments, setHasPayments ] = useState(false)
    const [ paymentsValue, setPaymentsValue ] = useState(0)

    const onChangeQuantity = (quantity, index) => {
        let newServices = [...services]
        newServices[index].quantity = quantity
        newServices[index].value = newServices[index].valueUnit * quantity
        setServices(newServices)
    }

    const removeService = (index) => {
        var newServices = [...services]
        newServices.splice(index, 1)
        setServices(newServices)

        if (snapshotSuppliesByService[index]) {
            const removeServiceFromSnapshot = {...snapshotSuppliesByService}
            delete removeServiceFromSnapshot[index]
            setSnapshotSuppliesByService(removeServiceFromSnapshot)
        }
    }

    const setSupply = (serviceIndex, supplyIndex, value) => {
        const newServices = [...services]
        const currentSupply = newServices[serviceIndex].supplies[supplyIndex]
        newServices[serviceIndex].supplies[supplyIndex] = {
            ...currentSupply,
            ...value
        }
        setServices(newServices)
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (error?.message) {
            isValid = false
        }

        const _documentDate = dayjs(`${documentYear}-${documentMonth}-${documentDay} 00:00:00`, "YYYY-MM-DD HH:mm:ss")
        if (documentDay && documentMonth && documentYear && _documentDate.isValid()) {
            const nextDay = dayjs().add(1, 'day')
            if (parseInt(documentYear) < 1971) {
                isValid = false
                setDocumentDateError(true)
                setDocumentDateHelperText("O ano não pode ser menor que 1971")
            } else if (_documentDate > nextDay) {
                isValid = false
                setDocumentDateError(true)
                setDocumentDateHelperText("A data não pode ser maior que a data atual")
            } else {
                setDocumentDate(_documentDate.toDate())
                setDocumentDateError(false)
                setDocumentDateHelperText("")
            }
        } else {
            isValid = false
            setDocumentDateError(true)
            setDocumentDateHelperText("A data informada não é válida")
        }

        const servicesAfterSuppliesValidation = [...services]
        var _errorSupplies = false
        if (services?.length) {
            setErrorServices(false)
            setHelperServices("")
            services?.map((service, index) => {
                if (service?.serviceName) {
                    service?.supplies?.map((supply, key) => {
                        if (supply?.objectId) {
                            if (supply?.quantity > 0) {
                                servicesAfterSuppliesValidation[index].supplies[key].errorQuantity = false
                                servicesAfterSuppliesValidation[index].supplies[key].helperQuantity = mandatoryField
                            } else {
                                servicesAfterSuppliesValidation[index].supplies[key].errorQuantity = true
                                servicesAfterSuppliesValidation[index].supplies[key].helperQuantity = "A quantidade utilizada do insumo precisa ser maior que 0 (zero)"
                                _errorSupplies = true
                            }
                            
                            if (supply?.unit) {
                                servicesAfterSuppliesValidation[index].supplies[key].errorUnit = false
                                servicesAfterSuppliesValidation[index].supplies[key].helperUnit = mandatoryField
                            } else {
                                if (!supply?.unitOptions || supply?.unitOptions.length == 0) {
                                    servicesAfterSuppliesValidation[index].supplies[key].helperUnit = "O insumo não possui uma unidade de medida cadastrada, por favor, atualize o cadastro do insumo informando a unidade de medida"
                                } else {
                                    servicesAfterSuppliesValidation[index].supplies[key].helperUnit = "A medida utilizada do insumo precisa ser informada"
                                }
                                servicesAfterSuppliesValidation[index].supplies[key].errorUnit = true
                                _errorSupplies = true
                            }
                        }
                    })
                }
            })
            if (_errorSupplies) {
                isValid = false
            }
        } else {
            isValid = false
            setErrorServices(true)
            setHelperServices("Por favor, adicione um serviço ou valor")
        }
        setServices(servicesAfterSuppliesValidation)
        setErrorSupplies(_errorSupplies)

        return isValid
    }

    const isValid = () => !loading && !loadingSave && !documentDateError && !errorServices && !errorSupplies

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push(`Buscando ${ pronoum } ${ title }...`)
        }
        if (loadingSave) {
            errors.push(`Salvando ${ pronoum } ${ title }...`)
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorServices) {
            errors.push("Nenhum serviço ou valor adicionado")
        }
        if (errorSupplies) {
            errors.push("Insumos a serem utilizados")
        }
        if (documentDateError) {
            errors.push(`A data d${ pronoum } ${ title } não é válida`)
        }
        if (!permissions?.check(ACL.SALE, ACL.Rule.EDIT, sale)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        setHasSubmited(true)
        if (validate()) {
            setLoadingSave(true)

            let toSave = []
            const servicesToSave = services?.map(service => ({
                object: service?.object,
                objectId: service?.objectId,
                serviceId: service?.serviceId,
                serviceName: service?.serviceName,
                value: service?.value,
                valueUnit: service?.valueUnit,
                quantity: service?.quantity,
                categoryName: service?.categoryName,
                notes: service?.notes,
                supplies: service?.supplies
                    ?.filter(supply => supply?.objectId)
                    ?.map(supply => ({
                        object: supply?.object,
                        objectId: supply?.objectId,
                        quantity: supply?.quantity,
                        unit: supply?.unit,
                    }))
            }))

            if (!sale.get("payments") || paymentsValue > total) {
                sale.set("payments", [])
            }
            sale.set("services", servicesToSave)
            sale.set("documentType", documentType)
            sale.set("documentDate", documentDate)
            sale.set("notes", notes)
            sale.set("discount", 0)
            sale.set("changedBy", user.object)
            sale.set("changedAt", new Date())
            sale.set("changedApp", APP_NAME)
            sale.set("changedDevice", APP_DEVICE)
            sale.set("changedAppVersion", APP_VERSION)
            toSave.push(sale)

            if (estimate) {
                estimate.set("childSale", sale)
                toSave.push(estimate)
            }

            if (calendar) {
                calendar.set("sale", sale)
                toSave.push(calendar)
            }

            // estimate doesnt transaction stock
            if (documentType == SALE) {
                snapshotServices.map(service => {
                    service.supplies?.some(supply => {
                        const serviceHasSelected = servicesToSave
                            .find(s => s.serviceName == service.serviceName)
                        if (!serviceHasSelected) {
                            service.supplies?.map(s => {
                                // if service has removed: rollback all supplies to stock
                                var rollbackQuantityToStock = getConvertedQuantity(s.quantity, units, s.object.get("unit"), s.unit)
                                const supplyTransaction = getSupplyTransactionObject(sale, s.object, rollbackQuantityToStock, service.serviceName, service.quantity)
                                toSave.push(supplyTransaction)
                            })
                            return true
                        } else {
                            const supplyHasSelected = serviceHasSelected?.supplies
                                ?.find(s => s?.objectId == supply?.objectId)
                            if (!supplyHasSelected) {
                                // is supply has removed: rollback supply to stock
                                var rollbackQuantityToStock = getConvertedQuantity(supply.quantity, units, supply.unit, supply.object.get("unit"))
                                const supplyTransaction = getSupplyTransactionObject(sale, supply.object, rollbackQuantityToStock, service.serviceName, service.quantity)
                                toSave.push(supplyTransaction)
                            }
                        }
                    })
                })
    
                servicesToSave.map(service => {
                    service.supplies?.map(supply => {
                        let quantity = parseFloat(supply?.quantity) * parseInt(service.quantity)
    
                        const editService = snapshotServices
                            .find(s => s.serviceName == service.serviceName)
                        if (editService) {
                            const editSupply = editService
                                ?.supplies
                                ?.find(s => s.objectId == supply.objectId)
                            if (editSupply) {
                                // if supply has edited: adjust quantity stock
                                editSupply.quantity = getConvertedQuantity(editSupply.quantity, units, editSupply.unit, supply.unit) * editService.quantity
                                quantity = quantity - editSupply.quantity
                            }
                        }
    
                        quantity = getConvertedQuantity(quantity, units, supply.unit, supply.object.get("unit"))
                        if (quantity != 0) {
                            const finalQuantity = quantity * -1
        
                            const supplyTransaction = getSupplyTransactionObject(sale, supply.object, finalQuantity, service.serviceName, service.quantity)
                            toSave.push(supplyTransaction)
                        }
                    })
                })
            }

            Parse.Object.saveAll(toSave)
            .then(objects => {
                var object = objects[0]
                if (documentType == SALE) {
                    history.replace(`/pagamento/${tipo}/${object.id}`, { redirectTo: redirectTo })
                } else {
                    history.replace(`/compartilhar/${ object.id }`, { redirectTo: redirectTo })
                }
                setLoadingSave(false)
            })
            .catch(error => {
                setLoadingSave(false)
                console.error(error)
                update && sale.revert()
                Swal.fire(
                    'Desculpe',
                    `Ocorreu algum erro ao tentar ${sale.id ? "editar" : "cadastrar"} ${ pronoum } ${ title }`,
                    'error'
                )
            })
        }
    }

    const getCalendarOrError = () => {
        if (id_agendamento) {
            getCalendar(id_agendamento, patient)
            .then(_calendar => {
                setCalendar(_calendar)
                getSaleOrError()
            })
            .catch(error => {
                Swal.fire(
                    'Desculpe',
                    error,
                    'error'
                )
                .then(_ => {
                    setLoading(false)
                    setError({
                        message: error,
                        function: "getCalendarOrError"
                    })
                })
            })
        } else {
            getSaleOrError()
        }
        return 
    }

    const getSaleOrError = async () => {
        if (permissions?.check(ACL.SALE, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", title, update ? id_documento : "")
            if (update) {
                setLoading(true)
                setError(null)

                getSale(id_documento, patient)
                .then(_sale => {
                    const estimateWillBeChangedToSale = _sale?.get("documentType") == ESTIMATE && documentType == SALE
                    if (estimateWillBeChangedToSale) {
                        setEstimate(_sale)

                        let newSale = new Parse.Object("MRSale")
                        newSale.setACL(clinic.ACL)
                        newSale.set("clinic", clinic.object)
                        newSale.set("createdBy", Parse.User.current())
                        newSale.set("createdApp", APP_NAME)
                        newSale.set("createdDevice", APP_DEVICE)
                        newSale.set("createdAppVersion", APP_VERSION)
                        newSale.set("isDeleted", false)
                        newSale.set("patient", patient)
                        newSale.set("parentSale", _sale)
                        setSale(newSale)
                    } else {
                        setSale(_sale)
                    }
                    if (_sale.get("paymentsValue") > 0) {
                        setHasPayments(true)
                        setPaymentsValue(_sale.get("paymentsValue"))
                    }
                    const servicesArray = (_sale.get("services") || [])
                    const oldServices = setUnitOptionsToSupply(servicesArray)
                    if (documentType == SALE && !estimateWillBeChangedToSale) {
                        const deepCloneServices = oldServices?.map(service => ({
                            ...service,
                            object: service?.object,
                            supplies: service?.supplies?.map(supply => ({
                                ...supply,
                                object: supply?.object
                            }))
                        }))
                        setSnapshotServices(deepCloneServices)
                    }
                    setServices(oldServices)
                    setNotes(_sale.get("notes") || "")
                    const _documentDate = dayjs(_sale.get("documentDate"))
                    setDocumentDay(_documentDate.format("DD"))
                    setDocumentMonth(_documentDate.format("MM"))
                    setDocumentYear(_documentDate.format("YYYY"))
                    setDocumentDate(_documentDate.toDate())
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getSaleOrError"
                        })
                    })
                })
            } else {
                let newSale = new Parse.Object("MRSale")
                newSale.setACL(clinic.ACL)
                newSale.set("clinic", clinic.object)
                newSale.set("createdBy", Parse.User.current())
                newSale.set("createdApp", APP_NAME)
                newSale.set("createdDevice", APP_DEVICE)
                newSale.set("createdAppVersion", APP_VERSION)
                newSale.set("isDeleted", false)
                newSale.set("patient", patient)
                setSale(newSale)
                setLoading(false)
            }
        }
    }

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id_animal, clinic.object)
        .then(_patient => {
            setPatient(_patient)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getPatientOrError"
                })
            })
        })
    }

    const defaultSuppliesHasChanged = (index) => {
        if (!snapshotSuppliesByService[index])
            return false

        const currentSupplies = services[index]?.supplies?.map(supply => ({
            objectId: supply?.objectId,
            quantity: supply?.quantity,
            unit: supply?.unit
        }))
        const snapshotSupplies = snapshotSuppliesByService[index]?.map(supply => ({
            objectId: supply?.objectId,
            quantity: supply?.quantity,
            unit: supply?.unit
        }))

        return JSON.stringify(currentSupplies) != JSON.stringify(snapshotSupplies)
    }

    const setUnitOptionsToSupply = (services) => {
        return services.map(service => ({
            ...service,
            valueUnit: service?.valueUnit || (service?.quantity ? service?.value / service?.quantity : service?.value),
            quantity: service?.quantity || 1,
            supplies: service.supplies?.map(supply => ({
                ...supply,
                unitOptions: units.filter(u => u.conversions?.[supply.unit] || u.id == supply.unit)
            }))
        }))
    }

    const getPluralOrSingularNameForOptions = (unit, quantity) => {
        return quantity == 1 ? unit?.singular : unit?.name 
    }

    useEffect(() => {
        if (state?.service && state?.service?.serviceName && state?.service?.value) {
            var newServices = [...services]
            const index = Number.isInteger(state.index) ? state.index : services.length
            newServices[index] = state.service
            newServices = setUnitOptionsToSupply(newServices)
            if (JSON.stringify(services) !== JSON.stringify(newServices)) {
                setServices(newServices)
                
                const serviceHasDefaultSupplies = state?.service?.supplies?.length > 0
                if (serviceHasDefaultSupplies) {
                    setSnapshotSuppliesByService({
                        ...snapshotSuppliesByService,
                        [index]: [
                            ...newServices[index].supplies
                        ]
                    })
                }
    
                // clear state after uses it
                history.replace({ 
                    state: {
                        redirectTo: redirectTo
                    }
                })
            }
        }
    }, [ state ])

    useEffect(() => {
        validate()
    }, [ 
        documentDay, documentMonth, documentYear, notes,
        JSON.stringify(services?.map(service => ({
            serviceName: service?.serviceName,
            value: service?.value,
            quantity: service?.quantity,
            notes: service?.notes,
            supplies: service?.supplies?.map(supply => ({
                objectId: supply?.objectId,
                quantity: supply?.quantity,
                unit: supply?.unit
            }))
        })))
    ])

    useEffect(() => {
        if (calendar?.id && calendar?.get("services")?.length > 0 && services?.length == 0) {
            setServices(setUnitOptionsToSupply(calendar?.get("services") || []))
        }
    }, [ calendar ])

    useEffect(() => {
        if (sale && !permissions?.check(ACL.SALE, ACL.Rule.EDIT, sale)) {
            permissions?.error()
        }
    }, [ sale ])

    useEffect(() => {
        if (patient) {
            getCalendarOrError()
        }
    }, [ patient ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object && units.length > 0) {
            getPatientOrError()
        }
    }, [ clinic, units ])

    useEffect(() => {
        getUnits().then(_units => setUnits(_units))
        getSupplies(clinic.object, false).then(supplies => setSupplyOptions(supplies))
    }, [])

    const errors = fieldsWithErrorText()

    const total = services.reduce((total, object) => total + object.value, 0)

    const healthTag = getHealthTagFromPatient(patient)

    return (
        <div id="sale-form">
            <VSPage
                loading={loading || loadingSave}
                title={`${ sale && sale.id ? "Editar" : `Nov${pronoum}` } ${ title }`}
                subtitle={(
                    <div className="row align-items-center">
                        { tipo == 'venda' && (
                            <>
                                <div className="col-auto px-0">
                                    <IconArrowLeft width="8px" height="12px" className="title-separator" />
                                </div>
                                <div className="col-auto">
                                    <div className="subtitle">Pagamento</div>
                                </div>
                            </>
                        )}
                        <div className="col-auto px-0">
                            <IconArrowLeft width="8px" height="12px" className="title-separator" />
                        </div>
                        <div className="col-auto">
                            <div className="subtitle">Visualizar e Emitir</div>
                        </div>
                        <div className="col"></div>
                    </div>
                )}
                cancel="Cancelar"
                onCancel={() => history.replace(redirectTo)}
                disabled={hasSubmited && !isValid()}
                errors={errors}
                submit={ tipo == 'venda' ? "Salvar e ir para pagamento" : "Visualizar e Emitir" }
                onSubmit={() => save()}
            >
                { error?.message ? (
                    <VSError
                        message={error.message}
                        onClose={() => {
                            switch(error.function) {
                                case "getPatientOrError":
                                    getPatientOrError()
                                    break
                                case "getCalendarOrError":
                                    getCalendarOrError()
                                    break
                                case "getSaleOrError":
                                    getSaleOrError()
                                    break
                                default:
                            }
                        }}
                    />
                ) : (
                    <>
                        <div className="row align-items-center mb-3">
                            <div className="col-12">
                                <h1 className="title">
                                    { sale?.id ? "Editar" : `Nov${pronoum}` } { title }
                                </h1>
                                <h2 className="subtitle">
                                    { getSpecieIcon(patient?.get("specie"), { width: 24, height: 24, fill: "#444444", className: "me-2" }) }
                                    { patient?.get("name") }
                                    { healthTag && (
                                        <span className="d-inline-block ms-2">
                                            {healthTag}
                                        </span>
                                    )}
                                </h2>
                            </div>
                        </div>
                        { hasPayments && paymentsValue > total && (
                            <div className="message-info">
                                <div className="row align-items-center">
                                    <div className="col-auto pr-0">
                                        <IconExclamationO  width="24" height="24" fill="var(--vsprontuario-secondary-color)" stroke="var(--vsprontuario-secondary-color)" />
                                    </div>
                                    <div className="col pl-2">
                                        Ao efetivar essa edição, como o novo Valor Total da Venda é inferior ao Valor Pago ({ formattedCurrency(paymentsValue) }, já recebidos), o(s) pagamento(s) atual(is) desta compra será(ão) apagado(s).
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12">
                                <h2 className="content-title">SERVIÇOS</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md">
                                { services && services.map((service, index) => (
                                    <div key={ index } className="row mb-3">
                                        <div className="col-12">
                                            <div className="box">
                                                <div className="box-head py-2">
                                                    <div className="row align-items-center">
                                                        <div className="col">
                                                            { service.objectId ? "Serviço" : "Outros Valores" }
                                                        </div>
                                                        { service.objectId ? (
                                                            <div className="col-auto box-actions">
                                                                <div className="box-action box-delete no-separator" onClick={() => removeService(index)}>
                                                                    <IconTrash width="18" height="18" fill="var(--vsprontuario-primary-color)" />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="col-auto box-actions">
                                                                <div className="box-action box-delete" onClick={() => removeService(index)}>
                                                                    <IconTrash width="18" height="18" fill="var(--vsprontuario-primary-color)" />
                                                                </div>
                                                                <div className="box-action box-edit" onClick={() => {
                                                                    history.replace(`/${tipo}/${id_animal}/${id_documento}/valor`, {
                                                                        redirectTo: redirectTo,
                                                                        service: service,
                                                                        index: index
                                                                    })
                                                                }}>
                                                                    <IconEdit width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="col-auto">
                                                            <VSTextFieldCounter
                                                                placeholder="1"
                                                                style={{ width: "136px" }}
                                                                value={services[index].quantity}
                                                                onChange={(value) => onChangeQuantity(value, index)}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box-body pb-0">
                                                    { service.objectId ? (
                                                        <>
                                                            <div className="box-title">ID { service.serviceId } - { service.serviceName }</div>
                                                        </>
                                                    ) : (
                                                        <div className="box-title">{ service.serviceName }</div>
                                                    )}
                                                    <div className="box-subtitle mb-3">
                                                        <div className="row">
                                                            <div className="col-12 col-md">
                                                                <b>Valor unitário:</b> { formattedCurrency(service.valueUnit) }
                                                            </div>
                                                            { service.quantity > 1 && (
                                                                <div className="col-12 col-md">
                                                                    <b>Valor total:</b> { formattedCurrency(service.value) }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <hr className="m-0" />
                                                    <VSAccordionSimple
                                                        expanded={true}
                                                        header={(expanded) => `${ expanded ? "Ocultar" : "Ver" } insumos utilizados`}
                                                    >
                                                        <div className="message-info p-2 mt-0 mb-3">
                                                            <div className="row align-items-center">
                                                                <div className="col-auto pe-0">
                                                                    <IconExclamationO  width="24" height="24" fill="var(--vsprontuario-secondary-color)" stroke="var(--vsprontuario-secondary-color)" />
                                                                </div>
                                                                <div className="col ps-2">
                                                                As informações dos insumos utilizados não serão compartilhadas com o tutor e não vão interferir no valor total da venda.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        { service.quantity > 1 && service.supplies[0]?.object && (
                                                            <Alert severity="info" className="mb-3"><b>NOTA:</b> O campo "Qtd. utilizada" deve ser informada por unidade de serviço (ex: {service.serviceName} utilizou {service.quantity} vezes {formatNumber(service.supplies[0]?.quantity)} { units.find(u => u.id == service.supplies[0]?.unit)?.name } do insumo {service.supplies[0]?.object?.get("supplyName")})</Alert>
                                                        )}
                                                        <div className="vsbox-item mb-3">
                                                            { service?.supplies?.map((supply, indexSupply) => {
                                                                const supplyStock = supplyOptions?.find(s => s.id == supply?.objectId)
                                                                return (
                                                                    <React.Fragment key={indexSupply}>
                                                                        { indexSupply != 0 && (
                                                                            <hr />
                                                                        )}
                                                                        <div className="row g-3">
                                                                            { supply.object ? (
                                                                                <div className="col">
                                                                                    <b>{ supply.object.get("supplyName") }</b>
                                                                                    <div>Em estoque: <SupplyUnitView unit={supplyStock?.get("unit")} quantity={supplyStock?.total} /></div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="col">
                                                                                    <Autocomplete
                                                                                        inputValue={supply?.supply}
                                                                                        options={supplyOptions}
                                                                                        getOptionLabel={(option) => option.id ? option.get("supplyName") : supply.get("supplyName")}
                                                                                        getOptionDisabled={(option) => Boolean(service?.supplies?.find(supply => supply?.objectId == option?.id))}
                                                                                        onChange={(_, selected) => {
                                                                                            const unit = selected?.get("unit")
                                                                                            setSupply(index, indexSupply, {
                                                                                                ...selected,
                                                                                                object: selected,
                                                                                                objectId: selected.id,
                                                                                                unitOptions: units.filter(u => u.conversions?.[unit] || u.id == unit),
                                                                                                quantity: 0,
                                                                                                unit: units.find(u => u.id == unit)?.id
                                                                                            })
                                                                                        }}
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                className="w-100"
                                                                                                placeholder={`Selecione o insumo`}
                                                                                                size="small"
                                                                                                variant="outlined"
                                                                                                helperText={mandatoryField}
                                                                                                fullWidth
                                                                                            />
                                                                                        )}
                                                                                        openOnFocus
                                                                                        freeSolo
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            <div className="col-auto">
                                                                                <button
                                                                                    className={`vsbox-btn vsbox-btn-alert`}
                                                                                    onClick={() => {
                                                                                        const newServices = [...services]
                                                                                        newServices[index].supplies.splice(indexSupply, 1)
                                                                                        setServices(newServices)
                                                                                    }}
                                                                                >
                                                                                    <IconTrash width="24" height="24" fill={"var(--vsprontuario-primary-color)"} />
                                                                                </button>
                                                                            </div>
                                                                            { supply?.objectId && (

                                                                                <div className="col-12">
                                                                                    <div className="row">
                                                                                        <div className="col-auto" style={{ width: "160px" }}>
                                                                                            <div className="row">
                                                                                                <div className="col-12 mb-1">
                                                                                                    <div className="input-subtitle">
                                                                                                        Qtd. utilizada
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12">
                                                                                                    <VSTextFieldCurrency
                                                                                                        value={supply.quantity}
                                                                                                        hideSymbol
                                                                                                        disabled={supply.unitOptions?.length == 0}
                                                                                                        onChangeValue={(event, originalValue, maskedValue) => {
                                                                                                            setSupply(index, indexSupply, {
                                                                                                                quantity: originalValue
                                                                                                            })
                                                                                                        }}
                                                                                                        InputElement={(
                                                                                                            <TextField
                                                                                                                type="tel"
                                                                                                                placeholder="0,00"
                                                                                                                label=""
                                                                                                                error={supply.errorQuantity}
                                                                                                                helperText={supply.errorQuantity ? null : mandatoryField}
                                                                                                                size="small"
                                                                                                                variant="outlined"
                                                                                                                fullWidth
                                                                                                            />
                                                                                                        )}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col">
                                                                                            <div className="row">
                                                                                                <div className="col-12 mb-1">
                                                                                                    <div className="input-subtitle">
                                                                                                        Medida utilizada
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12">
                                                                                                    <Autocomplete
                                                                                                        value={supply.unit}
                                                                                                        options={supply.unitOptions || []}
                                                                                                        getOptionLabel={(option) => getPluralOrSingularNameForOptions(option?.name ? option : supply.unitOptions?.find(u => u.id == supply.unit), supply.quantity) || ""}
                                                                                                        isOptionEqualToValue={(option, value) => option.id == value.id || option.id == supply.unit}
                                                                                                        onChange={(_, selected) => setSupply(index, indexSupply, {
                                                                                                            unit: selected?.id || ""
                                                                                                        })}
                                                                                                        disabled={supply.unitOptions?.length == 0}
                                                                                                        renderInput={(params) => (
                                                                                                            <TextField
                                                                                                                {...params}
                                                                                                                placeholder="Escolha uma medida"
                                                                                                                error={supply.errorUnit}
                                                                                                                helperText={supply.helperUnit}
                                                                                                                size="small"
                                                                                                                variant="outlined"
                                                                                                                fullWidth
                                                                                                            />
                                                                                                        )}
                                                                                                        openOnFocus
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        { supply.errorQuantity && !supply.errorUnit && (
                                                                                            <div className="col-12 mt-0">
                                                                                                <p className="text-error mb-0">
                                                                                                    { supply.helperQuantity }
                                                                                                </p>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            }) }
                                                            { service?.supplies?.length > 0 && (
                                                                <hr/>
                                                            )} 
                                                            <div className="row g-3 justify-content-lg-between">
                                                                { service?.supplies?.length < supplyOptions?.length ? (
                                                                    <div className="col-12 col-lg-auto">
                                                                        <button
                                                                            className="btn-add w-100 text-center"
                                                                            onClick={() => {
                                                                                setSupply(index, service?.supplies?.length, {
                                                                                    object: null,
                                                                                    unitOptions: units,
                                                                                    quantity: 0,
                                                                                    unit: null
                                                                                })
                                                                            }}
                                                                        >
                                                                            <IconAddO width="20" height="20" />
                                                                            Adicionar Novo Insumo
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div className="col-12">
                                                                        <div className="vsalert">
                                                                            <div className="row align-items-center">
                                                                                <div className="col-auto">
                                                                                    <IconWarning width="24px" height="24px" fill="var(--vsprontuario-secondary-color)" />
                                                                                </div>
                                                                                <div className="col ps-0">
                                                                                    <h4 className="vsalert-title">
                                                                                        Não é possível adicionar mais insumos.
                                                                                    </h4>
                                                                                    <p className="vsalert-text">
                                                                                        Você possui <b>{ supplyOptions?.length }</b> insumo(s) cadastrado(s). Para adicionar mais insumos será necessário cadastrar novos insumos, pois não é possível repetir os insumos já selecionados.
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                { defaultSuppliesHasChanged(index) && (
                                                                    <div className="col-12 col-lg-auto">
                                                                        <button
                                                                            className="btn-discard w-100 text-center"
                                                                            onClick={() => {

                                                                                Swal.fire({
                                                                                    showConfirmButton: false,
                                                                                    customClass: {
                                                                                        popup: 'p-0',
                                                                                        content: 'p-0',
                                                                                    },
                                                                                    html: (
                                                                                        <VSModal
                                                                                            title="Tem certeza que deseja desfazer as alterações?"
                                                                                            text="Os insumos utilizados voltarão a ser os mesmos cadastrados no serviço, essa ação não poderá ser desfeita."
                                                                                            options={[
                                                                                                {
                                                                                                    type: "cancel",
                                                                                                    text: "Cancelar Operação",
                                                                                                    action: () => {
                                                                                                        Swal.close()
                                                                                                    }
                                                                                                },
                                                                                                {
                                                                                                    type: "confirm",
                                                                                                    text: "Desfazer Alterações",
                                                                                                    action: () => {
                                                                                                        const newServices = [...services]
                                                                                                        newServices[index].supplies = [...snapshotSuppliesByService[index]]
                                                                                                        setServices(newServices)
                                                                                                        Swal.close()
                                                                                                    }
                                                                                                }
                                                                                            ]}
                                                                                        />
                                                                                    )
                                                                                })
                                                                            }}
                                                                        >
                                                                            Descartar alterações
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="vsalert vsalert-warning mb-3">
                                                            <div className="row align-items-center">
                                                                <div className="col-auto">
                                                                    <IconWarning width="24px" height="24px" />
                                                                </div>
                                                                <div className="col ps-0">
                                                                    <p className="mb-0" style={{ color: "#444444" }}>
                                                                        Não esqueça de sempre verificar a validade dos seus insumos
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </VSAccordionSimple>
                                                    { service?.notes && (
                                                        <>
                                                            <hr className="m-0" />
                                                            <VSAccordionSimple
                                                                header={(expanded) => `${ expanded ? "Ocultar" : "Ver" } mais informações`}
                                                            >
                                                                <div className="row">
                                                                    <div className="col vsbox-title">
                                                                        Observações:
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col mb-3 pre-line">
                                                                        { service?.notes || "Não informado" }
                                                                    </div>
                                                                </div>
                                                            </VSAccordionSimple>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) }
                                <div className="row">
                                    <div className="col-12 col-lg-6 mb-3">
                                        <button
                                            className="btn-dashed other-values"
                                            onClick={() => {
                                                history.replace(`/${tipo}/${id_animal}/${id_documento}/servico`, { redirectTo: redirectTo })
                                            }}
                                        >
                                            <IconAddO width="24" height="24" fill="var(--vsprontuario-primary-color)" className="d-block mx-auto mb-2" />
                                            Adicionar Serviços
                                        </button>
                                    </div>
                                    <div className="col-12 col-lg-6 mb-3">
                                        <button
                                            className="btn-dashed"
                                            onClick={() => {
                                                history.replace(`/${tipo}/${id_animal}/${id_documento}/valor`, { redirectTo: redirectTo })
                                            }}
                                        >
                                            <IconAddO width="24" height="24" fill="var(--vsprontuario-primary-color)" className="d-block mx-auto mb-2" />
                                            Adicionar Outros Valores
                                        </button>
                                    </div>
                                </div>
                                { hasSubmited && errorServices && (
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <small className="text-danger">{ helperServices }</small>
                                        </div>
                                    </div>
                                )}
                                <hr style={{ marginTop: "4px" }} />
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="mb-3 input-title border-0">
                                            Observações d{ pronoum } { title } (Opcional)
                                        </div>
                                        <TextField
                                            name="notes"
                                            value={notes}
                                            disabled={loading}
                                            onChange={({ target: { value }}) => setNotes(value)}
                                            placeholder={`Digite aqui as observações sobre ${ pronoum } ${ title }`}
                                            size="small"
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                            minRows={5}
                                        /> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="custom-box">
                                    <div className="custom-content">
                                        <div className="content-action">
                                            <div className="row g-3">
                                                <div className="col-12 order-0">
                                                    <div className="input-title border-0">
                                                        Data d{ pronoum } { title }
                                                    </div>
                                                </div>
                                                <div className="col-6 col-sm-3 order-1">
                                                    <TextField
                                                        type="tel"
                                                        name="documentDay"
                                                        value={documentDay}
                                                        disabled={loading}
                                                        onChange={({ target: { value }}) => setDocumentDay(value)}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                    /> 
                                                </div>
                                                <div className="col-12 col-sm-6 order-3 order-sm-2">
                                                    <FormControl variant="outlined" size="small" fullWidth>
                                                        <Select
                                                            name="documentMonth"
                                                            value={documentMonth}
                                                            disabled={loading}
                                                            onChange={({ target: { value }}) => setDocumentMonth(value)}
                                                        >
                                                            <MenuItem value="01">Janeiro</MenuItem>
                                                            <MenuItem value="02">Fevereiro</MenuItem>
                                                            <MenuItem value="03">Março</MenuItem>
                                                            <MenuItem value="04">Abril</MenuItem>
                                                            <MenuItem value="05">Maio</MenuItem>
                                                            <MenuItem value="06">Junho</MenuItem>
                                                            <MenuItem value="07">Julho</MenuItem>
                                                            <MenuItem value="08">Agosto</MenuItem>
                                                            <MenuItem value="09">Setembro</MenuItem>
                                                            <MenuItem value="10">Outubro</MenuItem>
                                                            <MenuItem value="11">Novembro</MenuItem>
                                                            <MenuItem value="12">Dezembro</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="col-6 col-sm-3 order-2 order-sm-3">
                                                    <TextField
                                                        type="tel"
                                                        name="documentYear"
                                                        value={documentYear}
                                                        disabled={loading}
                                                        onChange={({ target: { value }}) => setDocumentYear(value)}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                    /> 
                                                </div>
                                                { documentDateError && (
                                                    <div className="col-12 order-4">
                                                        <small className="text-danger">{ documentDateHelperText }</small>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-box">
                                    <div className="custom-content">
                                        <div className="content-action">
                                            <div className="row">
                                                <div className="col-12 mb-2">
                                                    <div className="input-title border-0">
                                                        Valor total d{ pronoum } { title }
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="content-value">
                                                        { formattedCurrency(total) }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </VSPage>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(SaleForm)