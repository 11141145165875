import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import { CircularProgress, Switch } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';

import { ReactComponent as IconAdd } from "../../assets/icon-add.svg"
import { ReactComponent as IconToggle } from "../../assets/icon-toggle.svg"
import { ReactComponent as IconWarning } from "../../assets/icon-warning.svg"

import { APP_NAME, APP_DEVICE, APP_VERSION, ACL, PRONTUARIO_PRO } from '../../constants'
import { getSelectedOptionLabel, addEvent, saveOnTracker, useQuery, dayjs, validateDate, getTrialEndsAtForProduct, redirectToCheckout } from '../../utils'
import { getBreeds, getGenders, getPatient, getPatientLimit, getPelages, getSpecies, getTemperaments, months, sizeOptions, years } from './Patient';
import { getOwnerReduxFromOwnerVetsmart, getPatientReduxFromPatientPetlove, getPetloveSaudePlan } from '../health/Health';
import { getOwner } from '../owner/Owner';

import DateInput from '../../components/dateInput/DateInput';
import VSDrawer from '../../components/vsDrawer/VSDrawer';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import VSImage from '../../components/vsImage/VSImage';
import OwnerQuery from '../owner/OwnerQuery';
import VSError from '../../components/vsError/VSError';
import HealthAlert from '../health/HealthAlert';
import { VSSimpleTooltip } from '../../components/vsTooltip/VSTooltip';
import { setHealthImport } from '../../store/actions';
import HealthStatus from '../health/HealthStatus';

import './PatientForm.scss'
const PatientForm = (props) => {

    const {
        id,
        id_animal
    } = useParams()

    const {
        state
    } = useLocation()

    const {
        clinic,
        history,
        features,
        permissions
    } = props

    const proxima = useQuery().get("proxima")
    const preFilledName = useQuery().get("name")

    const update = id_animal != "novo"

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ patient, setPatient ] = useState(null)
    const [ owner, setOwner ] = useState(null)

    const [ name, setName ] = useState(preFilledName || '')
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ specie, setSpecie ] = useState('')
    const [ species, setSpecies ] = useState([])
    const [ errorSpecie, setErrorSpecie ] = useState(false)
    const [ helperSpecie, setHelperSpecie ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ breed, setBreed ] = useState('')
    const [ breeds, setBreeds ] = useState([])

    const [ gender, setGender ] = useState('')
    const [ genders, setGenders ] = useState([])

    const [ pelage, setPelage ] = useState('')
    const [ pelages, setPelages ] = useState([])

    const [ temperament, setTemperament ] = useState('')
    const [ temperaments, setTemperaments ] = useState([])

    const [ castrated, setCastrated ] = useState('')
    const castratedOptions = [
        {
            value: "0",
            label: "Não"
        },
        {
            value: "1",
            label: "Sim"
        },
        {
            value: "2",
            label: "Indefinido"
        }
    ]

    const [ deceased, setDeceased ] = useState("0")

    const [ rga, setRga ] = useState('')
    const [ microchip, setMicrochip ] = useState('')

    const [ microchips, setMicrochips ] = useState({})
    const [ microchipsLoading, setMicrochipsLoading ] = useState(false)

    const [ birthdate, setBirthdate ] = useState(null)
    const [ errorBirthdate, setErrorBirthdate ] = useState(false)
    const [ helperBirthdate, setHelperBirthdate ] = useState(<span>Campo Opcional</span>)
    const [ birthdateText, setBirthdateText ] = useState('')
    const [ ageYear, setAgeYear ] = useState('')
    const [ ageMonth, setAgeMonth ] = useState('')
    const [ size, setSize ] = useState('')
    const [ otherInfo, setOtherInfo ] = useState('')
    const [ notes, setNotes ] = useState('')
    const [ image, setImage ] = useState(null)

    const [ health, setHealth ] = useState(null)
    const [ healthName, setHealthName ] = useState('')
    const [ healthCard, setHealthCard ] = useState('')
    const [ healthExpiresAt, setHealthExpiresAt ] = useState(null)
    const [ errorHealthExpiresAt, setErrorHealthExpiresAt ] = useState(false)
    const [ helperHealthExpiresAt, setHelperHealthExpiresAt ] = useState(<span>Campo Opcional</span>)
    const [ healthExpiresAtText, setHealthExpiresAtText ] = useState('')
  
    const [ limit, setLimit ] = useState(null)

    const handleSpecie = (selected) => {
        const specie = selected
        setSpecie(specie)
    }

    const handleBirthdate = (_date) => {
        setBirthdateText(_date)

        if (_date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g)) {
            let newDate = dayjs(_date, "DD/MM/YYYY")
            if (newDate.isValid()) {
                setBirthdate(newDate.toDate())
                const age = dayjs.duration(dayjs().diff(newDate))
                if (age.years() > 0 || age.months() > 0) {
                    setAgeYear(String(age.years()))
                    setAgeMonth(String(age.months()))
                }
                return
            }
        }
        setBirthdate(null)
        setAgeMonth('')
        setAgeYear('')
    }

    const handleHealthExpiresAt = (_date) => {
        setHealthExpiresAt(validateDate(_date))
        setHealthExpiresAtText(_date)
    }

    const handleAge = (_years, _months) => {
        const years = parseInt(_years) || 0
        const months = parseInt(_months) || 0
        const date = dayjs().subtract(years, 'years').subtract(months, 'months')
        setAgeYear(String(years))
        setAgeMonth(String(months))
        if (date.isValid()) {
            setBirthdate(date.toDate())
            setBirthdateText(date.format("DD/MM/YYYY"))
        } else {
            setBirthdate(null)
            setBirthdateText("")
        }
    }

    const validateOnTouch = () => {
        if (name || specie || birthdate || birthdateText || ageYear || ageMonth || gender || pelage || temperament || castrated || notes || healthName || healthCard || healthExpiresAtText) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }
        if (saveLoading) {
            isValid = false
        }
        if (error?.message) {
            isValid = false
        }

        if (!name) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome do animal é obrigatório")
        } else {
            setErrorName(false)
            setHelperName(<span>Campo <b>Obrigatório</b></span>)
        }

        if (!specie) {
            isValid = false
            setErrorSpecie(true)
            setHelperSpecie("A espécie do animal é obrigatória")
        } else {
            setErrorSpecie(false)
            setHelperSpecie(<span>Campo <b>Obrigatório</b></span>)
        }

        const _birthdate = dayjs(birthdate)
        if (birthdate || birthdateText.replace(/[^0-9]/g, '').length > 0 || ageYear.length > 0 || ageMonth.length > 0) {
            if (_birthdate.isValid()) {
                setErrorBirthdate(false)
                setHelperBirthdate(<span>Campo Opcional</span>)
            } else {
                isValid = false
                setErrorBirthdate(true)
                setHelperBirthdate("A data informada não é válida")
            }
        } else {
            setErrorBirthdate(false)
            setHelperBirthdate(<span>Campo Opcional</span>)
        }

        if (healthExpiresAtText) {
            if (validateDate(healthExpiresAtText)) {
                setErrorHealthExpiresAt(false)
                setHelperHealthExpiresAt(<span>Campo Opcional</span>)
            } else {
                isValid = false
                setErrorHealthExpiresAt(true)
                setHelperHealthExpiresAt("A data informada não é válida")
            }
        } else {
            setErrorHealthExpiresAt(false)
            setHelperHealthExpiresAt(<span>Campo Opcional</span>)
        }

        return isValid
    }

    const isValid = () => !loading && !saveLoading && !errorName && !errorSpecie && !errorBirthdate && !errorHealthExpiresAt

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Estamos procurando o animal")
        }
        if (saveLoading) {
            errors.push("Salvando o animal")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorName) {
            errors.push("Nome")
        }
        if (errorSpecie) {
            errors.push("Espécie")
        }
        if (errorBirthdate) {
            errors.push("Data de nascimento")
        }
        if (errorHealthExpiresAt) {
            errors.push("Validade da carteirinha")
        }
        if (!permissions?.check(ACL.PATIENT, ACL.Rule.EDIT, patient)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            if (validate()) {
                setSaveLoading(true)
                Parse.Cloud.run("savePatientForOwner", {
                    data: {
                        objectId: update ? id_animal : null,
                        name: name,
                        specie: specie,
                        breed: breed,
                        birthdate: birthdate,
                        gender: gender,
                        pelage: pelage,
                        temperament: temperament,
                        castrated: castrated,
                        deceased: deceased,
                        rga: rga,
                        microchip: microchip,
                        size: size,
                        otherInfo: otherInfo,
                        notes: notes,
                        image: image,
                        healthName: healthName,
                        healthCard: healthCard,
                        healthExpiresAt: healthExpiresAt,
                    },
                    clinicId: clinic.objectId,
                    ownerId: owner.id,
                    appVersionName: APP_VERSION,
                    deviceType: APP_DEVICE,
                    appContentType: APP_NAME
                })
                .then(_patient => {
                    saveOnTracker("Concluiu", "Animal", _patient.id)
                    setSaveLoading(false)
                    addEvent("TourPatient__registerYourFirstPatient", {})
                    addEvent("Patient__getList", {})
                    resolve(_patient)
                })
                .catch(error => {
                    setSaveLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        (error.code == 141 && error?.message) || `Ocorreu algum erro ao tentar ${update ? "editar" : "cadastrar"} o animal`,
                        'error'
                    )
                    reject()
                })
            } else {
                reject()
            }
        })
    }

    const nextStep = (patientId) => {
        if (proxima) {
            switch(proxima) {
                case "vacina":
                    return history.replace(`/animal/${patientId}/vacina/novo?selecionado`)
                case "lembrete":
                    return history.replace(`/animal/${patientId}/vacina/novo?selecionado`)
                case "consulta":
                    return history.replace(`/animal/${patientId}/consulta/novo?selecionado`)
                case "prescricao":
                    return history.replace(`/prescricao/${patientId}/novo?selecionado`)
                case "agenda":
                    return history.replace(`/agenda/novo?selecionado`, {
                        ...state,
                        id: patientId
                    })
                default:
                    return history.replace(`/animal/${patientId}?selecionado`)
            }
        }
        history.push(`/animal/${patientId}`)
    }

    const nextStepText = () => {
        const defaultText = "Você será redirecionado(a) automaticamente para "
        if (proxima) {
            switch(proxima) {
                case "vacina":
                    return `${defaultText} o Cadastro de Vacina`
                case "lembrete":
                    return `${defaultText} o Cadastro de Vacina`
                case "consulta":
                    return `${defaultText} o Cadastro de Consulta`
                case "prescricao":
                    return `${defaultText} o Cadastro da Prescrição`
            }
        }
        return `${defaultText} o Cadastro do Animal`
    }

    const getOwnerOrError = () => {
        setLoading(true)
        setError(null)
        
        getOwner(id, clinic.object)
        .then(_owner => {
            setOwner(_owner)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getOwnerOrError"
                })
            })
        })
    }

    const getLimit = async () => {
        return new Promise((resolve) => {
            getPatientLimit(clinic.object)
            .then(limit => {
                if (limit) {
                    setLimit(limit)
                    if (isBlockedByPatientLimitation(limit)) {
                        saveOnTracker("Visualizou", "Animal", "Tela de Bloqueio", String(limit.used))
                    }
                }
                return resolve(limit)
            })
        })
    }

    const isBlockedByPatientLimitation = (limit) => {
        return limit?.isLimited && limit?.available == 0
    }

    const getPatientOrError = async () => {
        if (permissions?.check(ACL.PATIENT, ACL.Rule.VIEW)) {
            setLoading(true)
            setError(null)
    
            if (update) {
                getPatient(id_animal, clinic.object)
                .then(_patient => {
                    setPatient(_patient)
                    setName(_patient.get("name"))
                    handleSpecie(_patient.get("specie"))
                    setBreed(_patient.get("breed"))
                    if (_patient.get("birthdate")) {
                        const date = dayjs(_patient.get("birthdate"))
                        handleBirthdate(date.format("DD/MM/YYYY"))
                    }
                    setGender(_patient.get("gender"))
                    setPelage(_patient.get("pelage"))
                    setTemperament(_patient.get("temperament"))
                    setCastrated(_patient.get("castrated"))
                    setDeceased(_patient.get("deceased"))
                    setRga(_patient.get("rga"))
                    setMicrochip(_patient.get("microchip"))
                    setHealthName(_patient.get("healthName"))
                    setHealthCard(_patient.get("healthCard"))
                    setHealthExpiresAt(_patient.get("healthExpiresAt"))
                    setSize(_patient.get("size"))
                    setOtherInfo(_patient.get("otherInfo"))
                    setNotes(_patient.get("notes"))
                    setImage(_patient.get("image"))
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getPatientOrError"
                        })
                    })
                })
            } else {

                await getLimit()
                // let newPatient = new Parse.Object("Patient")
                // newPatient.setACL(clinic.ACL)
                // newPatient.set("clinic", clinic.object)
                // newPatient.set("createdBy", Parse.User.current())
                // newPatient.set("createdApp", APP_NAME)
                // newPatient.set("createdDevice", APP_DEVICE)
                // newPatient.set("createdAppVersion", APP_VERSION)
                // newPatient.set("isDeleted", false)
                // newPatient.set("owner", owner)
                // setPatient(newPatient)
              
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        let preventMultipleRequests
        if (microchip && microchips[microchip] == undefined) {
            setMicrochipsLoading(true)
            preventMultipleRequests = setTimeout(() => {
                const query = new Parse.Query("Patient")
                query.equalTo("clinic", clinic.object)
                if (patient?.id) {
                    query.notEqualTo("objectId", patient.id)
                }
                query.equalTo("microchip", microchip)
                query.equalTo("isDeleted", false)
                query.find()
                .then(result => {
                    const newMicrochips = {...microchips}
                    newMicrochips[microchip] = result.length > 0 ? result : null
                    setMicrochips(newMicrochips)
                    setMicrochipsLoading(false)
                })
                .catch(error => {
                    console.error(error)
                    setMicrochipsLoading(false)
                })
            }, 300)
        }
        return () => {
            clearTimeout(preventMultipleRequests)
        }
    }, [ microchip ])

    useEffect(() => {
        let preventMultipleRequests
        if (microchip && clinic.health?.available) {
            preventMultipleRequests = setTimeout(async () => {
                const _health = await getPetloveSaudePlan(microchip, owner?.get("cpf"))
                setHealth(_health)
            }, 300)
        }
        return () => {
            clearTimeout(preventMultipleRequests)
        }
    }, [ microchip ])

    useEffect(() => {
        if (specie) {
            getBreeds(specie).then(_breeds => setBreeds(_breeds))
        }
    }, [ specie ])

    useEffect(() => {
        validateOnTouch()
    }, [ name, specie, birthdate, birthdateText, ageYear, ageMonth, healthName, healthCard, healthExpiresAtText ])

    useEffect(() => {
        if (patient && !permissions?.check(ACL.PATIENT, ACL.Rule.EDIT, patient)) {
            permissions?.error()
        }
    }, [ patient ])

    useEffect(async () => {
        if (owner) {
            getPatientOrError()
        }
    }, [ owner ])

    useEffect(async () => {
        if (clinic && clinic.isLoaded && clinic.object) {
            setLoading(true)
            saveOnTracker("Iniciou", "Animal", id !== "novo" ? id : "")
            if (id != "selecionar") {
                getOwnerOrError()
            } else {
                await getLimit()
                setLoading(false)
            }

            getSpecies().then(_species => setSpecies(_species))
            getGenders().then(_genders => setGenders(_genders))
            getPelages().then(_pelages => setPelages(_pelages))
            getTemperaments().then(_temperaments => setTemperaments(_temperaments))
        }
    }, [ clinic ])

    const errors = fieldsWithErrorText()

    const usage = microchips[microchip]

    return isBlockedByPatientLimitation(limit) ? (
        <VSDrawer
            id="patient-block"
            width="40%"
            onCancel={() => history.goBack()}
            onClose={() => history.goBack()}
            VSDrawerFooterProps={{
                show: true,
                children: (
                    <div className="row">
                        <div className="col-12">
                            <button className="btn-submit btn-square text-normal mt-2 w-100" onClick={() => {
                                // saveTrackersProSubscription(features)
                                redirectToCheckout(history, PRONTUARIO_PRO)
                            }}>
                                { !getTrialEndsAtForProduct(features, PRONTUARIO_PRO) ? "Experimentar o PRO 15 dias grátis" : "Assinar o Prontuário PRO" }
                            </button>
                        </div>
                    </div>
                )
            }}
        >
            <div className="row">
                <div className="col-12 mb-4">
                    <svg width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_38_1203" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="170" height="170">
                            <rect width="170" height="170" rx="85" fill="#D8ECFE"/>
                        </mask>
                        <g mask="url(#mask0_38_1203)">
                            <rect width="211.351" height="211.351" transform="matrix(-1 0 0 1 193.148 -18.299)" fill="#F5F5F5"/>
                            <path d="M17.4144 96.3325C22.0761 98.7983 27.7542 97.207 30.0967 92.7784C32.4392 88.3497 30.5591 82.7606 25.8974 80.2948C21.2356 77.829 15.5575 79.4202 13.215 83.8489C10.8725 88.2776 12.7526 93.8667 17.4144 96.3325Z" fill="#E8E8E8"/>
                            <path d="M39.3943 91.5389C44.0561 94.0047 49.7342 92.4135 52.0767 87.9848C54.4192 83.5561 52.5391 77.9671 47.8773 75.5013C43.2156 73.0355 37.5375 74.6267 35.195 79.0553C32.8524 83.484 34.7326 89.0731 39.3943 91.5389Z" fill="#E8E8E8"/>
                            <path d="M59.6524 102.254C64.3141 104.72 69.9922 103.129 72.3347 98.7002C74.6772 94.2715 72.7971 88.6824 68.1354 86.2166C63.4736 83.7508 57.7955 85.342 55.453 89.7707C53.1105 94.1994 54.9906 99.7884 59.6524 102.254Z" fill="#E8E8E8"/>
                            <path d="M68.0595 123.121C72.7213 125.587 78.3993 123.995 80.7419 119.567C83.0844 115.138 81.2043 109.549 76.5425 107.083C71.8807 104.617 66.2027 106.209 63.8602 110.637C61.5176 115.066 63.3978 120.655 68.0595 123.121Z" fill="#E8E8E8"/>
                            <path d="M55.9143 128.437C54.7075 123.611 53.7188 119.517 52.4781 114.674C51.8412 112.12 50.7653 109.335 48.809 107.315C48.5055 106.99 48.185 106.697 47.8475 106.437C47.0713 105.862 46.1597 105.38 45.2818 104.915C44.404 104.451 43.4924 103.969 42.5297 103.665C42.1244 103.533 41.7021 103.432 41.2628 103.364C38.4922 102.884 35.6178 103.58 33.1147 104.473C28.4468 106.191 24.506 107.678 19.8043 109.378C13.1587 111.24 5.26275 113.016 2.83156 120.063C2.08016 123.852 2.83133 128.396 6.76232 131.665C8.97256 133.45 17.8404 135.719 26.2138 140.148L26.8215 140.469C35.1948 144.898 42.0777 150.92 44.7799 151.774C49.695 153.184 53.8902 151.216 56.5829 148.494C61.0906 142.504 58.1148 134.978 55.9143 128.437Z" fill="#E8E8E8"/>
                            <path d="M75.4083 131.951C75.4083 131.951 79.3105 102.973 75.4083 73.3142C71.729 45.3465 73.1419 40.1659 83.0326 29.2418C92.9239 18.3185 130.181 23.2045 134.919 32.6206C139.039 40.8071 154.079 60.5422 155.554 91.7595C157.028 122.977 155.107 125.245 155.107 125.245L75.4083 131.951Z" fill="#081647"/>
                            <path d="M128.352 76.7164C128.352 76.7164 139.007 103.125 131.318 113.012L121.41 103.554L119.145 87.862L128.352 76.7164Z" fill="#000E2D"/>
                            <path d="M91.0527 83.915C91.0527 83.915 87.2644 114.133 99.5585 119.743L109.005 105.528L105.508 89.9925L91.0527 83.915Z" fill="#000E2D"/>
                            <path d="M99.1973 108.082L103.475 91.7595L124.173 81.733C124.173 81.733 123.101 92.9262 128.353 108.082H99.1973Z" fill="#AA67B7"/>
                            <path d="M92.0737 110.963L100.546 104.731L131.383 102.818L133.747 106.906L123.365 169.801L130.245 238.466L128.229 238.804C127.211 238.975 126.188 239.107 125.161 239.201L78.6504 243.447L92.0737 110.963Z" fill="#00C4B4"/>
                            <path d="M98.9193 111.644L97.9815 104.036C97.8162 102.696 98.9534 101.499 100.506 101.378L128.429 99.1971C129.186 99.1382 129.843 99.6415 129.866 100.297L130.18 109.349L122.095 121.207L113.783 123.492H103.621L97.7754 118.098L98.9193 111.644Z" fill="#00C4B4"/>
                            <path d="M78.8633 116.535C78.8633 116.535 68.6634 123.168 65.9834 135.147C63.8764 144.563 61.2868 194.518 61.2868 194.518L48 213.401L52.9082 227.051H54.5145C60.167 227.439 65.8636 227.468 70.4505 224.238L74.8915 221.11L83.0332 209.383L78.8633 116.535Z" fill="#D6E5F8"/>
                            <path d="M96.1563 107.752L78.8622 116.535L74.7814 129.556L71.1443 167.294L77.9908 211.537L67.3887 244.265L86.8272 242.783L87.184 227.026L89.2515 156.687L91.2836 111.881L96.1563 107.752Z" fill="#D6E5F8"/>
                            <path d="M155.487 114.379L143 109.426L129.835 101.938L129.752 104.128C131.364 106.808 131.92 109.965 131.318 113.013L120.363 168.496L122.848 209.031L126.702 239.061L160.808 233.348L149.367 207.212L155.786 151.23L155.487 114.379Z" fill="#D6E5F8"/>
                            <path opacity="0.5" d="M120.363 168.495C120.363 168.495 125.3 167.036 131.129 155.156C131.129 155.156 135.689 144.342 133.961 133.876H136.583C136.583 133.876 138.754 118.135 135.019 108.244L128.787 136.958L120.363 168.495Z" fill="#9EC7F3"/>
                            <path d="M158.361 115.459C165.749 118.237 171.229 124.394 172.981 131.894C178.636 156.106 188.318 188.31 188.47 198.7C188.664 211.933 182.703 220.991 174.923 222.662C168.77 223.982 112.997 221.019 112.997 221.019L110.389 200.846L155.552 194.391L149.368 160.843L155.488 114.379L158.361 115.459Z" fill="#D6E5F8"/>
                            <path d="M120.363 168.495C120.363 168.495 137.345 152.586 132.228 132.659L135.417 132.593C135.417 132.593 139.691 104.419 129.852 101.937C129.852 101.937 125.509 146.377 120.363 168.495Z" fill="#FFF5F6"/>
                            <path d="M89.0001 168.69C89.0001 168.69 79.1402 156.71 82.0987 135.174H85.2726L81.5451 130.867C81.5451 130.867 81.6054 121.62 87.5215 111.168C87.5215 111.168 92.1807 108.525 96.1572 107.753C96.1565 107.752 88.8508 135.542 89.0001 168.69Z" fill="#FFF5F6"/>
                            <path d="M127.093 106.202C127.087 106.226 126.679 106.167 125.937 106.105C125.196 106.046 124.118 105.997 122.79 106.062C121.462 106.124 119.889 106.305 118.166 106.609C116.441 106.906 114.566 107.323 112.58 107.72C110.594 108.116 108.673 108.438 106.893 108.551C105.116 108.669 103.49 108.564 102.179 108.289C100.863 108.025 99.8725 107.615 99.2373 107.285C98.9207 107.117 98.6865 106.973 98.5345 106.868C98.3839 106.762 98.309 106.704 98.317 106.696C98.3364 106.675 98.6624 106.886 99.3096 107.186C99.9575 107.481 100.943 107.853 102.238 108.086C103.528 108.328 105.119 108.41 106.866 108.279C108.616 108.153 110.516 107.827 112.495 107.432C114.475 107.037 116.358 106.626 118.099 106.341C119.838 106.05 121.433 105.888 122.778 105.852C124.124 105.814 125.213 105.896 125.955 105.991C126.326 106.041 126.613 106.086 126.804 106.128C126.996 106.167 127.095 106.192 127.093 106.202Z" fill="#00786E"/>
                            <g opacity="0.35">
                                <g opacity="0.35">
                                    <path opacity="0.35" d="M99.7117 107.377C98.9386 109.122 98.9165 111.285 100.485 112.624C101.765 113.715 103.817 113.987 105.626 113.691C107.434 113.394 109.062 112.613 110.646 111.839C114.622 109.896 118.661 107.884 123.178 107.06C123.467 107.007 123.776 106.952 123.989 106.782C124.202 106.612 124.255 106.283 124.015 106.14C123.908 106.076 123.772 106.062 123.641 106.052C119.646 105.723 115.681 106.65 111.764 107.372C107.847 108.094 103.665 108.605 99.8891 107.487" fill="#00786E"/>
                                </g>
                            </g>
                            <path d="M105.228 100.98C105.257 101.023 104.666 101.137 104.24 101.756C103.784 102.357 103.809 103.378 103.845 104.481C103.919 106.695 104.364 108.464 104.259 108.482C104.212 108.49 104.042 108.058 103.868 107.338C103.692 106.619 103.526 105.611 103.485 104.49C103.47 103.929 103.463 103.399 103.524 102.904C103.587 102.412 103.757 101.964 104.024 101.645C104.285 101.325 104.601 101.134 104.839 101.049C105.076 100.963 105.224 100.964 105.228 100.98Z" fill="#00786E"/>
                            <path d="M112.86 107.481C112.747 107.472 113.006 105.841 112.653 103.861C112.321 101.878 111.521 100.37 111.627 100.335C111.668 100.32 111.921 100.677 112.218 101.299C112.515 101.918 112.835 102.809 113.011 103.819C113.184 104.829 113.178 105.757 113.103 106.42C113.027 107.086 112.904 107.487 112.86 107.481Z" fill="#00786E"/>
                            <path d="M122.156 99.7092C122.192 99.6931 122.455 100.009 122.707 100.589C122.959 101.166 123.159 102.018 123.085 102.961C123.007 103.904 122.671 104.727 122.328 105.272C121.985 105.819 121.673 106.104 121.64 106.085C121.535 106.044 122.597 104.769 122.725 102.942C122.892 101.117 122.045 99.7387 122.156 99.7092Z" fill="#00786E"/>
                            <path d="M90.0997 39.1553C90.0997 39.1553 92.2242 48.7869 106.708 56.1006C121.193 63.4143 120.607 69.1103 120.607 69.1103C120.607 69.1103 123.233 62.676 129.216 65.7295C129.216 65.7295 133.777 69.4489 129.373 76.7158C124.969 83.9827 120.365 83.6159 120.365 83.6159C120.365 83.6159 112.847 95.9997 103.904 96.3217C94.2489 96.6691 87.8434 92.6913 83.2478 80.0972C78.6523 67.5032 79.4133 51.5324 90.0997 39.1553Z" fill="#AA67B7"/>
                            <path d="M90.1914 39.1432L91.2041 41.8339C93.3961 47.6577 97.5326 52.5913 102.994 55.5725C103.188 55.6789 103.386 55.7833 103.586 55.8857C106.039 57.1434 109.224 58.7056 111.615 60.079C115.235 62.1593 119.563 66.0902 120.604 69.1096C120.604 69.1096 120.615 62.9431 107.979 56.729C94.3359 50.0204 90.1914 39.1432 90.1914 39.1432Z" fill="#723B7D"/>
                            <path d="M90.1929 39.1432C90.1929 39.1432 82.8818 49.8551 80.6623 67.7488C80.6623 67.7488 78.0493 53.2144 90.1929 39.1432Z" fill="#723B7D"/>
                            <path d="M124.172 82.3629C124.172 82.3629 123.119 83.6694 120.363 83.6166C120.363 83.6166 115.741 95.0727 102.272 96.3431L101.889 97.8063C101.889 97.8063 114.829 97.4642 120.604 84.6179C120.605 84.6172 123.126 83.972 124.172 82.3629Z" fill="#723B7D"/>
                            <path opacity="0.25" d="M96.1573 107.752C96.1573 107.752 90.5804 137.586 89.8234 193.95L88.1602 193.849C88.1602 193.849 89.4111 158.006 89.4406 155.412C89.47 152.817 91.7504 118.351 96.1573 107.752Z" fill="#00786E"/>
                        </g>
                        <path d="M36 64C51.464 64 64 51.464 64 36C64 20.536 51.464 8 36 8C20.536 8 8 20.536 8 36C8 51.464 20.536 64 36 64Z" fill="#1993F7"/>
                        <path d="M45.5889 30.3588H44.0767V27.3345C44.0767 23.1608 40.6894 19.7735 36.5157 19.7735C32.3421 19.7735 28.9548 23.1608 28.9548 27.3345V30.3588H27.4426C25.7792 30.3588 24.4182 31.7198 24.4182 33.3832V48.5051C24.4182 50.1685 25.7792 51.5295 27.4426 51.5295H45.5889C47.2523 51.5295 48.6133 50.1685 48.6133 48.5051V33.3832C48.6133 31.7198 47.2523 30.3588 45.5889 30.3588ZM31.9792 27.3345C31.9792 24.8242 34.0055 22.7979 36.5157 22.7979C39.026 22.7979 41.0523 24.8242 41.0523 27.3345V30.3588H31.9792V27.3345ZM45.5889 48.5051H27.4426V33.3832H45.5889V48.5051ZM36.5157 43.9686C38.1791 43.9686 39.5401 42.6076 39.5401 40.9442C39.5401 39.2808 38.1791 37.9198 36.5157 37.9198C34.8523 37.9198 33.4914 39.2808 33.4914 40.9442C33.4914 42.6076 34.8523 43.9686 36.5157 43.9686Z" fill="white"/>
                    </svg>
                </div>
                <div className="col-12 mb-3">
                    <h3>Cadastre mais animais na sua clínica</h3>
                </div>
                <div className="col-12">
                    <p>
                        Você chegou ao limite de <b>{ limit?.total } cadastros de animais</b> permitido no seu plano atual. Assine o Prontuário PRO para cadastrar quantos animais precisar e ampliar a capacidade de gerenciamento da sua clínica sem restrições.
                    </p>
                </div>
            </div>
        </VSDrawer>
    ) : (
        <VSDrawer
            title={update ? "Editar Cadastro de Animal" : "Novo Cadastro de Animal"}
            cancel={update ? "Cancelar Alterações" : <span>Cancelar<span className="d-none d-md-inline"> Novo</span> Cadastro</span>}
            submit={isValid ? ( update ? "Salvar Alterações" : "Salvar Cadastro" ) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => nextStep(object.id)}
            VSDrawerSuccessProps={{
                title: update ? "Alterações salvas com sucesso!" : "Cadastro realizado com sucesso!" ,
                text: nextStepText()
            }} 
            VSDrawerCancelProps={{
                title: update ? "Tem certeza que deseja cancelar as alterações no cadastro do animal? " : "Tem certeza que deseja cancelar o novo cadastro de animal?",
                confirm: update ? "Cancelar  Alterações" : "Cancelar Novo Cadastro",
                cancel: update ? "Voltar para Edição do Cadastro" : "Voltar para Edição do Novo Cadastro"
            }}
            VSDrawerFooterProps={{
                show: owner ? true : false
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getOwnerOrError":
                                getOwnerOrError()
                                break
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                !owner ? (
                    <OwnerQuery
                        history={history}
                        onSelected={(_owner) => setOwner(_owner)}
                        onCreate={() => history.push(`/tutor/form/novo`)}
                        text={`Para ${ update ? "editar" : "cadastrar" } um animal, é preciso antes selecionar o(a) tutor(a) a quem este animal ficará vinculado`}
                    />
                ) : (
                    <>
                        <div className="vssearch">
                            <div className="vssearch-area">
                                <div className="row flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <div className="col">
                                        <div className="vssearch-text">
                                            { owner.get("name") }
                                        </div>
                                        <div className="vssearch-result">
                                            CPF: { owner.get("cpf") || "Não informado" }
                                            { " - " }
                                            RG: { owner.get("rg") || "Não informado" }
                                        </div>
                                    </div>
                                    <div className="col-auto d-flex d-sm-block justify-content-center mt-3 mt-sm-0">
                                        <button
                                            className="vssearch-toggle d-flex d-sm-inline-block"
                                            onClick={() => {
                                                history.push(`/tutor/selecionar/animal/${id_animal}`)
                                                setOwner(null)
                                            }}
                                        >
                                            <IconToggle className="me-2 me-sm-auto" width="24px" height="24px" />
                                            Trocar Tutor(a)
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { (!update || patient) && (
                            <>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <p className="my-3" style={{ 
                                            textTransform: "uppercase",
                                            fontWeight: 600
                                        }}>
                                            Animal está vivo?
                                        </p>
                                    </div>
                                    <div className="col-auto">
                                        <Switch
                                            checked={deceased != "1"}
                                            onChange={({ target: { checked } }) => setDeceased(checked ? "0" : "1")}
                                            color="primary"
                                        />
                                    </div>
                                </div>
                                { deceased == "1" && deceased != patient?.get("deceased") && (
                                    <div className="vsalert vsalert-warning mb-3">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <IconWarning width="24px" height="24px" />
                                            </div>
                                            <div className="col ps-0">
                                                <h4 className="vsalert-title">Você definiu que o animal não esta vivo.</h4>
                                                <p className="vsalert-text">Ao salvar essa alteração, todas as programações de vacinas e as próximas vacinas vinculadas a este animal serão excluídas. Elas não poderão ser recuperadas e esta operação não poderá ser desfeita.</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <hr/>
                                <div className="row mt-2">
                                    <div className="col">
                                        <VSAccordionSimple
                                            expanded={true}
                                            header={(_) => (
                                                "Identificação"
                                            )}
                                        >
                                            <div className="row flex-nowrap flex-column flex-md-row">
                                                <div className="col-12 col-md-auto flex-shrink-0 mb-3">
                                                    <VSImage
                                                        className="w-100"
                                                        getImage={(image) => setImage(image)}
                                                        image={image}
                                                        cropperOptions={{
                                                            aspectRatio: 1 / 1
                                                        }}
                                                        style={{
                                                            image: {
                                                                width: '82px',
                                                                height: '82px',
                                                                borderRadius: '50%'
                                                            },
                                                            buttons: {
                                                                width: 'calc(100% - 168px)',
                                                                float: 'right',
                                                                height: '148px',
                                                                alignItems: 'center',
                                                                changeButton: {
                                                                    paddingLeft: 0
                                                                },
                                                                deleteButton: {
                                                                    paddingLeft: 0
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <IconAdd width="24" height="24" />
                                                        Adicionar<br/> 
                                                        Foto de Perfil<br/> 
                                                        (opcional)
                                                    </VSImage>
                                                </div>
                                                <div className="col-12 col-md-auto flex-shrink-1">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-7 col-xl-12 order-1 mb-3">
                                                            <TextField
                                                                placeholder="Nome do animal"
                                                                label="Nome do animal"
                                                                value={name}
                                                                onChange={({ target: { value } }) => setName(value)}
                                                                error={errorName}
                                                                helperText={helperName}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-5 col-xl-4 order-2 mb-3">
                                                            <TextField
                                                                placeholder="000000000000000"
                                                                label="Microchip"
                                                                value={microchip}
                                                                onChange={({ target: { value } }) => setMicrochip(value)}
                                                                helperText={(
                                                                    <>
                                                                    { microchipsLoading ? (
                                                                        <CircularProgress size={14} className="me-1" />
                                                                    ) : usage && (
                                                                            <VSSimpleTooltip
                                                                                title={
                                                                                    <div className="p-3">
                                                                                        <p className="mb-1">O microchip está em uso no{usage.length == 1 ? "" : "s"} anima{usage.length == 1 ? "l" : "is"} abaixo:</p>
                                                                                        <table className="table table-borderless mb-0">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Microchip</th>
                                                                                                    <th>Nome</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                { usage.map((patient, index) => (
                                                                                                    <tr key={index}>
                                                                                                        <td>{ patient.get("microchip") }</td>
                                                                                                        <td>{ patient.get("name") }</td>
                                                                                                    </tr>
                                                                                                )) }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <IconWarning width={16} height={16} className="me-1" />
                                                                            </VSSimpleTooltip>
                                                                        )}
                                                                        Campo Opcional
                                                                    </>
                                                                )}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-xl-4 order-4 order-xl-3 mb-3">
                                                            <Autocomplete
                                                                inputValue={specie}
                                                                options={species}
                                                                onChange={(_, selected) => handleSpecie(selected || "")}
                                                                onInputChange={(event, selected) => {
                                                                    if (event){
                                                                        handleSpecie(selected)
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Espécie do animal"
                                                                        label="Espécie"
                                                                        error={errorSpecie}
                                                                        helperText={helperSpecie}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                                freeSolo
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-xl-4 order-5 order-sm-6 order-xl-4 mb-3">
                                                            <Autocomplete
                                                                inputValue={breed}
                                                                options={breeds}
                                                                onChange={(_, selected) => setBreed(selected || '')}
                                                                onInputChange={(event, selected) => event && setBreed(selected || '')}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Raça do animal"
                                                                        label="Raça"
                                                                        helperText={<span>Campo Opcional<br/></span>}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                                freeSolo
                                                            />
                                                        </div>
                                                        { health && (
                                                            <div className="col-12 order-3 order-xl-5 mb-3">
                                                                { patient?.get("petloveSaudeId") ? (
                                                                    <HealthStatus pet={patient?.get("petloveSaudeData")} patient={patient} history={history} />
                                                                ) : (
                                                                    <HealthAlert
                                                                        type="info"
                                                                        onClick={() => {
                                                                            const petSameChip = health?.pets?.find(pet => pet.num_chip == microchip)
                                                                            if (petSameChip) {
                                                                                const healthImport = {
                                                                                    petlove: health,
                                                                                    vetsmart: owner,
                                                                                    owner: getOwnerReduxFromOwnerVetsmart(owner),
                                                                                    pet: getPatientReduxFromPatientPetlove(petSameChip),
                                                                                    patient: patient,
                                                                                    importPatientOnly: true
                                                                                }
                                                                                props.dispatch(setHealthImport(healthImport))
                                                                                history.push(`/petlove-saude/animal/editar`)
                                                                            } else {
                                                                                const healthImport = {
                                                                                    petlove: health,
                                                                                    vetsmart: owner
                                                                                }
                                                                                props.dispatch(setHealthImport(healthImport))
                                                                                history.push(`/petlove-saude`)
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                        <div className="col-6 col-xl-3 col-xxl-4 order-6 order-sm-5 order-xl-6 mb-3">
                                                            <Autocomplete
                                                                value={gender}
                                                                options={genders}
                                                                onChange={(_, selected) => setGender(selected || '')}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Sexo do animal"
                                                                        label="Sexo"
                                                                        helperText={<span>Campo Opcional<br/></span>}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                            />
                                                        </div>
                                                        <div className="col-6 col-sm-auto order-7 mb-3">
                                                            <DateInput
                                                                className="my-0"
                                                                placeholder="00/00/0000"
                                                                label="Data de nascimento"
                                                                value={birthdateText || null}
                                                                error={errorBirthdate}
                                                                helperText={helperBirthdate}
                                                                onChange={(value) => handleBirthdate(value)}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                        <div className="col-2 col-sm-2 order-8 mb-3 text-center px-0">
                                                            <div className="separator-text">OU</div>
                                                        </div>
                                                        <div className="col-10 col-sm order-9">
                                                            <div className="row">
                                                                <div className="col-6 pe-1 mb-3">
                                                                    <Autocomplete
                                                                        value={ageYear}
                                                                        options={years}
                                                                        onChange={(_, selected) => handleAge(selected, ageMonth)}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                type="tel"
                                                                                placeholder="0"
                                                                                label="Anos"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                            />
                                                                        )}
                                                                        openOnFocus
                                                                    />
                                                                </div>
                                                                <div className="col-6 ps-1 mb-3">
                                                                    <Autocomplete
                                                                        value={ageMonth}
                                                                        options={months}
                                                                        onChange={(_, selected) => handleAge(ageYear, selected)}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                type="tel"
                                                                                placeholder="0"
                                                                                label="Meses"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                            />
                                                                        )}
                                                                        openOnFocus
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </VSAccordionSimple>
                                    </div>
                                </div>
                                <hr className="my-2" />
                                <div className="row">
                                    <div className="col">
                                        <VSAccordionSimple
                                            expanded={true}
                                            header={(_) => (
                                                "Dados Complementares"
                                            )}
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-6 order-1 mb-3">
                                                            <Autocomplete
                                                                inputValue={temperament}
                                                                options={temperaments}
                                                                onChange={(_, selected) => setTemperament(selected || '')}
                                                                onInputChange={(event, selected) => event && setTemperament(selected || '')}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Temperamento do animal"
                                                                        label="Temperamento"
                                                                        helperText={<span>Campo Opcional<br/></span>}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                                freeSolo
                                                            />
                                                        </div>
                                                        <div className="col-12 order-3 mb-3">
                                                            <Autocomplete
                                                                inputValue={pelage}
                                                                options={pelages}
                                                                onChange={(_, selected) => setPelage(selected || '')}
                                                                onInputChange={(event, selected) => event && setPelage(selected || '')}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Pelagem do animal"
                                                                        label="Pelagem"
                                                                        helperText={<span>Campo Opcional<br/></span>}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                                freeSolo
                                                            />
                                                        </div>
                                                        <div className="col-6 order-2 mb-3">
                                                            <Autocomplete
                                                                value={size || null}
                                                                options={sizeOptions}
                                                                onChange={(_, selected) => setSize(selected || '')}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Porte do animal"
                                                                        label="Porte"
                                                                        helperText={<span>Campo Opcional</span>}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                            />
                                                        </div>
                                                        <div className="col-6 order-4 mb-3">
                                                            <Autocomplete
                                                                value={castrated}
                                                                options={castratedOptions}
                                                                getOptionLabel={(option) => getSelectedOptionLabel(option, castratedOptions, castrated)}
                                                                getOptionSelected={(option) => option.value === castrated}
                                                                onChange={(_, selected) => setCastrated(selected?.value || '')}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="O animal foi castrado?"
                                                                        label="Castrado"
                                                                        helperText={<span>Campo Opcional<br/></span>}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                            />
                                                        </div>
                                                        <div className="col-6 order-5">
                                                            <TextField
                                                                placeholder="0.000.000"
                                                                label="RGA"
                                                                value={rga}
                                                                onChange={({ target: { value } }) => setRga(value)}
                                                                helperText={<span>Campo Opcional</span>}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-4 mb-3">
                                                            <TextField
                                                                placeholder="Ex: Petlove Saúde"
                                                                label="Plano de Saúde"
                                                                value={healthName}
                                                                onChange={({ target: { value } }) => setHealthName(value)}
                                                                helperText={<span>Campo Opcional</span>}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-4 mb-3">
                                                            <TextField
                                                                placeholder="000 000 000 000 000"
                                                                label="Nº da Carteirinha"
                                                                value={healthCard}
                                                                onChange={({ target: { value } }) => setHealthCard(value)}
                                                                helperText={<span>Campo Opcional</span>}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-4">
                                                            <DateInput
                                                                placeholder="00/00/0000"
                                                                label="Validade da carteirinha"
                                                                value={healthExpiresAtText || null}
                                                                error={errorHealthExpiresAt}
                                                                helperText={helperHealthExpiresAt}
                                                                onChange={(value) => handleHealthExpiresAt(value)}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3 mt-md-0 mb-3">
                                                    <TextField
                                                        placeholder="Outros elementos de identificação do animal"
                                                        label="Outras informações"
                                                        value={otherInfo}
                                                        onChange={({ target: { value } }) => setOtherInfo(value)}
                                                        helperText={<span>Campo Opcional</span>}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline
                                                    />
                                                </div>
                                            </div>
                                        </VSAccordionSimple>
                                    </div>
                                </div>
                                <hr className="my-2" />
                                <div className="row">
                                    <div className="col">
                                        <VSAccordionSimple
                                            expanded={true}
                                            header={(_) => (
                                                "Observações Internas"
                                            )}
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    <TextField
                                                        placeholder="Anotações particulares (temperamento, característica, etc.)"
                                                        label="Observações do animal"
                                                        value={notes}
                                                        onChange={({ target: { value } }) => setNotes(value)}
                                                        helperText={<span>Campo Opcional<br/></span>}
                                                        size="small"
                                                        variant="outlined"
                                                        multiline
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col input-info">
                                                    As observações são anotações particulares suas sobre o animal,
                                                    como temperamento, alguma característica específica do animal, etc. <b>Elas nunca serão compartilhadas com o tutor!</b>
                                                </div>
                                            </div>
                                        </VSAccordionSimple>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        features: state.features,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(PatientForm);