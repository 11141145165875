
import * as constants from "../constants"

export const logIn = (user) => ({
    type: constants.API_LOGIN,
    payload: user
})

export const logOut = (user) => ({
    type: constants.API_LOGOUT,
    payload: user
})

export const getUser = (user) => ({
    type: constants.API_USER,
    payload: user
})

export const getSelectedClinicUser = (clinicUser) => ({
    type: constants.API_CLINIC_USER,
    payload: clinicUser
})

export const getOnboarding = (onboarding) => ({
    type: constants.API_ONBOARDING,
    payload: onboarding
})

export const getProntuarioPro = (subscription) => ({
    type: constants.API_PRO,
    payload: subscription
})

export const setShowingTag = (isShowing) => ({
    type: constants.API_TAG,
    payload: isShowing
})

export const setHealthOnboarding = (show) => ({
    type: constants.API_HEALTH_ONBOARDING,
    payload: show
})

export const resetHealthImport = () => ({
    type: constants.API_HEALTH_IMPORT_RESET,
    payload: null
})

export const setHealthImport = (data) => ({
    type: constants.API_HEALTH_IMPORT,
    payload: data
})

export const setHealthImportOwner = (data) => ({
    type: constants.API_HEALTH_IMPORT_OWNER,
    payload: data
})

export const setHealthImportPatient = (data) => ({
    type: constants.API_HEALTH_IMPORT_PATIENT,
    payload: data
})

export const setHealthImportPatientEdit = (data) => ({
    type: constants.API_HEALTH_IMPORT_PATIENT_EDIT,
    payload: data
})

export const setHealthImportPatientSelect = (data) => ({
    type: constants.API_HEALTH_IMPORT_PATIENT_SELECT,
    payload: data
})