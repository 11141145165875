import React, { useState } from "react"
import { connect } from "react-redux"

import { getTrialEndsAtForProduct, redirectToCheckout, saveOnTracker, saveTrackersProSubscription } from "../../utils";

import VSDrawer from "../../components/vsDrawer/VSDrawer";

import { ReactComponent as IconVirtualAssistantSelect } from "../../assets/virtual-assistant-select.svg"
import { ReactComponent as IconVirtualAssistantDiagnosis } from "../../assets/icon-virtual-assistant-diagnosis.svg"
import { ReactComponent as IconVirtualAssistantHistoric } from "../../assets/icon-virtual-assistant-historic.svg"
import { ReactComponent as IconVirtualAssistantAnamnesisFormat } from "../../assets/icon-virtual-assistant-anamnesis-format.svg"
import { ReactComponent as IconVirtualAssistantAnamnesisRecord } from "../../assets/icon-virtual-assistant-anamnesis-record.svg"
import { ReactComponent as IconIA } from "../../assets/icon-ia.svg"
import { ReactComponent as IconHeaderIA } from "../../assets/icon-header-ia.svg"

import './VirtualAssistantFreeOverlay.scss'
const VirtualAssistantFreeOverlay = (props) => {

    const {
        features,
        history,
        clinic
    } = props

    // const {
    //     assistant
    // } = useParams()

    return (
        <VSDrawer
            id="virtual-assistant-free-overlay"
            // width="40%"
            width="385px"
            title={
                <div className="row">
                    <div className="col-12">
                        <IconHeaderIA className="me-0 me-lg-2" width="24px" height="24px" fill="#FF8A00" />
                        Assistente Vetsmart
                    </div>
                </div>
            }
            onCancel={() => history.goBack()}
            onClose={() => history.goBack()}
            VSDrawerFooterProps={{
                show: true,
                children: (
                    <div className="row">
                        <div className="col-12">
                            <button className="btn-submit btn-square text-normal mt-2 w-100" onClick={() => {
                                saveTrackersProSubscription(features)
                                redirectToCheckout(history, "prontuario_ai")
                            }}>
                                { !getTrialEndsAtForProduct(features, 'prontuario_ai') ? "Experimentar o Assistente 15 dias grátis" : "Assinar o Assistente Vetsmart" }
                            </button>
                        </div>
                    </div>
                )
            }}
        >
            <div className="text-center mx-auto mb-4">
                <IconVirtualAssistantSelect className="mb-4" />
                <h3 className="virtual-assistant-free-overlay-title">Assistente Vetsmart</h3>
                <p className="virtual-assistant-intro-text">{!getTrialEndsAtForProduct(features, 'prontuario_ai') ? "Experimente" : "Assine"} o Assistente Vetsmart e otimize seu tempo com resumos clínicos e e possibilidades de diagnósticos diferenciais.</p>
            </div>
            <div className="row justify-content-center">
                <div className="col-auto">
                    <button className="btn-link text-normal text-start d-block no-action">
                        <IconVirtualAssistantHistoric className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                        Resumo do histórico clínico
                    </button>
                    <button className="btn-link text-normal text-start d-block no-action">
                        <IconVirtualAssistantDiagnosis className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                        Gerar Diagnóstico diferencial
                    </button>
                    <button className="btn-link text-normal text-start d-block no-action">
                        <IconVirtualAssistantAnamnesisRecord className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                        Gravar Anamnese
                    </button>
                    <button className="btn-link text-normal text-start d-block no-action">
                        <IconVirtualAssistantAnamnesisFormat className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                        Organizar Anamnese
                    </button>
                    {/* <div className="soon-text px-3">
                        <IconIA className="me-2" width="24" height="24" fill="#FF8A00" />
                        Em breve novas funcionalidades
                    </div> */}
                </div>
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        features: state.features,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(VirtualAssistantFreeOverlay)