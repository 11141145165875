import React, { forwardRef, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types';
import { SwipeableDrawer } from '@mui/material';

import { ReactComponent as IconArrowRight } from "../../assets/icon-arrow-right.svg"

import "./VSDrawerInfo.scss"
const VSDrawerInfo = forwardRef((props, ref) => {

    const {
        title,
        close,
        children,
        onClose
    } = props

    const [ open, setOpen ] = useState(false)

    useImperativeHandle(ref, () => ({
        onOpen: setOpen,
     }));

    return (
        <div className="vsdrawer-info col-12">
            <div className="vsdrawer-info-wrap row align-items-center">
                {title &&
                <div className="col-12 d-block d-md-none">
                    <button
                        className="vsbox-btn vsbox-btn-primary"
                        onClick={() => setOpen(true)}
                    >
                        { title || "Ver prévia" }
                    </button>
                </div>}
                <SwipeableDrawer
                    anchor="bottom"
                    open={open}
                    className="vsdrawer-bottomsheet"
                    onOpen={() => null}
                    onClose={() => setOpen(false)}
                    PaperProps={{
                        style: {
                            width: "100%"
                        }
                    }}
                >
                    <div className="vsdrawer-info-header" onClick={() => {
                        setOpen(false)
                        if(onClose){
                            onClose()
                        }
                    }}>
                        <div className="row g-3 align-items-center">
                            <div className="col-auto pe-1">
                                <IconArrowRight width={18} height={18} style={{ transform: "rotate(180deg)" }} />
                            </div>
                            <div className="col text-capitalize">
                                { close || "Voltar" }
                            </div>
                        </div>
                    </div>
                    <div className="vsdrawer-info-body">
                        { children }
                    </div>
                </SwipeableDrawer>
                <div className="vsdrawer-info-container col-12 d-none d-md-block">
                    <div className="vsdrawer-info-content">
                        { children }
                    </div>
                </div>
            </div>
        </div>
    )
})

VSDrawerInfo.propTypes = {
    children: PropTypes.node
}

VSDrawerInfo.defaultProps = {
    children: <div></div>
}

export default VSDrawerInfo