import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import { ReactComponent as IconPrimaryPaw } from "../../assets/icon-primary-paw.svg"
import { ReactComponent as IconAdd } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconPrimaryTrash } from "../../assets/icon-primary-trash.svg"

import { deletePatient, queryForSearchByPatientAndOwner } from './Patient';
import { addEvent, diacriticSensitive } from '../../utils';
import { ACL, OrderBy_Alphabetical, OrderBy_AlphabeticalDescending, OrderBy_Oldest, OrderBy_Newest } from '../../constants';

import VSEmpty from '../../components/vsEmpty/VSEmpty';
import VSList from '../../components/vsList/VSList';
import BoxPatient from './BoxPatient';

import './PatientList.scss'
const Patient = (props) => {

    const {
        clinic,
        history,
        location,
        permissions
    } = props

    const rowsPerPage = 10

    const filter = useRef(null)
    const [ firstPatientByOnboarding, setFirstPatientByOnboarding ] = useState(null)

    const getPatients = (searchBy, dateFrom, dateTo, orderBy, page) => {
        return new Promise(async (resolve, reject) => {
            const queryPatient = await queryForSearchByPatientAndOwner(searchBy, clinic.object)
            const query = new Parse.Query("Patient");
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            if (queryPatient?.length) {
                query.containedIn("objectId", queryPatient)
            }
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            switch (orderBy) {
                case OrderBy_Alphabetical:
                    query.ascending("name");
                    break;
                case OrderBy_AlphabeticalDescending:
                    query.descending("name");
                    break;
                case OrderBy_Oldest:
                    query.ascending("createdAt");
                    break;
                default:
                    query.descending("createdAt");
            }
            query.addAscending("objectId")
            query.withCount()
            query.skip((page - 1) * rowsPerPage)
            query.limit(rowsPerPage)
            query.include("owner");
            query.find()
            .then(data => {
                const {
                    results,
                    count
                } = data
                setFirstPatientByOnboarding(results && results[0]?.id)
                resolve({
                    items: results,
                    total: count
                })
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
    
    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (firstPatientByOnboarding) {
            addEvent([
                "TourAppointment__showFirstPatientToSelect",
                "TourVaccine__showFirstPatientToSelect",
                "TourPrescription__showFirstPatientToSelect"
            ], {
                patientId: firstPatientByOnboarding
            })
        }
    }, [ location, firstPatientByOnboarding ])

    useEffect(() => {
        if (permissions && !permissions.check(ACL.PATIENT, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        document.addEventListener("Patient__getList", onChange)
        return () => {
            document.removeEventListener("Patient__getList", onChange)
        }
    }, [])

    return (
        <div id="patient" className="row">
            <div className="col-12">
                <div className="page-content">
                    <VSList
                        ref={filter}
                        title={{
                            text: "Animais"
                        }}
                        button={permissions?.check(ACL.PATIENT, ACL.Rule.EDIT) && {
                            text: "Cadastrar Novo Animal",
                            onClick: () => history.push(`/tutor/selecionar/animal/novo`)
                        }}
                        search={{
                            text: "Buscar por Nome do Animal, ID ou Tutor(a)",
                            value: props.patient || ""
                        }}
                        order={{
                            options: [
                                OrderBy_Newest,
                                OrderBy_Oldest,
                                OrderBy_Alphabetical,
                                OrderBy_AlphabeticalDescending
                            ],
                            value: OrderBy_Newest
                        }}
                        pagination={{
                            rowsPerPage: rowsPerPage
                        }}
                        onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                            return getPatients(_searchBy, _start, _end, _orderBy, _page)
                        }}
                        renderItem={(item) => (
                            <BoxPatient
                                patient={item}
                                history={history}
                                actions={(
                                    <>
                                        <div className="col-sm-auto col-12 order-3 order-sm-0 mt-3 mt-sm-0">
                                            <button className="vsbox-btn vsbox-btn-primary w-100" onClick={() => history.push(`/animal/${item.id}`)}>
                                                Ver Cadastro Completo
                                            </button>
                                        </div>
                                        { permissions?.check(ACL.PATIENT, ACL.Rule.EDIT, item) && (
                                            <div className="col-sm-auto col-6 ps-2 ps-sm-0 order-2 order-sm-0">
                                                <button className="vsbox-btn vsbox-btn-dark w-100" onClick={() => history.push(`/tutor/${item.get("owner").id}/animal/${item.id}`)}>
                                                    <IconPrimaryEdit width="24" height="24" />
                                                </button>
                                            </div>
                                        )}
                                        { permissions?.check(ACL.PATIENT, ACL.Rule.DELETE, item) && (
                                            <div className="col-sm-auto col-6 ps-sm-0 order-1 order-sm-0 pe-1 pe-sm-3">
                                                <button className="vsbox-btn vsbox-btn-alert w-100" onClick={() => deletePatient(item, () => addEvent("Patient__getList", {}))}>
                                                    <IconPrimaryTrash width="24" height="24" />
                                                </button>
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        )}
                        renderEmpty={() => (
                            <VSEmpty
                                icon={<IconPrimaryPaw width="32px" height="32px" />}
                                title="Você ainda não possui nenhum animal cadastrado no Prontuário"
                                text="Cadastre o primeiro animal e comece a aproveitar todos os benefícios de gestão de atendimentos do Prontuário Vetsmart"
                            >
                                { permissions?.check(ACL.PATIENT, ACL.Rule.EDIT) && (
                                    <button
                                        id="new-patient-link"
                                        className="vsbox-btn vsbox-btn-primary"
                                        onClick={() => history.push(`/tutor/selecionar/animal/novo`)}
                                    >
                                        <IconAdd width="14" height="14" className="me-2" />
                                        Cadastrar novo animal
                                    </button>
                                )}
                            </VSEmpty>
                        )}
                        renderNotFound={() => (
                            <VSEmpty
                                icon={<IconPrimaryPaw width="32px" height="32px" />}
                                title="Não encontramos nenhum resultado para a sua busca"
                                text="Tente refazer sua busca utilizando mais caracteres ou crie um novo cadastro utilizando o botão abaixo"
                            >
                                { permissions?.check(ACL.PATIENT, ACL.Rule.EDIT) && (
                                    <button
                                        id="new-patient-link"
                                        className="vsbox-btn vsbox-btn-primary"
                                        onClick={() => history.push(`/tutor/selecionar/animal/novo`)}
                                    >
                                        <IconAdd width="14" height="14" className="me-2" />
                                        Cadastrar novo animal
                                    </button>
                                )}
                            </VSEmpty>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(Patient);