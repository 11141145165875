import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"

import { ReactComponent as IconAddO } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconAdd } from "../../assets/icon-add.svg"
import { ReactComponent as IconMinusO } from "../../assets/icon-minus-o.svg"
import { ReactComponent as IconPaw } from "../../assets/icon-paw.svg"
import { ReactComponent as IconQuestionO } from "../../assets/icon-question-o.svg"

import { diacriticSensitive } from "../../utils"
import { ACL, OrderBy_Alphabetical, OrderBy_AlphabeticalDescending, OrderBy_Oldest, SHOW_NEW_SUPPLY } from "../../constants"
import { searchSuppliesByCompany, searchSuppliesByDistributor } from './SupplyTransaction';
import { deleteCategory, searchCategories } from "../service/Service"

import VSList from "../../components/vsList/VSList"
import VSEmpty from "../../components/vsEmpty/VSEmpty"
import CardFeedback from "../../components/cardFeedback/CardFeedback"
import SupplyView from "./SupplyView"
import CategoryForm from "../service/CategoryForm"
import SupplyViewOld from "./SupplyViewOld"

import "./SupplyList.scss"
const SupplyList = (props) => {

    const {
        clinic,
        history,
        permissions
    } = props

    const rowsPerPage = 5

    const filter = useRef(null)

    const [ items, setItems ] = useState([])
    const [ categories, setCategories ] = useState([])
    const [ companyNames, setCompanyNames ] = useState([])
    const [ distributors, setDistributors ] = useState([])
    const [ stock, setStock ] = useState("0")
    const [ active, setActive ] = useState("0")
    const [ reminder, setReminder ] = useState("0")

    const getSupplies = (searchBy, dateFrom, dateTo, orderBy, page) => {
        return new Promise((resolve, reject) => {
            let query
            if (searchBy) {
                const queryByName = new Parse.Query("VW_MRSupplyTransaction_stock")
                queryByName.equalTo("clinic", clinic.object)
                queryByName.matches("supplyName", diacriticSensitive(searchBy), "im")

                const queryById = new Parse.Query("VW_MRSupplyTransaction_stock")
                queryById.equalTo("clinic", clinic.object)
                queryById.matches("supplyId", diacriticSensitive(searchBy), "im")

                query = Parse.Query.or(
                    queryByName,
                    queryById
                )
            } else {
                query = new Parse.Query("VW_MRSupplyTransaction_stock")
            }
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            if (categories.length > 0) {
                query.containedIn("category", categories.map(({ value }) => value))
            }
            if (companyNames && companyNames.length > 0){
                query.containedIn("companyName", companyNames.map(companyName => companyName.value));
            }
            if (distributors && distributors.length > 0){
                query.containedIn("distributor", distributors.map(distributor => distributor.value));
            }
            if (active != "0") {
                query.equalTo("active", active == "1")
            }
            if(reminder != "0"){
                query.equalTo("reminder", reminder == "1")
            }
            if (stock == "1") {
                query.equalTo("totalIsBelowMin", true)
            }
            switch (orderBy) {
                case OrderBy_Alphabetical:
                    query.ascending("supplyName");
                    break;
                case OrderBy_AlphabeticalDescending:
                    query.descending("supplyName");
                    break;
                case OrderBy_Oldest:
                    query.ascending("createdAt");
                    break;
                default:
                    query.descending("createdAt");
            }
            query.addAscending("objectId")
            query.withCount()
            query.include("supply")
            query.include("supply.category")
            query.include("supply.changedBy")
            query.include("supply.createdBy")
            query.skip((page - 1) * rowsPerPage)
            query.limit(rowsPerPage)
            query.find()
            .then(_supplies => {
                setItems(_supplies?.results)
                resolve({
                    items: _supplies?.results,
                    total: _supplies?.count
                })
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.SUPPLY, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        document.addEventListener("Supply__getList", onChange)
        return () => { 
            document.removeEventListener("Supply__getList", onChange)
        }
    }, [])

    return (
        <>
            <div id="supply-list" className="row flex-grow-1">
                <div className="col-12">
                    <div className="page-content">
                        <VSList
                            ref={filter}
                            title={{
                                text: "Insumos"
                            }}
                            search={{
                                text: "Buscar por ID ou nome do insumo"
                            }}
                            VSFilterProps={{
                                title: "Filtro de Insumos",
                                onClear: () => {
                                    setCategories([])
                                    setCompanyNames([])
                                    setDistributors([])
                                    setStock("0")
                                    setActive("1")
                                    setReminder("0")
                                },
                                onFilter: () => {
                                    onChange()
                                },
                                filters: [
                                    {
                                        title: "Categoria",
                                        defaultValue: [],
                                        value: categories,
                                        onChange: (values) => {
                                            setCategories(values)
                                        },
                                        onSearch: (term) => {
                                            return new Promise((resolve, reject) => {
                                                searchCategories(term, clinic.object)
                                                .then(({ results, count }) => {
                                                    if (results) {
                                                        const options = results.map(category => ({
                                                            value: category,
                                                            label: category.get("categoryName")
                                                        }))
                                                        return resolve({
                                                            count: count,
                                                            options: options
                                                        })
                                                    }
                                                    return resolve([])
                                                })
                                                .catch(error => {
                                                    console.error(error)
                                                    return resolve([])
                                                })
                                            })
                                        },
                                        options: [],
                                        multiple: {
                                            title: "Categorias",
                                            button: "Selecione uma categoria",
                                            placeholder: "Procure pela categoria",
                                            canCreate: () => permissions?.check(ACL.SERVICE, ACL.Rule.EDIT),
                                            canEdit: (object) => object.get("clinic"),
                                            canDelete: (object) => object.get("clinic"),
                                            onDelete: (object, callback) => deleteCategory(object, callback),
                                            FormComponent: CategoryForm
                                        }
                                    },
                                    {
                                        title: "Fabricante",
                                        defaultValue: [],
                                        value: companyNames,
                                        onChange: (values) => {
                                            setCompanyNames(values)
                                        },
                                        onSearch: (term) => {
                                            return new Promise((resolve, reject) => {
                                                searchSuppliesByCompany(term, clinic.object)
                                                .then(({ results, count }) => {
                                                    if (results) {
                                                        const options = results.map(supply => ({
                                                            value: supply.get("companyName"),
                                                            label: supply.get("companyName")
                                                        }))
                                                        return resolve({
                                                            count: count,
                                                            options: options
                                                        })
                                                    }
                                                    return resolve([])
                                                })
                                                .catch(error => {
                                                    console.error(error)
                                                    return resolve([])
                                                })
                                            })
                                        },
                                        options: [],
                                        multiple: {
                                            title: "Fabricante",
                                            button: "Selecione um fabricante",
                                            placeholder: "Procure pelo fabricante"
                                        }
                                    },
                                    {
                                        title: "Fornecedor",
                                        defaultValue: [],
                                        value: distributors,
                                        onChange: (values) => {
                                            setDistributors(values)
                                        },
                                        onSearch: (term) => {
                                            return new Promise((resolve, reject) => {
                                                searchSuppliesByDistributor(term, clinic.object)
                                                .then(({ results, count }) => {
                                                    if (results) {
                                                        const options = results.map(supply => ({
                                                            value: supply.get("distributor"),
                                                            label: supply.get("distributor")
                                                        }))
                                                        return resolve({
                                                            count: count,
                                                            options: options
                                                        })
                                                    }
                                                    return resolve([])
                                                })
                                                .catch(error => {
                                                    console.error(error)
                                                    return resolve([])
                                                })
                                            })
                                        },
                                        options: [],
                                        multiple: {
                                            title: "Fornecedor",
                                            button: "Selecione um fornecedor",
                                            placeholder: "Procure pelo fornecedor"
                                        }
                                    },
                                    {
                                        title: "Estoque",
                                        horizontal: true,
                                        defaultValue: "0",
                                        value: stock,
                                        onChange: (value) => {
                                            setStock(value)
                                        },
                                        options: [
                                            { value: "0", label: "Todos"},
                                            { value: "1", label: "Em falta"},
                                        ]
                                    },
                                    {
                                        title: "Estado do insumo",
                                        horizontal: true,
                                        defaultValue: "0",
                                        value: active,
                                        onChange: (value) => {
                                            setActive(value)
                                        },
                                        options: [
                                            { value: "0", label: "Todos"},
                                            { value: "1", label: "Ativo"},
                                            { value: "2", label: "Inativo"}
                                        ]
                                    },
                                    {
                                        title: "Lembrete de reposição",
                                        horizontal: true,
                                        defaultValue: "0",
                                        value: reminder,
                                        onChange: (value) => {
                                            setReminder(value)
                                        },
                                        options: [
                                            { value: "0", label: "Todos"},
                                            { value: "1", label: "Ativo"},
                                            { value: "2", label: "Desativado"}
                                        ]
                                    }
                                ]
                            }}
                            pagination={{
                                rowsPerPage: rowsPerPage
                            }}
                            onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                                return getSupplies(_searchBy, _start, _end, _orderBy, _page)
                            }}
                            customFilter={
                                <div className="row gx-3">
                                    { permissions?.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.EDIT) && (
                                        <>
                                            <div className="col-12 col-sm">
                                                <button
                                                    id="new-supply-entry"
                                                    className="btn-outline-submit btn-square vstext-ellipsis w-100 justify-content-center"
                                                    onClick={() => history.push(`/insumo/movimentacao/entrada`)}
                                                >
                                                    <IconAdd id="add-icon" width="14" height="14" fill="var(--vsprontuario-primary-color)" />
                                                    Entrada de insumo
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm mt-3 mt-sm-0">
                                                <button
                                                    id="new-supply-exit"
                                                    className="btn-outline-submit btn-square vstext-ellipsis w-100 justify-content-center"
                                                    onClick={() => history.push(`/insumo/movimentacao/saida`)}
                                                >
                                                    <IconMinusO id="add-icon" width="14" height="14" fill="var(--vsprontuario-primary-color)" />
                                                    Saída de insumo
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    { permissions?.check(ACL.SUPPLY, ACL.Rule.EDIT) && (
                                        <div className="col-12 col-md-auto">
                                            <button
                                                id="new-supply-link"
                                                className="btn-main-mobile btn-submit btn-square w-100"
                                                onClick={() => history.push(`/insumo/novo`)}
                                            >
                                                <IconAddO id="add-icon" width="14" height="14" fill="#ffffff" />
                                                Criar insumo
                                            </button>
                                        </div>
                                    )}
                                </div>
                            }
                            renderItem={(item, expanded) => SHOW_NEW_SUPPLY ? (
                                <SupplyView object={item.get("supply")} stock={item} expanded={expanded} {...props} />
                            ) : (
                                <SupplyViewOld object={item.get("supply")} total={item.get("total")} expanded={expanded} {...props} />
                            )}
                            renderEmpty={() => (
                                <VSEmpty
                                    icon={<IconPaw width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />}
                                    title="Nenhum insumo criado"
                                    text="Todas os insumos aparecerão aqui. Tenha o controle do seu estoque cadastrando seus insumos utilizados em seus atendimentos."
                                >
                                    { permissions?.check(ACL.SUPPLY, ACL.Rule.EDIT) && (
                                        <button
                                            className="vsbox-btn vsbox-btn-primary w-auto mb-4"
                                            onClick={() => history.push(`/insumo/novo`)}
                                        >
                                            <IconAddO width="14" height="14" className="me-2" />
                                            Cadastrar Novo Insumo
                                        </button>
                                    )}
                                    <div className="row g-3">
                                        <div className="col-12 vspro-info">
                                            <div className="row g-3 justify-content-start justify-content-md-center">
                                                <div className="col-auto pe-0">
                                                    <IconQuestionO width={24} height={24} fill="var(--vsprontuario-primary-color)" />
                                                </div>
                                                <div className="col col-md-auto ps-2 text-start action-text">
                                                    Ficou com dúvidas? Saiba como funciona a parte de <a href="#" target="_blank">Insumos</a>.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </VSEmpty>
                            )}
                            renderNotFound={() => (
                                <VSEmpty
                                    icon={<IconPaw width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />}
                                    title="Não encontramos nenhum insumo"
                                    text="Tente refazer sua busca utilizando mais caracteres ou crie um novo cadastro utilizando o botão abaixo"
                                >
                                    { permissions?.check(ACL.SUPPLY, ACL.Rule.EDIT) && (
                                        <button
                                            className="vsbox-btn vsbox-btn-primary"
                                            onClick={() => history.push(`/insumo/novo`)}
                                        >
                                            <IconAddO width="14" height="14" className="me-2" />
                                            Cadastrar Novo Insumo
                                        </button>
                                    )}
                                </VSEmpty>
                            )}
                        />
                    </div>
                </div>
            </div>
            {items && items.length > 0 && (
                <CardFeedback contentDetail={history.location?.pathname || "SupplyList"} />
            )}
        </>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(SupplyList)