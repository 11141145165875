import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import { CircularProgress } from '@mui/material';
import TextFieldMask from '../../components/textFieldMask/TextFieldMask';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Autocomplete } from "@mui/lab";

import { ReactComponent as IconWarning } from "../../assets/icon-warning.svg"

import {
    APP_NAME,
    APP_DEVICE,
    APP_VERSION,
    ACL
} from '../../constants'
import { 
    getStateList,
    validateCPF,
    validateEmail,
    validatePhone,
    validatePostalCode,
    addEvent,
    saveOnTracker,
    useQuery,
    getAddressByZipCode,
    validateDate
} from '../../utils'
import { getOwner } from './Owner';

import VSDrawer from '../../components/vsDrawer/VSDrawer';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import VSError from '../../components/vsError/VSError';
import { VSSimpleTooltip } from '../../components/vsTooltip/VSTooltip';

import { getPetloveSaudePlan } from '../health/Health';
import HealthAlert from '../health/HealthAlert';
import DateInput from '../../components/dateInput/DateInput';
import dayjs from 'dayjs';
import { setHealthImport } from '../../store/actions';

var currentZipCode = null

import './OwnerForm.scss'
const OwnerForm = (props) => {

    const { id } = useParams()

    const {
        clinic,
        history,
        onboarding,
        permissions
    } = props

    const {
        state
    } = useLocation()

    const proxima = useQuery().get("proxima")
    const preFilledName = useQuery().get("name")

    const update = id !== "novo"

    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ owner, setOwner ] = useState(null)

    const [ name, setName ] = useState(preFilledName || '')
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ cpf, setCpf ] = useState('')
    const [ errorCpf, setErrorCpf ] = useState(false)
    const [ helperCpf, setHelperCpf ] = useState("Campo opcional")

    const [ cpfs, setCpfs ] = useState({})
    const [ cpfsLoading, setCpfsLoading ] = useState(false)

    const [ email, setEmail ] = useState('')
    const [ errorEmail, setErrorEmail ] = useState(false)
    const [ helperEmail, setHelperEmail ] = useState("Campo opcional")

    const [ landlinePhone, setLandlinePhone ] = useState('')
    const [ errorLandlinePhone, setErrorLandlinePhone ] = useState(false)
    const [ helperLandlinePhone, setHelperLandlinePhone ] = useState("Campo opcional")

    const [ phone, setPhone ] = useState('')
    const [ errorPhone, setErrorPhone ] = useState(false)
    const [ helperPhone, setHelperPhone ] = useState("Campo opcional")

    const [ zipCode, setZipCode ] = useState('')
    const [ loadingZipCode, setLoadingZipCode ] = useState(false)
    const [ errorZipCode, setErrorZipCode ] = useState(false)
    const [ helperZipCode, setHelperZipCode ] = useState("Campo opcional")

    const [ birthdate, setBirthdate ] = useState(null)
    const [ errorBirthdate, setErrorBirthdate ] = useState(false)
    const [ helperBirthdate, setHelperBirthdate ] = useState("Campo opcional")
    const [ birthdateText, setBirthdateText ] = useState('')

    const [ rg, setRg ] = useState('')
    const [ internationalId, setInternationalId ] = useState('')
    const [ address, setAddress ] = useState('')
    const [ addressNumber, setAddressNumber ] = useState('')
    const [ addressComplement, setAddressComplement ] = useState('')
    const [ neighborhood, setNeighborhood ] = useState('')
    const [ city, setCity ] = useState('')
    const [ cityId, setCityId ] = useState('')
    const [ stateId, setStateId ] = useState('')
    const [ notes, setNotes ] = useState('')
    const [ referredBy, setReferredBy ] = useState('')

    const [ health, setHealth ] = useState(null)

    const handleReferredBy = (selected) => {
        const referredBy = selected
        setReferredBy(referredBy)
    }

    const validateOnTouch = () => {
        if (name || cpf || rg || email || phone || zipCode || address || addressNumber || addressComplement || neighborhood || city || stateId || birthdateText) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }
        if (saveLoading) {
            isValid = false
        }
        if (error?.message) {
            isValid = false
        }

        if (!name) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome do tutor é obrigatório")
        } else if (name.length < 3) {
            isValid = false
            setErrorName(true)
            setHelperName("Por favor, preencha o nome completo do tutor")
        } else {
            setErrorName(false)
            setHelperName(<span>Campo <b>Obrigatório</b></span>)
        }

        let unmaskedCpf = cpf && cpf.replace(/[^0-9]/g, '')
        if (!unmaskedCpf || validateCPF(unmaskedCpf)) {
            setErrorCpf(false)
            setHelperCpf("Campo Opcional")
        } else {
            isValid = false
            setErrorCpf(true)
            setHelperCpf("O CPF informado não é válido")
        }

        if (!email || validateEmail(email)) {
            setErrorEmail(false)
            setHelperEmail("Campo Opcional")
        } else {
            isValid = false
            setErrorEmail(true)
            setHelperEmail("O e-mail informado não é válido")
        }

        let unmaskedLandlinePhone = landlinePhone && landlinePhone.replace(/[^0-9]/g, '')
        if (!unmaskedLandlinePhone || validatePhone(landlinePhone)) {
            setErrorPhone(false)
            setHelperPhone("Campo Opcional")
        } else {
            isValid = false
            setErrorPhone(true)
            setHelperPhone("O número de telefone informado não é válido")
        }

        let unmaskedPhone = phone && phone.replace(/[^0-9]/g, '')
        if (!unmaskedPhone || validatePhone(phone)) {
            setErrorPhone(false)
            setHelperPhone("Campo Opcional")
        } else {
            isValid = false
            setErrorPhone(true)
            setHelperPhone("O número de celular informado não é válido")
        }

        let unmaskedZipCode = zipCode && zipCode.replace(/[^0-9]/g, '')
        if (!unmaskedZipCode || validatePostalCode(zipCode)) {
            setErrorZipCode(false)
            setHelperZipCode("Campo Opcional")
        } else {
            isValid = false
            setErrorZipCode(true)
            setHelperZipCode("O CEP informado não é válido")
        }

        if (birthdateText) {
            if (validateDate(birthdateText)) {
                setErrorBirthdate(false)
                setHelperBirthdate("Campo Opcional")
            } else {
                isValid = false
                setErrorBirthdate(true)
                setHelperBirthdate("A data de nascimento não é válida")
            }
        }

        return isValid
    }

    const isValid = () => !loading && !saveLoading && !errorName && !errorCpf && !errorEmail && !errorPhone && !errorZipCode && !errorBirthdate

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Estamos procurando o tutor")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorName) {
            errors.push("Nome")
        }
        if (errorCpf) {
            errors.push("CPF")
        }
        if (errorEmail) {
            errors.push("E-mail")
        }
        if (errorLandlinePhone) {
            errors.push("Telefone")
        }
        if (errorPhone) {
            errors.push("Celular")
        }
        if (errorZipCode) {
            errors.push("CEP")
        }
        if (errorBirthdate) {
            errors.push("Data de nascimento")
        }
        if (!permissions?.check(ACL.OWNER, ACL.Rule.EDIT, owner)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            if (validate()) {
                setSaveLoading(true)
                Parse.Cloud.run("saveOwnerForClinic", {
                    data: {
                        objectId: update ? id : null,
                        name: name,
                        email: email,
                        cpf: cpf,
                        rg: rg,
                        zipCode: zipCode,
                        addressStreet: address,
                        addressNumber: addressNumber,
                        addressComplement: addressComplement,
                        neighborhood: neighborhood,
                        city: city,
                        landlinePhone: landlinePhone,
                        phone: phone,
                        cityId: cityId,
                        stateId: stateId,
                        notes: notes,
                        referredBy: referredBy,
                        internationalId: internationalId,
                        birthdate: birthdate
                    },
                    clinicId: clinic.id || clinic.objectId,
                    appVersionName: APP_VERSION,
                    deviceType: APP_DEVICE,
                    appContentType: APP_NAME
                })
                .then(_owner => {
                    saveOnTracker("Concluiu", "Tutor", _owner.id)
                    setSaveLoading(false)
                    addEvent("TourOwner__registerYourFirstOwner")
                    addEvent("Owner__getList", {})
                    resolve(_owner)
                })
                .catch(error => {
                    setSaveLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar ${update ? "editar" : "cadastrar"} o tutor`,
                        'error'
                    )
                    reject()
                })
            } else {
                reject()
            }
        })
    }

    const getOwnerOrError = () => {
        if (permissions?.check(ACL.OWNER, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", "Tutor", id !== "novo" ? id : "")
            if (update) {
                setLoading(true)
                setError(null)
    
                getOwner(id, clinic.object)
                .then(_owner => {
                    currentZipCode = _owner.get("zipCode")
                    setOwner(_owner)
                    setName(_owner.get("name"))
                    setEmail(_owner.get("email"))
                    setCpf(_owner.get("cpf"))
                    setRg(_owner.get("rg"))
                    setZipCode(_owner.get("zipCode"))
                    setAddress(_owner.get("addressStreet"))
                    setAddressNumber(_owner.get("addressNumber"))
                    setAddressComplement(_owner.get("addressComplement"))
                    setNeighborhood(_owner.get("neighborhood"))
                    setCity(_owner.get("city"))
                    setLandlinePhone(_owner.get("landlinePhone"))
                    setPhone(_owner.get("phone"))
                    setCityId(_owner.get("cityId"))
                    setStateId(_owner.get("stateId"))
                    setNotes(_owner.get("notes"))
                    setReferredBy(_owner.get("referredBy"))
                    setInternationalId(_owner.get("internationalId"))
                    setBirthdateText(_owner.get("birthdate") ? dayjs(_owner.get("birthdate")).format("DD/MM/YYYY") : null)
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getOwnerOrError"
                        })
                    })
                })
            } else {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        let preventMultipleRequests
        if (cpf && cpfs[cpf] == undefined) {
            setCpfsLoading(true)
            preventMultipleRequests = setTimeout(() => {
                const query = new Parse.Query("Owner")
                query.equalTo("clinic", clinic.object)
                if (owner?.id) {
                    query.notEqualTo("objectId", owner.id)
                }
                query.equalTo("cpf", cpf)
                query.equalTo("isDeleted", false)
                query.find()
                .then(result => {
                    const newCpfs = {...cpfs}
                    newCpfs[cpf] = result.length > 0 ? result : null
                    setCpfs(newCpfs)
                    setCpfsLoading(false)
                })
                .catch(error => {
                    console.error(error)
                    setCpfsLoading(false)
                })
            }, 300)
        }
        return () => {
            clearTimeout(preventMultipleRequests)
        }
    }, [ cpf ])

    useEffect(() => {
        let preventMultipleRequests
        if (cpf && clinic.health?.available) {
            preventMultipleRequests = setTimeout(async () => {
                const _health = await getPetloveSaudePlan(null, cpf)
                setHealth(_health)
            }, 300)
        }
        return () => {
            clearTimeout(preventMultipleRequests)
        }
    }, [ cpf ])

    useEffect(() => {
        validateOnTouch()
    }, [ name, cpf, email, phone, zipCode, birthdateText ])

    useEffect(() => {
        if(currentZipCode != zipCode) {
            setLoadingZipCode(true)
            getAddressByZipCode(zipCode)
            .then(data => {
                currentZipCode = zipCode
                setLoadingZipCode(false)
                setAddress(data.address)
                setNeighborhood(data.neighborhood)
                setCity(data.city)
                setCityId(data.city_id)
                setStateId(data.state_id)
            })
            .catch(_ => {
                setLoadingZipCode(false)
            })
        }
    }, [ zipCode ])

    useEffect(() => {
        if (owner && !permissions?.check(ACL.OWNER, ACL.Rule.EDIT, owner)) {
            permissions?.error()
        }
    }, [ owner ])

    useEffect(() => {
        if (clinic && clinic.isLoaded) {
            getOwnerOrError()
        }
    }, [ clinic ])

    const errors = fieldsWithErrorText()

    const usage = cpfs[cpf]

    return (
        <VSDrawer
            title={update ? "Editar Cadastro de Tutor(a)" : "Novo Cadastro de Tutor(a)"}
            cancel={update ? "Cancelar Alterações" : <span>Cancelar <span className="d-none d-md-inline">Novo</span> Cadastro</span>}
            submit={isValid ? ( update ? "Salvar Alterações" : "Salvar Cadastro" ) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => {
                setOwner(object)
                if (proxima) {
                    history.replace(`/tutor/${ object.id }/animal/novo?proxima=${proxima}`, {
                        ...state
                    })
                }
            }}
            VSDrawerSuccessProps={{
                title: update ? "Alterações salvas com sucesso!" : "Cadastro realizado com sucesso!" ,
                text: proxima ? "Você será redirecionado(a) automaticamente para o Cadastro de um novo animal para este(a) Tutor(a)" : "Escolha se deseja Ir para o Perfil do(a) Tutor(a) ou Cadastrar um novo animal para este(a) Tutor(a)",
                VSDrawerFooterProps: {
                    children: owner && !proxima && (
                        <div className="row">
                            <div className="col pe-2">
                                <button
                                    className="btn btn-success w-100"
                                    onClick={() => history.replace(`/tutor/${ owner.id }`)}
                                >
                                    Ir para o Perfil do(a) Tutor(a)
                                </button>
                            </div>
                            <div className="col ps-2">
                                <button
                                    className="btn btn-save w-100"
                                    onClick={() => history.replace(`/tutor/${ owner.id }/animal/novo`)}
                                >
                                    Cadastrar um novo animal para este(a) Tutor(a)
                                </button>
                            </div>
                        </div>
                    )
                }
            }}
            VSDrawerCancelProps={{
                title: update ? "Tem certeza que deseja cancelar as alterações no cadastro de tutor(a)?" : "Tem certeza que deseja cancelar o novo cadastro de tutor(a)?",
                confirm: update ? "Cancelar Alterações" : "Cancelar Novo Cadastro",
                cancel: update ? "Voltar para Edição do Cadastro" : "Voltar para Edição do Novo Cadastro"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getOwnerOrError":
                                getOwnerOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : clinic && clinic.object && (
                <>
                    <div className="row">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Identificação"
                                )}
                            >
                                <div className="row">
                                    <div className="col-12 col-md-9 order-md-1 mb-3">
                                        <TextField
                                            placeholder="Nome Completo"
                                            label="Nome do tutor"
                                            value={name}
                                            onChange={({ target: { value } }) => setName(value)}
                                            error={errorName}
                                            helperText={helperName}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-6 col-md-3 order-md-2 mb-3">
                                        <TextFieldMask
                                            type="tel"
                                            mask='000.000.000-00'
                                            value={cpf}
                                            onAccept={(value) => setCpf(value)}
                                            placeholder="000.000.000-00"
                                            label="CPF"
                                            error={errorCpf}
                                            helperText={(
                                                <>
                                                { cpfsLoading ? (
                                                    <CircularProgress size={14} className="me-1" />
                                                ) : usage && (
                                                        <VSSimpleTooltip
                                                            title={
                                                                <div className="p-3">
                                                                    <p className="mb-1">O CPF está em uso no{usage.length == 1 ? "" : "s"} tutor{usage.length == 1 ? "" : "es"} abaixo:</p>
                                                                    <table className="table table-borderless mb-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>CPF</th>
                                                                                <th>Nome</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            { usage.map((owner, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{ owner.get("cpf") }</td>
                                                                                    <td>{ owner.get("name") }</td>
                                                                                </tr>
                                                                            )) }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            }
                                                        >
                                                            <IconWarning width={16} height={16} className="me-1" />
                                                        </VSSimpleTooltip>
                                                    )}
                                                    { helperCpf }
                                                </>
                                            )}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-6 col-md-4 order-md-4 mb-3">
                                        <TextField
                                            placeholder="00000000-0"
                                            label="RG"
                                            value={rg}
                                            onChange={({ target: { value } }) => setRg(value)}
                                            helperText={<span>Campo Opcional</span>}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    { health && !owner?.get("petloveSaudeId") && (
                                        <div className="col-12 order-md-3 mb-3">
                                            <HealthAlert
                                                type="info"
                                                onClick={() => {
                                                    const healthImport = {
                                                        petlove: health,
                                                        vetsmart: owner
                                                    }
                                                    props.dispatch(setHealthImport(healthImport))
                                                    history.push(`/petlove-saude`)
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div className="col-6 col-md-4 order-md-5 mb-3">
                                        <TextField
                                            placeholder="Ex: AAA-GG-SSSS"
                                            label="Identidade Estrangeira"
                                            value={internationalId}
                                            onChange={({ target: { value } }) => setInternationalId(value)}
                                            helperText={<span>Campo Opcional</span>}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-6 col-md-4 order-md-6 mb-3">
                                        <DateInput
                                            className="my-0"
                                            placeholder="00/00/0000"
                                            label="Data de nascimento"
                                            value={birthdateText || null}
                                            error={errorBirthdate}
                                            helperText={helperBirthdate}
                                            onChange={(value) => {
                                                setBirthdateText(value)
                                                setBirthdate(validateDate(value))
                                            }}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Dados de Contato"
                                )}
                            >
                                <div className="row">
                                    <div className="col-6 col-lg-3 mb-3">
                                        <TextFieldMask
                                            type="tel"
                                            mask={'(00) 0000-0000'}
                                            value={landlinePhone}
                                            onAccept={(value) => {
                                                setLandlinePhone(value)
                                            }}
                                            placeholder="(00) 0000-0000"
                                            label="Telefone"
                                            error={errorLandlinePhone}
                                            helperText={helperLandlinePhone}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-6 col-lg-3 mb-3">
                                        <TextFieldMask
                                            type="tel"
                                            mask={phone?.length <= 14 ? '(00) 0000-0000[0]' : '(00) 00000-0000'}
                                            value={phone}
                                            onAccept={(value) => setPhone(value)}
                                            placeholder="(00) 00000-0000"
                                            label="Celular"
                                            error={errorPhone}
                                            helperText={helperPhone}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <TextField
                                            type="email"
                                            placeholder="exemplo@email.com"
                                            label="E-mail"
                                            value={email}
                                            onChange={({ target: { value } }) => setEmail(value)}
                                            error={errorEmail}
                                            helperText={helperEmail}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Endereço"
                                )}
                            >
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-2 mb-3">
                                        <TextFieldMask
                                            type="tel"
                                            mask="00000-000"
                                            value={zipCode}
                                            onAccept={(value) => setZipCode(value)}
                                            placeholder="00000-000"
                                            label="CEP"
                                            error={errorZipCode}
                                            helperText={helperZipCode}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        { loadingZipCode && (
                                                            <CircularProgress size={16} />
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 col-md-8 mb-3">
                                        <TextField
                                            placeholder="Rua, avenida, alameda, etc."
                                            label="Endereço"
                                            value={address}
                                            onChange={({ target: { value } }) => setAddress(value)}
                                            helperText={<span>Campo Opcional</span>}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-2 mb-3">
                                        <TextField
                                            placeholder="000"
                                            label="Número"
                                            value={addressNumber}
                                            onChange={({ target: { value } }) => setAddressNumber(value)}
                                            helperText={<span>Campo Opcional</span>}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-6 col-md-8 col-lg-3 mb-3">
                                        <TextField
                                            placeholder="Torre Oceania, Apartamento 12"
                                            label="Complemento"
                                            value={addressComplement}
                                            onChange={({ target: { value } }) => setAddressComplement(value)}
                                            helperText={<span>Campo Opcional</span>}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-2 mb-3">
                                        <TextField
                                            placeholder="São Paulo"
                                            label="Estado"
                                            value={stateId}
                                            onChange={({ target: { value } }) => setStateId(value)}
                                            helperText={<span>Campo Opcional</span>}
                                            select
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        >
                                            { getStateList().map(stateItem => (
                                                <MenuItem key={stateItem.value} value={stateItem.value}>
                                                    { stateItem.text }
                                                </MenuItem>
                                            )) }
                                        </TextField>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 mb-3">
                                        <TextField
                                            placeholder="São Paulo"
                                            label="Cidade"
                                            value={city}
                                            onChange={({ target: { value } }) => setCity(value)}
                                            helperText={<span>Campo Opcional</span>}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 mb-3">
                                        <TextField
                                            placeholder="Jardim das Flores"
                                            label="Bairro"
                                            value={neighborhood}
                                            onChange={({ target: { value } }) => setNeighborhood(value)}
                                            helperText={<span>Campo Opcional</span>}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Observações Internas"
                                )}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <TextField
                                            className="vsmui-disable-ellipsis"
                                            placeholder="Anotações particulares (temperamento, característica, etc.)"
                                            label="Digite as observações sobre o(a) Tutor(a)"
                                            value={notes}
                                            onChange={({ target: { value } }) => setNotes(value)}
                                            helperText={<span>Campo Opcional<br/></span>}
                                            size="small"
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col input-info">
                                        As observações são anotações particulares. <b>Elas nunca serão compartilhadas com o tutor!</b>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-6 col-md-4">
                                        <Autocomplete
                                            inputValue={referredBy}
                                            options={["Internet", "Redes sociais", "Indicação", "Fachada da loja", "Panfleto"]}
                                            onChange={(_, selected) => handleReferredBy(selected || "")}
                                            onInputChange={(event, selected) => {
                                                if (event){
                                                    handleReferredBy(selected)
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Digite..."
                                                    label="Onde nos conheceu?"
                                                    helperText={<span>Campo Opcional<br/></span>}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            openOnFocus
                                            freeSolo
                                        />
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        onboarding: state.onboarding,
        permissions: state.permissions,
    };
};
  
export default connect(mapStateToProps)(OwnerForm);