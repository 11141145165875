import React, { useState } from "react"
import { connect } from "react-redux"
import { dayjs, imageUrlToParseFile, showSnackbar } from "../../utils"

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconCheck } from "../../assets/icon-primary-check.svg"
import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"

import VSPage from "../../components/vsPage/VSPage"
import HealthPatientsCard from "./HealthPatientsCard"
import PatientPhoto from "../../components/patientPhoto/PatientPhoto"
import VSError from "../../components/vsError/VSError"
import { getAge } from "../patient/Patient"
import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants"
import { resetHealthImport, setHealthImportOwner, setHealthImportPatient } from "../../store/actions"
import HealthCancel from "./HealthCancel"

import "./HealthReview.scss"
const HealthReview = (props) => {

    const {
        clinic,
        health,
        history
    } = props

    const {
        owner,
        patients
    } = health

    const [ cancel, setCancel ] = useState(false)

    const [ loading, setLoading ] = useState(false)
    const [ loadingMessage, setLoadingMessage ] = useState("")
    const [ error, setError ] = useState()

    const saveOwner = async () => {
        return new Promise((resolve, reject) => {
            const data = {
                data: {
                    objectId: owner?.id,
                    name: owner?.name,
                    birthdate: owner?.birthdate,
                    cpf: owner?.cpf,
                    landlinePhone: owner?.landlinePhone,
                    phone: owner?.phone,
                    email: owner?.email,
                    rg: owner?.rg,
                    internationalId: owner?.internationalId,
                    zipCode: owner?.zipCode,
                    addressStreet: owner?.addressStreet,
                    addressNumber: owner?.addressNumber,
                    addressComplement: owner?.addressComplement,
                    neighborhood: owner?.neighborhood,
                    city: owner?.city,
                    stateId: owner?.stateId,
                    notes: owner?.notes,
                    referredBy: owner?.referredBy,
                    petloveSaudeId: owner?.petloveSaudeId,
                    petloveSaudeData: owner?.petloveSaudeData
                },
                clinicId: clinic.objectId,
                appVersionName: APP_VERSION,
                deviceType: APP_DEVICE,
                appContentType: APP_NAME
            }
            Parse.Cloud.run("saveOwnerForClinic", data)
            .then(_owner => {
                return resolve(_owner)
            })
            .catch(error => {
                console.error(error)
                return reject(error?.message || "Não foi possível cadastrar o(a) tutor(a)")
            })
        })
    }

    const savePatient = async (patient, owner) => {
        return new Promise(async (resolve, reject) => {
            if (patient?.image && typeof patient.image == "string") {
                patient.image = await imageUrlToParseFile(patient.image)
            }
            const data = {
                data: {
                    objectId: patient?.id,
                    name: patient?.name,
                    specie: patient?.specie || "Não informado",
                    breed: patient?.breed,
                    birthdate: patient?.birthdate,
                    gender: patient?.gender,
                    pelage: patient?.pelage,
                    temperament: patient?.temperament,
                    castrated: patient?.castrated,
                    deceased: patient?.deceased,
                    rga: patient?.rga,
                    microchip: patient?.microchip,
                    size: patient?.size,
                    otherInfo: patient?.otherInfo,
                    notes: patient?.notes,
                    petloveSaudeId: String(patient?.petloveSaudeId),
                    petloveSaudeData: patient?.petloveSaudeData,
                    image: patient?.image
                },
                clinicId: clinic.objectId,
                ownerId: owner.id,
                appVersionName: APP_VERSION,
                deviceType: APP_DEVICE,
                appContentType: APP_NAME
            }
            Parse.Cloud.run("savePatientForOwner", data)
            .then(_patient => {
                return resolve(_patient)
            })
            .catch(error => {
                console.error(error)
                return reject(error?.message || `Não foi possível cadastrar o animal`)
            })
        })
    }

    const save = async () => {
        return new Promise(async (resolve, reject) => {
            setError(null)
            setLoading(true)
            setLoadingMessage(`${owner?.id ? "Atualizando" : "Cadastrando"} os dados do(a) Tutor(a)...`)
    
            const successOwner = await saveOwner()
            .then(_owner => {
                props.dispatch(setHealthImportOwner({
                    ...owner,
                    id: _owner.id
                }))
                return _owner
            })
            .catch(error => {
                setLoading(false)
                setError({
                    message: error?.message || `Não foi possível ${owner?.id ? "atualizar" : "cadastrar"} o(a) Tutor(a)`,
                    function: "save"                
                })
            })
            if (!successOwner) {
                return reject()
            }
    
            const newPatients = [...patients]
            for (var index = 0; index < patients.length; index++) {
                const patient = patients[index]
                if (patient?.selected != "none") {
                    setLoadingMessage(`${patient?.id ? "Atualizando" : "Cadastrando"} o animal ${patient?.name} (${index + 1}/${patients.length})`)
                    const savedPatient = await savePatient(patient, successOwner)
                    .then(_patient => {
                        newPatients[index].id = _patient.id
                        newPatients[index].image = _patient.get("image")
                        return _patient
                    })
                    .catch(error => {
                        setLoading(false)
                        setError({
                            message: error?.message || `Não foi possível ${patient?.id ? "atualizar" : "cadastrar"} o animal ${patient?.name}`,
                            function: "save"                
                        })
                    })
                    if (!savedPatient) {
                        props.dispatch(setHealthImportPatient(newPatients))
                        return reject()
                    }
                }
            }
    
            setLoading(false)
            showSnackbar(`Informações salvas com sucesso!`)
            history.push(`/tutor/${successOwner?.id}`)
            props.dispatch(resetHealthImport())
            return resolve()
        })
    }

    return (
        <div id="health-review">
            <VSPage
                loading={loading}
                loadingMessage={loadingMessage}
                title={(
                    <div className="row">
                        <div className="col d-none d-xl-block">
                            <span>Dados do(a) Tutor(a)</span>
                        </div>
                        <div className="col-auto d-none d-xl-block">
                            <IconArrowLeft width={8} height={12} fill="var(--vsprontuario-primary-color)" style={{ transform: "rotate(180deg)" }} />
                        </div>
                        <div className="col-auto d-none d-lg-block">
                            <span>Animais Vinculados</span>
                        </div>
                        <div className="col-auto d-none d-lg-block">
                            <IconArrowLeft width={8} height={12} fill="var(--vsprontuario-primary-color)" style={{ transform: "rotate(180deg)" }} />
                        </div>
                        <div className="col-auto">
                            <span className="current">Revisar Dados</span>
                        </div>
                    </div>
                )}
                onCancel={() => setCancel(true)}
                submit="Salvar a Finalizar"
                onSubmit={() => save()}
            >
                { error?.message ? (
                    <VSError
                        message={error.message}
                        onClose={() => {
                            switch(error.function) {
                                case "save":
                                    save()
                                    break
                                default:
                            }
                        }}
                    />
                ) : (
                    <div className="row">
                        <div className="col-12 order-2 col-lg-8 order-lg-1">
                            <div className="row align-items-center">
                                <div className="col-12 mb-3">
                                    <div className="title">
                                        3 - Revisar informações do Tutor e Animais vinculados 
                                    </div>
                                </div>
                                <div className="col-12 mb-3 pb-3">
                                    Verifique e organize as informações apresentadas.
                                </div>
                                <div className="col mb-3">
                                    <div className="subtitle">
                                        Cadastro do(a) Tutor(a)
                                    </div>
                                </div>
                                <div className="col-auto mb-3">
                                    <button className="btn-link" onClick={() => history.push(`/petlove-saude`)}>
                                        <IconPrimaryEdit width="18" height="18" className="me-2" />
                                        Editar Cadastro
                                    </button>
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="vsbox">
                                        <div className="vsbox-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="vsbox-title">{ owner?.name }</div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="vsbox-text">
                                                        CPF { owner?.cpf }
                                                        { owner?.rg && (
                                                            <> - RG { owner?.rg }</>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="vsbox-separator my-3"></div>
                                            <div className="row">
                                                <div className="col-auto mb-3">
                                                    <label className="vsbox-info">Celular</label>
                                                    <div className="vsbox-info-text">{ owner?.phone || "Não informado" }</div>
                                                </div>
                                                <div className="col-auto mb-3">
                                                    <label className="vsbox-info">Telefone</label>
                                                    <div className="vsbox-info-text">{ owner?.landlinePhone || "Não informado" }</div>
                                                </div>
                                                <div className="col-auto mb-3">
                                                    <label className="vsbox-info">E-mail</label>
                                                    <div className="vsbox-info-text">{ owner?.email || "Não informado" }</div>
                                                </div>
                                                <div className="col-auto mb-3">
                                                    <label className="vsbox-info">Data de nascimento</label>
                                                    <div className="vsbox-info-text">{ owner?.birthdate ? (typeof owner.birthdate == "string" ? owner.birthdate : dayjs(owner.birthdate).format("DD/MM/YYYY")) : "Não informado" }</div>
                                                </div>
                                                <div className="col-12">
                                                    { owner?.selected == "none" ? (
                                                        <div className="vsbox-alert alert-default">
                                                            <IconClose width={24} height={24} fill="#999999" className="me-1" /> Não será feito nada
                                                        </div>
                                                    ) : (
                                                        <div className="vsbox-alert alert-success">
                                                            <IconCheck width={24} height={24} className="me-1" /> 
                                                            { owner?.selected == "unify" ? (
                                                                <>Cadastro será atualizado</>
                                                            ) : (
                                                                <>Será importado como Novo Cadastro</>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col mb-3">
                                    <div className="subtitle">
                                        Animais vinculados ao(a) tutor(a)
                                    </div>
                                </div>
                                <div className="col-auto mb-3">
                                    <button className="btn-link" onClick={() => history.push(`/petlove-saude/animal`)}>
                                        <IconPrimaryEdit width="18" height="18" className="me-2" />
                                        Editar Cadastros
                                    </button>
                                </div>
                                <div className="col-12">
                                    <div className="vsbox">
                                        <div className="vsbox-body">
                                            { patients?.map((patient, index) => (
                                                <React.Fragment key={index}>
                                                    { index != 0 && <div className="vsbox-separator my-3"></div> }
                                                    <div key={index} className="vsbox-profile mb-3">
                                                        <div className="row align-items-start h-100">
                                                            <div className="col-auto">
                                                                <PatientPhoto patientImage={patient?.image?._url || patient?.image} patientSpecie={patient?.specie} withBorder />
                                                            </div>
                                                            <div className="col ps-0">
                                                                <div className="vsbox-name">{ patient?.name }</div>
                                                                <div className="vsbox-data">
                                                                    { patient?.specie }
                                                                    { patient?.breed && (
                                                                        <> - { patient.breed }</>
                                                                    )}
                                                                    { patient?.gender && (
                                                                        <> - { patient.gender }</>
                                                                    )}
                                                                    { patient?.birthdate && (
                                                                        <> - { getAge(patient.birthdate, true) }</>
                                                                    )}
                                                                    <> | Microchip: { patient?.microchip || "Não informado" }</>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { patient?.selected == "none" ? (
                                                        <div className="vsbox-alert alert-default">
                                                            <IconClose width={24} height={24} fill="#999999" className="me-1" /> Não será feito nada
                                                        </div>
                                                    ) : (
                                                        <div className="vsbox-alert alert-success">
                                                            <IconCheck width={24} height={24} className="me-1" /> 
                                                            { patient?.selected == "unify" ? (
                                                                <>Cadastro será unificado com Animal já existente</>
                                                            ) : (
                                                                <>Será importado como Novo Cadastro</>
                                                            )}
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )) }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 order-1 col-lg-4 order-lg-2 mb-3">
                            <HealthPatientsCard />
                        </div>
                    </div>
                )}
            </VSPage>
            { cancel && (
                <HealthCancel
                    page="Revisar Dados"
                    onCancel={() => history.push(`/tutor${health?.vetsmart?.id ? "/" + health?.vetsmart.id : ""}`)}
                    onKeep={() => setCancel(false)}
                />
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        health: state.health
    };
};

export default connect(mapStateToProps)(HealthReview)