import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { MenuItem, TextField } from '@mui/material'

import { ReactComponent as IconVetsmart } from "../../assets/icon-vetsmart.svg"
import { ReactComponent as IconUpload } from "../../assets/icon-upload.svg"

import { ACL } from '../../constants'
import { queryForSearchByPatientAndOwner } from '../patient/Patient'
import { historyDocument, includeDocuments } from '../patient/tabs/history/History'
import { dayjs, diacriticSensitive } from '../../utils'

import VSMultipleSelect from '../../components/vsMultipleSelect/VSMultipleSelect'
import VSDrawer from '../../components/vsDrawer/VSDrawer'
import VSEmpty from '../../components/vsEmpty/VSEmpty'
import VSList from '../../components/vsList/VSList'
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple'
import VSTimeline from '../../components/vsTimeline/VSTimeline'
import VSBoxPatient from '../../components/cardPatient/VSBoxPatient'


import './HistoryList.scss'
const HistoryList = (props) => {

    const {
        clinic,
        history,
        permissions
    } = props
    
    const rowsPerPage = 5
    const defaultDate = dayjs()

    const filter = useRef(null)
    const isMounted = useRef(null)

    const [ show, setShow ] = useState(null)
    const [ histories, setHistories ] = useState({})
    const [ year, setYear ] = useState(defaultDate.format("YYYY"))
    const [ month, setMonth ] = useState(defaultDate.format("MM"))
    const [ documentsOptions, setDocumentsOptions ] = useState([])
    const [ documentsSelected, setDocumentsSelected ] = useState([])
    
    const years = Array.from(Array(15).keys()).map((_, index) => {
        return defaultDate.clone().add(index - 10, 'years').format("YYYY")
    })
    
    const findHistories = (searchTerm, page) => {
        const dateFrom = dayjs(`01/${month}/${year}`, "DD/MM/YYYY").toDate()
        const dateTo   = dayjs(dateFrom).endOf("month").toDate()
        return new Promise((resolve, reject) => {
            const nonSelectedDocuments = documentsOptions.filter(({ value }) => documentsSelected.map(selected => selected.value).indexOf(value) === -1)
            var filters = {}
            nonSelectedDocuments.map(document => {
                filters[`_p_${document.value}`] = null
            })
            Parse.Cloud.run("getPeriodForClass", {
                clinicId: clinic.objectId,
                className: "VW_History_full",
                filters: filters,
                searchByPatientAndOwner: diacriticSensitive(searchTerm),
                dateFrom: dateFrom,
                dateTo: dateTo,
                order: "newest",
                page: page
            })
            .then(async ({ total, from, to }) => {
                var queryHistory = new Parse.Query("History");
                const queryPatient = await queryForSearchByPatientAndOwner(searchTerm, clinic.object)
                if (queryPatient?.length) {
                    queryHistory.containedIn("patient", queryPatient)
                }
                queryHistory.equalTo("clinic", clinic.object);
                queryHistory.greaterThanOrEqualTo("documentDate", from)
                queryHistory.lessThanOrEqualTo("documentDate", to)
                // Remove unselected documents
                nonSelectedDocuments.map(doc => {
                    queryHistory.doesNotExist(doc.value)
                })
                queryHistory.descending("documentDate");
                queryHistory = includeDocuments(queryHistory)
                queryHistory.find()
                .then(_histories => {
                    let historiesByDate = []
                    let dates = []
                    _histories.map(history => {
                        const documentDateFormatted = dayjs(history.get("documentDate")).format("DD/MM/YYYY")
                        if (history.get("patient")) {
                            const patientId = history.get("patient").id
                            if (!historiesByDate[documentDateFormatted]) {
                                dates.push(documentDateFormatted)
                                historiesByDate[documentDateFormatted] = []
                            }
                            if (!historiesByDate[documentDateFormatted][patientId]) {
                                historiesByDate[documentDateFormatted][patientId] = []
                            }
                            historiesByDate[documentDateFormatted][patientId].push(history)
                        }
                    })
                    setHistories(historiesByDate)
                    resolve({
                        items: dates,
                        total: total
                    })
                })
                .catch(error => {
                    console.error(error)
                    reject(error)
                })
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
    
    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && documentsOptions.length == 0) {
            if (!permissions?.check(ACL.HISTORY, ACL.Rule.VIEW)) {
                permissions?.error()
                return
            }
            
            const options = [
                permissions?.check(ACL.ATTACHMENT, ACL.Rule.VIEW) && {
                    value: "attachment",
                    label: "Anexos"
                },
                permissions?.check(ACL.TERM, ACL.Rule.VIEW) && {
                    value: "term",
                    label: "Atestados/Termos"
                },
                permissions?.check(ACL.SURGERY, ACL.Rule.VIEW) && {
                    value: "surgery",
                    label: "Cirurgias"
                },
                permissions?.check(ACL.APPOINTMENT, ACL.Rule.VIEW) && {
                    value: "appointment",
                    label: "Consultas"
                },
                permissions?.check(ACL.EXAM_REQUEST, ACL.Rule.VIEW) && {
                    value: "examRequest",
                    label: "Exames"
                },
                permissions?.check(ACL.APPOINTMENT_DRUG, ACL.Rule.VIEW) && {
                    value: "appointmentDrug",
                    label: "Medicamentos"
                },
                permissions?.check(ACL.WEIGHT, ACL.Rule.VIEW) && {
                    value: "weight",
                    label: "Pesos"
                },
                permissions?.check(ACL.PRESCRIPTION, ACL.Rule.VIEW) && {
                    value: "prescription",
                    label: "Prescrições"
                },
                permissions?.check(ACL.VACCINE, ACL.Rule.VIEW) && {
                    value: "vaccine",
                    label: "Vacinas"
                },
                permissions?.check(ACL.SALE, ACL.Rule.VIEW) && {
                    value: "sale",
                    label: "Vendas e Orçamentos"
                },
            ].filter(document => document)
            setDocumentsOptions(options)
            setDocumentsSelected(options)
        }
    }, [ permissions ])

    useEffect(() => {
        if (isMounted.current) {
            onChange()
        }
    }, [ month, year, documentsSelected ])

    useEffect(() => {
        isMounted.current = true
        document.addEventListener("History__getList", onChange)
        return () => {
            isMounted.current = false
            document.removeEventListener("History__getList", onChange)
        }
    }, [])

    return (
        <div id="history" className="row">
            <div className="col-12">
                <div className="page-content">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="page-title">
                                Histórico
                            </div>
                        </div>
                    </div>
                    <VSList
                        ref={filter}
                        search={{
                            text: "Buscar por Nome do Animal ou Tutor(a)",
                            value: ""
                        }}
                        pagination={{
                            rowsPerPage: rowsPerPage
                        }}
                        onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                            return findHistories(_searchBy, _page)
                        }}
                        customFilter={(
                            <div className="row">
                                <div className="col-12 col-sm pe-sm-0 mb-3 mb-sm-0" style={{ minWidth: '200px' }}>
                                    <VSMultipleSelect
                                        label="Tipos de Registro"
                                        onChange={(value) => {
                                            if (value.length > 0) {
                                                setDocumentsSelected(value)
                                            }
                                        }}
                                        options={documentsOptions}
                                        getLabel={(option) => option.label}
                                        getValue={(option) => option.value}
                                        showInputValue={(optionsSelected) => {
                                            return optionsSelected.length == documentsOptions.length ? "Todos" : (optionsSelected.length == 1 ? optionsSelected[0].label : `${optionsSelected.length} selecionados`)
                                        }}
                                        value={documentsSelected}
                                        enabledAllOptions
                                    />
                                </div>
                                <div className="col-6 col-sm-auto">
                                    <TextField
                                        label="Ano"
                                        value={year}
                                        onChange={({ target: { value } }) => setYear(value)}
                                        helperText={null}
                                        select
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    >
                                        { years.map((_year, index) => (
                                            <MenuItem value={_year} key={index}>{ _year }</MenuItem>
                                        )) }
                                    </TextField>
                                </div>
                                <div className="col-6 col-sm-auto ps-0">
                                    <TextField
                                        label="Mês"
                                        value={month}
                                        onChange={({ target: { value } }) => setMonth(value)}
                                        helperText={null}
                                        select
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    >
                                        <MenuItem value={"01"}>
                                            Janeiro
                                        </MenuItem>
                                        <MenuItem value={"02"}>
                                            Fevereiro
                                        </MenuItem>
                                        <MenuItem value={"03"}>
                                            Março
                                        </MenuItem>
                                        <MenuItem value={"04"}>
                                            Abril
                                        </MenuItem>
                                        <MenuItem value={"05"}>
                                            Maio
                                        </MenuItem>
                                        <MenuItem value={"06"}>
                                            Junho
                                        </MenuItem>
                                        <MenuItem value={"07"}>
                                            Julho
                                        </MenuItem>
                                        <MenuItem value={"08"}>
                                            Agosto
                                        </MenuItem>
                                        <MenuItem value={"09"}>
                                            Setembro
                                        </MenuItem>
                                        <MenuItem value={"10"}>
                                            Outubro
                                        </MenuItem>
                                        <MenuItem value={"11"}>
                                            Novembro
                                        </MenuItem>
                                        <MenuItem value={"12"}>
                                            Dezembro
                                        </MenuItem>
                                    </TextField>
                                </div>
                                <div className="col-12 col-md-auto ps-md-0 mt-3 mt-md-0">
                                    <button
                                        className="btn-submit btn-square w-100"
                                        onClick={() => history.push('/historico/selecionar')}
                                    >
                                        <IconUpload width="20px" height="20px" className="me-2" fill="white" />
                                        Compartilhar Histórico
                                    </button>
                                </div>
                            </div>
                        )}
                        renderItem={(item) => {
                            const date = dayjs(item, "DD/MM/YYYY")
                            return (
                                <VSTimeline
                                    day={ date.format("DD") }
                                    month={ date.format("MMM") }
                                    year={ date.format("YYYY") }
                                    key={item}
                                >
                                    { histories && histories[item] && Object.keys(histories[item]).map((patientId, index) => {
                                        const _histories = histories[item][patientId]
                                        const patient = _histories[0].get("patient")
                                        const key = `${item.replace(/[^0-9]/g, '')}${index}`
                                        const isAllDeleted = _histories.reduce((carry, history) => !carry ? false : history.get('isDeleted'), true)
                                        return (
                                            <React.Fragment key={key}>
                                                <VSAccordionSimple
                                                    expanded={true}
                                                    className="custom-accordion"
                                                    hideIcon
                                                    header={(expanded) => (
                                                        <div className={`vstimeline-item vstimeline-active w-100`}>
                                                            <VSBoxPatient
                                                                count={_histories.length}
                                                                patient={patient}
                                                                expanded={expanded}
                                                                disabled={isAllDeleted}
                                                            />
                                                        </div>
                                                    )}
                                                >
                                                    { _histories && _histories.map((data, index) => {
                                                        const document = historyDocument(data)
                                                        if (!document)
                                                            return
                                                        return (
                                                            <div key={index} className={`${document.object.get("isDeleted") ? "vstimeline-item vstimeline-inactive" : ""}`}>
                                                                <document.view object={document.object} history={history} key={index} noDocumentDate noAudit />
                                                            </div>
                                                        )
                                                    }) }
                                                </VSAccordionSimple>
                                            </React.Fragment>
                                        )
                                    }) }
                                </VSTimeline>
                            )
                        }}
                        renderEmpty={() => (
                            documentsOptions.length !== documentsSelected.length ? (
                                <VSEmpty
                                    icon={<IconVetsmart width="32px" height="32px" />}
                                    title="Não encontramos nenhum resultado para sua busca"
                                    text="Tente refazer a busca utilizando outros caracteres ou alterando a data e os tipos de registro"
                                />
                            ) : (
                                <VSEmpty
                                    icon={<IconVetsmart width="32px" height="32px" />}
                                    title="Você não possui nenhum histórico nesse período"
                                    text="Cadastre o primeiro registro acessando a aba de animais"
                                />
                            )
                        )}
                        renderNotFound={() => (
                            <VSEmpty
                                icon={<IconVetsmart width="32px" height="32px" />}
                                title="Não encontramos nenhum resultado para sua busca"
                                text="Tente refazer a busca utilizando outros caracteres ou alterando a data e os tipos de registro"
                            />
                        )}
                    />
                </div>
            </div>
            <VSDrawer
                id="history-view"
                open={show != null}
                title={show && show.document}
                onCancel={() => setShow(null)}
                VSDrawerFooterProps={{
                    show: false
                }}
            >
                { show && React.createElement(show.view, {
                    object: show.object,
                    history: history
                })}
            </VSDrawer>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(HistoryList)