import axios from "axios"
import VSModal from "../../components/vsModal/VSModal"
import { APP_DEVICE, APP_NAME, APP_VERSION, Filter_Limit } from "../../constants";
import { ownerIsNotDeleted } from "../owner/Owner";
import { ReactComponent as IconCat } from '../../assets/icon-cat.svg'
import { ReactComponent as IconDog } from '../../assets/icon-dog.svg'
import { ReactComponent as IconPaw } from '../../assets/icon-paw.svg'
import { dayjs, diacriticSensitive, getPropertyFromObject } from "../../utils";
import HealthTag from "../health/HealthTag";

export const patientIsNotDeleted = (clinicId) => {
    const queryOwner = ownerIsNotDeleted(clinicId)
    const queryPatient = new Parse.Query("Patient");
    queryPatient.equalTo("isDeleted", false);
    queryPatient.matchesQuery("owner", queryOwner);
    return queryPatient
}

export const deletePatient = (patient, callback) => {
    const patientName = patient.get("name")
    Swal.fire({
        // allowOutsideClick: false,
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title="Tem certeza que deseja excluir este cadastro de Animal?"
                text="Todos os registros e históricos vinculados a este animal serão excluídos. Os dados não poderão ser recuperados e esta operação não poderá ser desfeita"
                textConfirm={<>Digite o <b>nome</b> do animal para prosseguir com a exclusão do cadastro</>}
                placeholderConfirm={`Digite o Nome do Animal: ${patientName}`}
                compareTo={patientName}
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir Cadastro",
                        action: () => {
                            const owner = patient.get("owner")
                            const clinic = owner.get("clinic")
                            Parse.Cloud.run("savePatientForOwner", {
                                data: {
                                    objectId: patient.id,
                                    isDeleted: true
                                },
                                clinicId: clinic.id,
                                ownerId: owner.id,
                                appVersionName: APP_VERSION,
                                deviceType: APP_DEVICE,
                                appContentType: APP_NAME
                            })
                            // patient.save()
                            .then(_ => {
                                Swal.fire(
                                    'Excluído!',
                                    'O animal foi excluído com sucesso.',
                                    'success'
                                )
                                .then(_ => {
                                    callback && callback()
                                })
                            })
                            .catch(error => {
                                console.error(error)
                                Swal.fire(
                                    'Desculpe',
                                    'Ocorreu algum erro ao tentar excluir o animal',
                                    'error'
                                )
                            })
                        }
                    }
                ]}
            />
        )
    })
}

var searchPatientsWithDelay = null;
export const searchPatients = (name, clinic) => {
    return new Promise((resolve, reject) => {
        clearInterval(searchPatientsWithDelay);
        searchPatientsWithDelay = setTimeout(() => {
            const queryPatient = new Parse.Query("Patient")
            queryPatient.equalTo("clinic", clinic)
            queryPatient.equalTo("isDeleted", false)
            if (name) {
                queryPatient.matches("name", diacriticSensitive(name), "im");
            }
            queryPatient.ascending("name")
            queryPatient.limit(Filter_Limit)
            queryPatient.withCount()
            queryPatient.find()
            .then(_patients => {
                resolve(_patients)
            })
            .catch(error => {
                console.error(error);
                reject("Ocorreu algum erro ao tentar buscar o animal")
            })
        }, 300);
    })
}

/**
 * Precisamos modificar essa função após a atualização de versão
 * do Parse Server para a versão 8, porque o $inQuery com o $or
 * não estava funcionando (01/04/2025)
 * 
 * @param {*} searchTerm 
 * @param {*} clinic 
 * @returns Array de Patient.objectId
 */
export const queryForSearchByPatientAndOwner = async (searchTerm, clinic) => {
    if (searchTerm) {
        const ids = []

        if(!isNaN(searchTerm)){
            var searchByPatientId = new Parse.Query("Patient");
            searchByPatientId.equalTo("clinic", clinic);
            searchByPatientId.equalTo("isDeleted", false);
            searchByPatientId.equalTo('patientNumberId', Number(searchTerm))
            searchByPatientId.select("objectId")
            const patients = await searchByPatientId.find()
            patients.map(patient => {
                ids.push(patient.id)
            })
        } else {
            const promises = []
            const diacriticTerm = diacriticSensitive(searchTerm)
            
            const searchByOwner = new Parse.Query("Owner");
            searchByOwner.equalTo("clinic", clinic);
            searchByOwner.equalTo("isDeleted", false);
            searchByOwner.matches("name", diacriticTerm, "im")
        
            const searchByOwnerPatient = new Parse.Query("Patient");
            searchByOwnerPatient.equalTo("clinic", clinic);
            searchByOwnerPatient.equalTo("isDeleted", false);
            searchByOwnerPatient.matchesQuery("owner", searchByOwner)
            searchByOwnerPatient.select("objectId")
            promises.push(searchByOwnerPatient.find())
    
            var searchByPatient = new Parse.Query("Patient");
            searchByPatient.equalTo("clinic", clinic);
            searchByPatient.equalTo("isDeleted", false);
            searchByPatient.matches("name", diacriticTerm, "im")
            searchByPatient.select("objectId")
            promises.push(searchByPatient.find())
            const [ arr1, arr2 ] = await Promise.all(promises)
            const patients = arr1.concat(arr2)
            patients.map(patient => {
                ids.push(patient.id)
            })
        }

        const unique = [...new Set(ids)]
        return unique
    } else {
        return null
    }
}

export const getPatient = (patientId, clinicObject, includes = ["clinic"]) => {
    return new Promise((resolve, reject) => {
        var queryPatient = new Parse.Query("Patient");
        queryPatient.equalTo("clinic", clinicObject);
        queryPatient.equalTo("isDeleted", false);
        includes.map(className => {
            queryPatient.include(className)
        })
        queryPatient.get(patientId)
        .then(_patient => {
            resolve(_patient)
        })
        .catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar o animal')
        })
    })
}

export const getPatientLimit = (clinic) => {
    return new Promise((resolve) => {
        Parse.Cloud.run("checkPatientLimit", {
            clinicId: clinic.id
        })
        .then(limit => {
            return resolve(limit)
        })
        .catch(error => {
            console.error(error)
            return resolve(null)
        })
    })
}

export const getAge = (birthdate, onlyAge = false) => {
    if (birthdate) {
        const years = dayjs().diff(dayjs(birthdate), "years")
        const months = dayjs().diff(dayjs(birthdate), "months") % 12
        let age = []
        if (years) {
            age.push(`${years} ano${years > 1 ? "s" : ""}`)
            if (months) {
                age.push("e")
            }
        }
        if (months) {
            age.push(`${months} ${months > 1 ? "meses" : "mês"}`)
        }
        if (age.length == 0) {
            const days = dayjs().diff(dayjs(birthdate), "days")
            return `${days} dia${days == 1 ? "" : "s"}`
        }
        if (onlyAge) {
            return age.join(" ")
        }
        return `${dayjs(birthdate).format("DD/MM/YYYY")} (${age.join(" ")})`
    }
    return ""
}

export const getAgeFromPatient = (patient, onlyAge = false) => {
    if (patient && patient.get && patient.get("birthdate")) {
        return getAge(patient.get("birthdate"), onlyAge)
    }
    return ""
}

export const getHealthTagFromPatient = (patient, withStatus = true) => {
    if (patient?.get("petloveSaudeId")) {
        return <HealthTag status={withStatus ? "success" : "default"} />
    }
    if (patient?.get("healthName") || patient?.get("healthCard") || patient?.get("healthExpiresAt")) {
        return <HealthTag status="health" />
    }
    return null
}

export const comparePatientPetloveSaudeData = (oldPetloveSaudeData, newPetloveSaudeData) => {
    var isEqual = true
    const keysToCompare = [
        "id",
        "name",
        "num_chip",
        "chip_temporary_expire_at",
        "avatar",
        "dt_birthday",
        "specie",
        "race",
        "gender",
        "plan.name",
        "plan.status_id"
    ]
    keysToCompare.map(key => {
        if (getPropertyFromObject(oldPetloveSaudeData, key) != getPropertyFromObject(newPetloveSaudeData, key)) {
            isEqual = false
        }
    })
    return isEqual
}

export const getSpecies = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_V2}/json/species?unordered`)
        .then(({ data }) => {
            if (data) {
                return resolve(data)
            } else {
                return reject()
            }
        })
        .catch(error => {
            console.error(error)
            return reject()
        })
    })
}

export const getGenders = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_V2}/json/genders?unordered`)
        .then(({ data }) => {
            if (data) {
                return resolve(data)
            } else {
                return reject()
            }
        })
        .catch(error => {
            console.error(error)
            return reject()
        })
    })
}

export const getPelages = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_V2}/json/pelages`)
        .then(({ data }) => {
            if (data) {
                return resolve(data)
            } else {
                return reject()
            }
        })
        .catch(error => {
            console.error(error)
            return reject()
        })
    })
}

export const getTemperaments = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_V2}/json/temperaments`)
        .then(({ data }) => {
            if (data) {
                return resolve(data)
            } else {
                return reject()
            }
        })
        .catch(error => {
            console.error(error)
            return reject()
        })
    })
}

let breeds = []
export const getBreeds = (specie) => {
    return new Promise((resolve, reject) => {
        if (isCanine(specie) || isFeline(specie)) {
            if (breeds[specie]) {
                return resolve(breeds[specie])
            } else {
                axios.get(`${process.env.REACT_APP_API_V2}/json/breeds/${specie}?unordered`)
                .then(({ data }) => {
                    if (data) {
                        breeds[specie] = data
                        return resolve(data)
                    } else {
                        return reject()
                    }
                })
                .catch(error => {
                    console.error(error)
                    return reject()
                })
            }
        } else {
            return resolve([])
        }
    })
}

export const isCanine = (_specie) => {
    const specie = _specie || ""
    return specie.toLowerCase() == "canino"
}

export const isFeline = (_specie) => {
    const specie = _specie || ""
    return specie.toLowerCase() == "felino"
}

export const getSpecieIcon = (specie, options) => {
    specie = specie || ""
    return specie.match(/(canino)/ig) ? (
        <IconDog {...options} />
    ) : specie.match(/(felino)/ig) ? (
        <IconCat {...options} />
    ) : (
        <IconPaw {...options} />
    )
}

export const sizeOptions = [
    "Pequeno",
    "Médio",
    "Grande"
]

export const deceasedDescription = {
    "0": "Não",
    "1": "Sim"
}

export const castratedDescription = {
    "0": "Não",
    "1": "Sim",
    "2": "Indefinido"
}

export const unisexPatient = (gender) => {
    switch(gender) {
        case "Macho":
            return "o"
        case "Fêmea":
            return "a"
        default:
            return "o(a)"
    }
}

export const years = [...Array(120).keys()].map(i => String(i))
export const months = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"]