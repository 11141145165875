import { useEffect, useState } from "react"
import DateInput from "../../../../../components/dateInput/DateInput"
import { dayjs } from "../../../../../utils"

const ReminderField = (props) => {

    const {
        title,
        isReminder,
        documentDate,
        reminderDate,
        reminderDateText,
        handleReminderDate,
        errorReminderDate,
        helperReminderDate
    } = props

    const defaultDate = dayjs()
    const [ next21Days, setNext21Days ] = useState(defaultDate.clone().add(21, 'days').format("DD/MM/YYYY"))
    const [ next28Days, setNext28Days ] = useState(defaultDate.clone().add(28, 'days').format("DD/MM/YYYY"))
    const [ next30Days, setNext30Days ] = useState(defaultDate.clone().add(30, 'days').format("DD/MM/YYYY"))
    const [ next45Days, setNext45Days ] = useState(defaultDate.clone().add(45, 'days').format("DD/MM/YYYY"))
    const [ next6Months, setNext6Months ] = useState(defaultDate.clone().add(6, 'months').format("DD/MM/YYYY"))
    const [ nextYear, setNextYear ] = useState(defaultDate.clone().add(1, 'year').format("DD/MM/YYYY"))

    useEffect(() => {
        if (reminderDate && !reminderDateText) {
            handleReminderDate(dayjs(reminderDate).format("DD/MM/YYYY"))
        }
    }, [ reminderDate ])

    useEffect(() => {
        if (documentDate && !isReminder) {
            const defaultDate = dayjs(documentDate)
            setNext21Days(defaultDate.clone().add(21, 'days').format("DD/MM/YYYY"))
            setNext30Days(defaultDate.clone().add(30, 'days').format("DD/MM/YYYY"))
            setNext45Days(defaultDate.clone().add(45, 'days').format("DD/MM/YYYY"))
            setNext6Months(defaultDate.clone().add(6, 'months').format("DD/MM/YYYY"))
            setNextYear(defaultDate.clone().add(1, 'year').format("DD/MM/YYYY"))
        }
    }, [ documentDate ])

    return (
        <div className="row align-items-center mb-3">
            <div className="col-12 col-sm pt-3">
                <div className="row gx-2 align-items-center">
                    <div className="col mb-3">
                        <button
                            className={`btn-outline-submit btn-select ${reminderDateText == next21Days ? "selected" : ""}`}
                            onClick={() => handleReminderDate(next21Days)}
                        >
                            em 21<br/> dias
                        </button>
                    </div>
                    <div className="col mb-3">
                        <button
                            className={`btn-outline-submit btn-select ${reminderDateText == next28Days ? "selected" : ""}`}
                            onClick={() => handleReminderDate(next28Days)}
                        >
                            em 28<br/> dias
                        </button>
                    </div>
                    <div className="col mb-3">
                        <button
                            className={`btn-outline-submit btn-select ${reminderDateText == next30Days ? "selected" : ""}`}
                            onClick={() => handleReminderDate(next30Days)}
                        >
                            em 30<br/> dias
                        </button>
                    </div>
                    <div className="col mb-3">
                        <button
                            className={`btn-outline-submit btn-select ${reminderDateText == next45Days ? "selected" : ""}`}
                            onClick={() => handleReminderDate(next45Days)}
                        >
                            em 45<br/> dias
                        </button>
                    </div>
                    <div className="col-6 col-sm mb-3">
                        <button
                            className={`btn-outline-submit btn-select ${reminderDateText == next6Months ? "selected" : ""}`}
                            onClick={() => handleReminderDate(next6Months)}
                        >
                            em 6<br/> meses
                        </button>
                    </div>
                    <div className="col-6 col-sm mb-3">
                        <button
                            className={`btn-outline-submit btn-select ${reminderDateText == nextYear ? "selected" : ""}`}
                            onClick={() => handleReminderDate(nextYear)}
                        >
                            em 1<br/> ano
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-12 col-xxl-auto">
                <div className="row gx-2 align-items-center">
                    <div className="col-auto vaccine-text">
                        OU
                    </div>
                    <div className="col col-xxl-auto" style={{ width: "150px" }}>
                        <div className="row">
                            { title && (
                                <div className="col-12 mb-1">
                                    <div className="input-subtitle">
                                        { title }
                                    </div>
                                </div>
                            )}
                            <div className="col-12">
                                <DateInput
                                    placeholder="00/00/0000"
                                    value={reminderDateText || null}
                                    error={errorReminderDate}
                                    onChange={(value) => handleReminderDate(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { (isReminder || errorReminderDate) && (
                <div className="col-12">
                    <small className={errorReminderDate ? "text-danger" : "text-gray"}>{ helperReminderDate }</small>
                </div>
            )}
        </div>
    )
}

export default ReminderField