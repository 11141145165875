import React from "react"

import { CircularProgress } from "@mui/material"

import { ReactComponent as IconArrowRight } from "../../assets/icon-arrow-right.svg"
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"

import VSTooltip from "../vsTooltip/VSTooltip"

import "./VSPage.scss"
const VSPage = (props) => {

    const {
        title,
        subtitle,
        cancel,
        onCancel,
        errors,
        disabled,
        loading,
        loadingMessage,
        submit,
        onSubmit,
        children
    } = props

    return (
        <div id="vspage">
            <div className="vspage-header">
                <div className="row gx-3 align-items-center">
                    <div className="col-auto vsheader-title">
                        <div className="title">{ title }</div>
                    </div>
                    <div className="col-auto d-none d-lg-block">
                        { subtitle }
                    </div>
                    { onCancel && (
                        <div className="col text-end vsheader-close">
                            <button className="btn-cancel" onClick={() => onCancel()}>
                                <IconClose width="20" height="20" className="d-block d-md-none" fill="#444444" />
                                <span className="d-none d-md-block">{ cancel || "Cancelar" }</span>
                            </button>
                        </div>
                    )}
                    { onSubmit && (
                        <div className="col-auto vsheader-save">
                            <VSTooltip
                                id="vspage-tooltip"
                                placement="bottom-start"
                                open={disabled}
                                title={
                                    <React.Fragment>
                                        { errors && errors[0] }
                                    </React.Fragment>
                                }
                            >
                                <button
                                    className="btn-submit"
                                    disabled={disabled}
                                    onClick={() => onSubmit()}
                                    style={{
                                        minWidth: '300px'
                                    }}
                                >
                                    { loading ? (
                                        <CircularProgress size={14} style={{ color: "var(--vsprontuario-gray-color-dark)", marginTop: '5px' }} />
                                    ) : (
                                        <div className="row">
                                            <div className="col text-start text-md-center">
                                                { submit || "Salvar" }
                                            </div>
                                            <div className="col-auto d-block d-md-none">
                                                <IconArrowRight width={18} height={18} fill="#FFFFFF" />
                                            </div>
                                        </div>
                                    )}
                                </button>
                            </VSTooltip>
                        </div>
                    )}
                </div>
            </div>
            <div className="vspage-body">
                <div className="row">
                    <div className="col-12">
                        { loading ? (
                            <div className="data-loading">
                                <CircularProgress />
                                <p className="mt-4">{ loadingMessage }</p>
                            </div>
                        ) : (
                            children
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VSPage