import React, { useEffect, useState } from "react"
import { getCurrentHealthStatus, getHealthStatusOptions } from "./Health"

import "./HealthStatus.scss"
const HealthStatus = (props) => {

    const {
        history,
        patient,
        pet,
        noDetails
    } = props

    const [ status, setStatus ] = useState(null)
    const statusOptions = getHealthStatusOptions(pet)

    useEffect(() => {
        setStatus(null)

        if (pet) {
            const statusOption = getCurrentHealthStatus(pet)
            if (statusOption) {
                const currentStatus = statusOptions.find(option => option.id == statusOption)
                if (currentStatus) {
                    setStatus(currentStatus)
                }
            }
        }
    }, [ pet ])

    return status && (
        <div id="health-status" className={`alert ${ status.className }`}>
            <div className="row align-items-baseline align-items-lg-center">
                <div className="col-auto pe-2">
                    { status.icon }
                </div>
                <div className="col ps-1">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg">
                            Petlove Saúde: <b>{ pet.plan.name }</b>
                            { status.aditionalContent && (
                                <>&nbsp;| { status.aditionalContent }</>
                            )}
                        </div>
                        { status.details && !noDetails && (
                            <div className="col-auto">
                                <div className="alert-button" onClick={() => history.push(`/animal/${patient.id}/saude/coberturas`)}>
                                    Ver detalhes
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HealthStatus