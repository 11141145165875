import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs';

import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple';
import HealthStatus from '../../../health/HealthStatus';

import { CircularProgress } from '@mui/material';
import { getAge } from '../../Patient';

import './PatientHealthCard.scss'
const PatientHealthCard = (props) => {
    const {
        pet,
        patient,
        history
    } = props
    
    const owner = patient?.get('owner')
    const [ loading, setLoading ] = useState(true)

    useEffect(() => {
        setLoading(true)
        if (pet) {
            setLoading(false)
        }
    }, [ pet ])

    return (
        <div id="patient-health-card" className="vsbox">
            {loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : (
                <>
                    <HealthStatus 
                        pet={pet} 
                        patient={patient} 
                        history={history} 
                        noDetails
                    />
                    <div className="vsbox-body pb-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="patient-health-card-title">
                                    ID {pet.id} • {pet.name}
                                </div>
                                <div className="patient-health-card-text">
                                    Espécie: {pet.specie}
                                    {pet.race && (
                                        <> - Raça: {pet.race}</>
                                    )}
                                    {pet.dt_birthday && 
                                        <> - { getAge(pet.dt_birthday, true) }</>
                                    }
                                </div>
                                {pet.chip_temporary_expire_at && 
                                dayjs(pet.chip_temporary_expire_at).isAfter() && 
                                pet.num_chip && (
                                    <div className="patient-health-card-text mt-2">
                                        Microchip provisório: {pet.num_chip} - Expira em: {dayjs(pet.chip_temporary_expire_at).format("DD/MM/YYYY")}
                                    </div>
                                )}
                                {owner && (
                                    <>
                                        <div className="vsbox-separator mt-3"></div>
                                        <div className="row">
                                            <div className="col-12">
                                                <VSAccordionSimple
                                                    header={(expanded) => (
                                                        `${ expanded ? "Ocultar" : "Ver" } Informações do(a) Tutor(a)`
                                                    )}
                                                >
                                                    <div className="row mb-2">
                                                        <div className="col-12 col-xl-auto">
                                                            <label className="vsbox-info">
                                                                Nome
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("name") }
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-auto">
                                                            <label className="vsbox-info">
                                                                CPF
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("cpf") || "Não informado" }
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-auto">
                                                            <label className="vsbox-info">
                                                                Celular
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("phone") || "Não informado" }
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg">
                                                            <label className="vsbox-info">
                                                                Telefone
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("landlinePhone") || "Não informado" }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </VSAccordionSimple>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default PatientHealthCard