import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';
import { compareOwnerPetloveSaudeData, deleteOwner, getOwner } from './Owner';
import OwnerViewPatient from './OwnerViewPatient';

import { ReactComponent as IconAdd } from "../../assets/icon-add.svg"
import { ReactComponent as IconPaw } from "../../assets/icon-paw-menu.svg"
import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconPrimaryTrash } from "../../assets/icon-primary-trash.svg"
import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconUpload } from "../../assets/icon-upload.svg"
import { ReactComponent as IconSearch } from "../../assets/icon-search.svg"

import { ACL } from '../../constants';
import { deletePatient, getAgeFromPatient } from '../patient/Patient';
import { addEvent, dayjs, diacriticSensitive, saveOnTracker } from '../../utils';

import CardViewSwitch from '../../components/cardViewSwitch/CardViewSwitch';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import PatientPhoto from '../../components/patientPhoto/PatientPhoto';
import VSPagination, { showPagination } from '../../components/vsPagination/VSPagination';
import { historyDocument, includeDocuments } from '../patient/tabs/history/History';
import PatientShareHistory from '../patient/PatientShareHistory';

import OwnerViewHistory from './OwnerViewHistory';
import OwnerViewSale from './OwnerViewSale';
import OwnerViewEstimate from './OwnerViewEstimate';
import OwnerViewReceivables from './OwnerViewReceivables';
import OwnerViewReminder from './OwnerViewReminder';
import HealthAlert from '../health/HealthAlert';
import { getOwnerReduxFromOwnerPetlove, getPetloveSaudePlan } from '../health/Health';
import { setHealthImport } from '../../store/actions';

var delayForSearch = null

import './OwnerView.scss'
const OwnerView = (props) => {

    const { id } = useParams()

    const {
        clinic,
        history,
        permissions
    } = props

    const [ expandCards, setExpandCards ] = useState(false)

    const [ owner, setOwner ] = useState(null)
    const [ health, setHealth ] = useState(null)
    const [ patientsData, setPatientsData ] = useState(null)
    const [ loadingOwner, setLoadingOwner ] = useState(true)
    const [ loadingPatient, setLoadingPatient ] = useState(false)
    const [ petloveSaudeDataIsDiff, setPetloveSaudeDataIsDiff ] = useState(false)
    
    const getPetloveSaude = async () => {
        if (owner && clinic.health?.available) {
            const _health = await getPetloveSaudePlan(null, owner?.get("cpf"))
            setHealth(_health)
        }
    }

    const init = () => {
        if (id && clinic.isLoaded) {
            setLoadingOwner(true)

            getOwner(id, clinic.object, [])
            .then(_owner => {
                setOwner(_owner)
                setLoadingOwner(false)
            })
            .catch(error => {
                setLoadingOwner(false)
                console.error(error)
            })

        }
    }

    const getPatients = () => {
        setLoadingPatient(true)
        const query = new Parse.Query("Patient")
        query.equalTo("clinic", clinic.object)
        query.equalTo("owner", owner)
        query.equalTo("isDeleted", false)
        query.ascending("name");
        query.addAscending("objectId")
        query.withCount()
        query.limit(3000)
        return query.find()
        .then(({ count, results }) => {
            const _patientsData = {
                count: count || 0,
                results: results || []
            }

            setPatientsData(_patientsData)
            setLoadingPatient(false)
            return _patientsData
        })
        .catch(error => {
            console.error(error)
            setLoadingPatient(false)
        })
    }

    const onClickMerge = () => {
        const healthImport = {
            petlove: health,
            vetsmart: owner
        }
        props.dispatch(setHealthImport(healthImport))
        history.push(`/petlove-saude`)
    }

    useEffect(() => {
        if (owner && owner.id) {
            addEvent("TourPatient__viewOwner", {})

            setExpandCards(false)
            setPatientsData(null)
            getPetloveSaude()

            if (!permissions?.check(ACL.OWNER, ACL.Rule.VIEW)) {
                permissions?.error()
            }
        }
    }, [ owner ])

    useEffect(() => {
        init()
    }, [ clinic, id ])

    useEffect(() => {
        if(expandCards && !patientsData){
            getPatients()
        }
    }, [ expandCards ])

    useEffect(() => {
        const isDiff = !compareOwnerPetloveSaudeData(owner?.get("petloveSaudeData"), getOwnerReduxFromOwnerPetlove(health))
        setPetloveSaudeDataIsDiff(isDiff)
    }, [ owner, health ])

    const ownerNotImported = !owner?.get("petloveSaudeId")

    return (
        <div id="owner-view" className="row">
            <div className="col-12 px-0">
                <div className="page-content">
                    <div className="row mb-3">
                        <div className="col-12">
                            <small
                                className="page-subtitle"
                                onClick={() => history.push('/tutor')}
                            >
                                <IconArrowLeft width="8" height="12" /> Ir para Lista de Tutores
                            </small>
                        </div>
                    </div>
                    { (loadingOwner || !owner) ? (
                        <div className="row">
                            <div className="col-12">
                                <div className="data-loading">
                                    <CircularProgress />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            { health && (
                                ownerNotImported ? (
                                    <div className="col-12 mb-3">
                                        <HealthAlert
                                            onClick={onClickMerge}
                                        />
                                    </div>
                                ) : petloveSaudeDataIsDiff && (
                                    <div className="col-12 mb-3">
                                        <HealthAlert
                                            text="Identificamos informações atualizadas sobre o cadastro na base do Plano de Saúde Petlove."
                                            buttonText="Atualizar Cadastro"
                                            onClick={onClickMerge}
                                        />
                                    </div>
                                )
                            )}
                            <div className="col-12 col-xl-auto pe-xl-0 owner-info-wrap">
                                <div className="vsbox">
                                    <div className="vsbox-head">
                                        <div className="vsbox-title">
                                            { owner.get("name") }
                                        </div>
                                        <div className="vsbox-text mb-4">
                                            Data de Cadastro { dayjs(owner.createdAt).format("DD/MM/YYYY") }
                                        </div>
                                        <div className="row flex-sm-row flex-column">
                                            { permissions?.check(ACL.OWNER, ACL.Rule.EDIT, owner) && (
                                                <div className="col pe-sm-2">
                                                    <button
                                                        className="vsbox-btn vsbox-btn-primary"
                                                        onClick={() => history.push(`/tutor/form/${owner.id}`)}
                                                    >
                                                        <IconPrimaryEdit width="18px" height="18px" fill="#FFFFFF" />
                                                        Editar Cadastro
                                                    </button>
                                                </div>
                                            )}
                                            { permissions?.check(ACL.OWNER, ACL.Rule.DELETE, owner) && (
                                                <div className="col ps-sm-2 mt-2 mt-sm-0">
                                                    <button
                                                        className="vsbox-btn vsbox-btn-alert"
                                                        onClick={() => deleteOwner(owner, () => history.push(`/tutor`))}
                                                    >
                                                        <IconPrimaryTrash width="18px" height="18px" fill="#FFFFFF" />
                                                        Excluir Cadastro
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="vsbox-body py-1">
                                        {owner.get("phone") && 
                                        <div className="row">
                                            <div className="col-12 py-3">
                                                <a
                                                    href={`https://wa.me/55${owner.get("phone").replace(/[^0-9]/g, "")}`}
                                                    target="_blank"
                                                    className="btn-outline-submit btn-square w-100 d-block"
                                                    style={{ textTransform: "none" }}
                                                    onClick={() => saveOnTracker("Compartilhou", "Mensagem para Tutor(a)", owner.id, "", "Visualização do Tutor", owner.id, "", "WhatsApp Web")}
                                                >
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="me-2" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19.05 4.91C17.18 3.03 14.69 2 12.04 2C6.58005 2 2.13005 6.45 2.13005 11.91C2.13005 13.66 2.59005 15.36 3.45005 16.86L2.05005 22L7.30005 20.62C8.75005 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.9501 17.38 21.9501 11.92C21.9501 9.27 20.92 6.78 19.05 4.91ZM12.04 20.15C10.56 20.15 9.11005 19.75 7.84005 19L7.54005 18.82L4.42005 19.64L5.25005 16.6L5.05005 16.29C4.23005 14.98 3.79005 13.46 3.79005 11.91C3.79005 7.37 7.49005 3.67 12.03 3.67C14.23 3.67 16.3 4.53 17.85 6.09C19.41 7.65 20.26 9.72 20.26 11.92C20.2801 16.46 16.58 20.15 12.04 20.15ZM16.56 13.99C16.31 13.87 15.09 13.27 14.87 13.18C14.64 13.1 14.48 13.06 14.31 13.3C14.14 13.55 13.67 14.11 13.53 14.27C13.39 14.44 13.24 14.46 12.99 14.33C12.74 14.21 11.94 13.94 11 13.1C10.26 12.44 9.77005 11.63 9.62005 11.38C9.48005 11.13 9.60005 11 9.73005 10.87C9.84005 10.76 9.98005 10.58 10.1 10.44C10.22 10.3 10.27 10.19 10.35 10.03C10.43 9.86 10.39 9.72 10.33 9.6C10.27 9.48 9.77005 8.26 9.57005 7.76C9.37005 7.28 9.16005 7.34 9.01005 7.33C8.86005 7.33 8.70005 7.33 8.53005 7.33C8.36005 7.33 8.10005 7.39 7.87005 7.64C7.65005 7.89 7.01005 8.49 7.01005 9.71C7.01005 10.93 7.90005 12.11 8.02005 12.27C8.14005 12.44 9.77005 14.94 12.25 16.01C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.69 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.03 14.27C16.96 14.16 16.81 14.11 16.56 13.99Z" fill="#00C4B4"/>
                                                    </svg>
                                                    Enviar Mensagem para Tutor(a)
                                                </a>
                                            </div>
                                        </div>}
                                        <div className="row">
                                            <div className="col-12">
                                                <VSAccordionSimple
                                                    expanded={true}
                                                    header={(expanded) => (
                                                        `${ expanded ? "Ocultar" : "Ver" } Identificação do Cliente`
                                                    )}
                                                >
                                                    <div className="row mb-1">
                                                        <div className="col-6 mb-3">
                                                            <label className="vsbox-info">
                                                                CPF
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("cpf") || "Não informado" }
                                                            </div>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <label className="vsbox-info">
                                                                RG
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("rg") || "Não informado" }
                                                            </div>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <label className="vsbox-info">
                                                                Data de nascimento
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("birthdate") ? dayjs(owner.get("birthdate")).format("DD/MM/YYYY") : "Não informado" }
                                                            </div>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <label className="vsbox-info">
                                                                Identidade Estrangeira
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("internationalId") || "Não informado" }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </VSAccordionSimple>
                                            </div>
                                        </div>
                                        <div className="vsbox-separator"></div>
                                        <div className="row">
                                            <div className="col-12">
                                                <VSAccordionSimple
                                                    expanded={true}
                                                    header={(expanded) => (
                                                        `${ expanded ? "Ocultar" : "Ver" } Informações de Contato`
                                                    )}
                                                >
                                                    <div className="row mb-1">
                                                        <div className="col-6 col-md-auto mb-3">
                                                            <label className="vsbox-info">
                                                                Celular
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("phone") || "Não informado" }
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-auto mb-3">
                                                            <label className="vsbox-info">
                                                                Telefone
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("landlinePhone") || "Não informado" }
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-auto">
                                                            <label className="vsbox-info">
                                                                E-mail
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("email") || "Não informado" }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </VSAccordionSimple>
                                            </div>
                                        </div>
                                        <div className="vsbox-separator"></div>
                                        <div className="row">
                                            <div className="col-12">
                                                <VSAccordionSimple
                                                    expanded={true}
                                                    header={(expanded) => (
                                                        `${ expanded ? "Ocultar" : "Ver" } Endereço do Cliente`
                                                    )}
                                                >
                                                    <div className="row">
                                                        <div className="col-12 mb-3">
                                                            <label className="vsbox-info">
                                                                CEP
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("zipCode") || "Não informado" }
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-3">
                                                            <label className="vsbox-info">
                                                                Endereço
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("addressStreet") || "Não informado" }
                                                            </div>
                                                        </div>
                                                        <div className="col-4 mb-3">
                                                            <label className="vsbox-info">
                                                                Número
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("addressNumber") || "Não informado" }
                                                            </div>
                                                        </div>
                                                        <div className="col-8 mb-3">
                                                            <label className="vsbox-info">
                                                                Complemento
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("addressComplement") || "Não informado" }
                                                            </div>
                                                        </div>
                                                        <div className="col-4 mb-3">
                                                            <label className="vsbox-info">
                                                                Estado
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("stateId") || "Não informado" }
                                                            </div>
                                                        </div>
                                                        <div className="col-8 mb-3">
                                                            <label className="vsbox-info">
                                                                Cidade
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("city") || "Não informado" }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col">
                                                            <label className="vsbox-info">
                                                                Bairro
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { owner.get("neighborhood") || "Não informado" }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </VSAccordionSimple>
                                            </div>
                                        </div>
                                        <div className="vsbox-separator"></div>
                                        <div className="row">
                                            <div className="col-12">
                                                <VSAccordionSimple
                                                    expanded={true}
                                                    header={(expanded) => (
                                                        `${ expanded ? "Ocultar" : "Ver" } Observações Internas do Cliente`
                                                    )}
                                                >
                                                    <div className="row mb-2">
                                                        <div className="col">
                                                            <div className="vsbox-info-text">
                                                                { owner.get("notes") || "Não informado" }
                                                            </div>
                                                            {owner.get("referredBy") && 
                                                            <div className="vsbox-info-text mt-3">
                                                                Conheceu a clínica via {owner.get("referredBy")}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </VSAccordionSimple>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { permissions?.check(ACL.PATIENT, ACL.Rule.VIEW) && !loadingOwner && owner && (
                                <div className="col-12 col-xl ps-xl-4">
                                    <CardViewSwitch 
                                        className="mb-3"
                                        expand={expandCards}
                                        setExpand={setExpandCards}
                                    />
                                    <OwnerViewPatient 
                                        clinic={clinic}
                                        owner={owner}
                                        expanded={expandCards}
                                        history={history}
                                        patientsData={patientsData}
                                        getPatients={getPatients}
                                    />
                                    <OwnerViewHistory
                                        clinic={clinic}
                                        owner={owner}
                                        expanded={expandCards}
                                        history={history}
                                        patientsData={patientsData}
                                        getPatients={getPatients}
                                    />
                                    <OwnerViewReminder
                                        clinic={clinic}
                                        owner={owner}
                                        expanded={expandCards}
                                        history={history}
                                        patientsData={patientsData}
                                        getPatients={getPatients}
                                    />
                                    <OwnerViewSale
                                        clinic={clinic}
                                        owner={owner}
                                        expanded={expandCards}
                                        history={history}
                                        patientsData={patientsData}
                                        getPatients={getPatients}
                                    />
                                    <OwnerViewEstimate
                                        clinic={clinic}
                                        owner={owner}
                                        expanded={expandCards}
                                        history={history}
                                        patientsData={patientsData}
                                        getPatients={getPatients}
                                    />
                                    <OwnerViewReceivables
                                        clinic={clinic}
                                        owner={owner}
                                        expanded={expandCards}
                                        history={history}
                                        patientsData={patientsData}
                                        getPatients={getPatients}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(OwnerView);