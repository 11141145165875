import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { CircularProgress, List, ListItem } from '@mui/material';
import CardProAdvertising from "../../components/cardProAdvertising/CardProAdvertising"
import NewsBox from './NewsBox';
import { APP_DEVICE, APP_NAME, APP_VERSION } from '../../constants';
import { ReactComponent as Logo } from "../../assets/vetsmart.svg"
import { ReactComponent as IconNews } from "../../assets/icon-news.svg"
import { ReactComponent as IconClinicManagement } from "../../assets/icon-clinic-management.svg"
import HomeLimit from '../home/HomeLimit';
import { saveOnTracker } from '../../utils';

import './News.scss'

const News = (props) => {

    const {
        features,
        user,
        clinic,
        history
    } = props

    const [ loading, setLoading ] = useState(true)
    const [ notificationNews, setNotificationNews ] = useState([])

    const getNotificationNews = () => {
        Parse.Cloud.run("getNotifications_v2", {
            type: "publicProntuario",
            channel: "prontuario_news",
            appVersionName: APP_VERSION,
            deviceType: "web",
            appContentType: APP_NAME
        })
        .then(notifications => {
            setNotificationNews(notifications.sort((a, b) => {
                return a.get('publishedAt') >= b.get('publishedAt') ? -1 : 1;
            }))
            setLoading(false)
        })
    }

    useEffect(() => {
        if(clinic && clinic.isLoaded && clinic.object){
            getNotificationNews()
        }
    }, [ clinic ])

    useEffect(() => {
        saveOnTracker("Acessou", "Novidades")
    }, [])

    return (
        <div id="news-page" className="row page-content">
            <div className="col-12">
                <div className="row align-items-start align-items-md-center justify-content-end mb-3 flex-column flex-md-row">
                    <div className="col mb-2 mb-md-0">
                        <div className="page-title text-nowrap">
                            Novidades do Prontuário
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-8">
                { loading ? (
                    <CircularProgress color="primary" />
                ) : (
                    <>
                        <div className="vsbox mb-3">
                            <div className="vsbox-news-head row align-items-start align-items-md-center flex-column flex-md-row gx-0 p-3">
                                <div className="col-auto col-md-3">
                                    <div className="news-vslogo">
                                        <Logo width="77px" height="14px" />
                                    </div>
                                </div>
                                <div className="col-7 col-md-6 mt-3 mt-md-0 mb-2 mb-md-0 ps-md-3">
                                    <div className="news-title">Viemos trazer boas notícias!</div>
                                </div>
                                <div className="col-3">
                                    <div className="news-icon">
                                        <IconNews />
                                    </div>
                                </div>
                            </div>
                            <div className="vsbox-body mb-0">
                                <p>A <b>Vetsmart</b> tem se esforçado para aprimorar suas ferramentas e garantir que <b>você tenha a melhor experiência possível</b>.</p>
                                <p className="mb-0">Nos últimos 3 meses <b>trouxemos atualizações que vão melhorar a sua rotina</b> usando nossa plataforma e gostaríamos de te atualizar sobre elas.</p>
                            </div>
                        </div>
                        {notificationNews && notificationNews.length > 0 &&
                        notificationNews.map((notification, index) => notification && (
                            <NewsBox
                                className="mb-3"
                                key={index}
                                object={notification}
                                expanded={true}
                            />
                        ))}
                    </>
                )}
            </div>
            <div className="col-12 col-md-4">
                <CardProAdvertising clinic={clinic} features={features} history={history} />

                { !features?.unlimited_messages ? (
                    <HomeLimit {...props} />
                ) : (
                    <div className="vsbox card-pro-advertising">
                        <div className="vsbox-body">
                            <div className="row g-3">
                                <div className="col-12">
                                    <IconClinicManagement />
                                </div>
                                <div className="col-12">
                                    <div className="vsbox-title mb-1">Gerencie os acessos da sua clínica</div>
                                    <div>Convide usuários para e gerencie os acessos permitidos e horários de atendimento de cada profissonal.</div>
                                </div>
                                <div className="col-12">
                                    <a 
                                        style={{ 
                                            cursor: "pointer",
                                            fontSize: "14px",
                                            fontWeight: 600
                                        }} 
                                        onClick={() => {
                                            history.push("/configuracao-clinica/gerenciar-acessos")
                                        }}
                                    >
                                        Acessar Gerenciamento de Acessos
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        // permissions: state.permissions,
        features: state.features,
    }
}

export default connect(mapStateToProps)(News)