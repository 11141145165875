import React, { useEffect, useState } from 'react'

import { ReactComponent as IconPatientAppointment } from '../../../../assets/icon-patient-appointment.svg'
import { ReactComponent as IconPrimaryEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconRecordDelete } from "../../../../assets/icon-virtual-assistant-anamnesis-record-delete.svg"
import { ReactComponent as IconSave } from "../../../../assets/icon-save.svg"
import { ReactComponent as IconRecordSaveConfirmation } from '../../../../assets/icon-virtual-assistant-anamnesis-save-confirmation.svg'
import { ReactComponent as IconRecordDeleteConfirmation } from '../../../../assets/icon-virtual-assistant-anamnesis-delete-confirmation.svg'

import VSEmpty from '../../../../components/vsEmpty/VSEmpty';

import { TextField } from '@mui/material';

const AppointmentFormAnamnesisFormatSaveConfirmation = ({ onConfirm, onCancel }) => (
    <div className="appointment-preview-confirmation blue">
        <div className="confirmation-image-wrap">
            <IconRecordSaveConfirmation />
        </div>
        <div className="confirmation-title mt-2">Tem certeza que deseja salvar essa organização?</div>
        <div className="confirmation-text mt-2">Ao salvar, os dados motivos da consulta ou anamnese serão substituídos e não poderão ser recuperados.</div>
        <button className={`vsbox-btn vsbox-btn-primary w-100 text-normal mt-3`} onClick={() => {
            onConfirm()
        }}>
            Salvar
        </button>
        <button className={`vsbox-btn vsbox-btn-warning w-100 text-normal mt-3`} onClick={() => {
            onCancel()
        }}>
            Cancelar
        </button>
    </div>
)

const AppointmentFormAnamnesisFormatDeleteConfirmation = ({ onDelete, onCancel }) => (
    <div className="appointment-preview-confirmation red">
        <div className="confirmation-image-wrap">
            <IconRecordDeleteConfirmation />
        </div>
        <div className="confirmation-title mt-2">Tem certeza que deseja excluir essa organização?</div>
        <div className="confirmation-text mt-2">Os dados não poderão ser recuperados e esta operação não poderá ser desfeita.</div>
        <button className={`vsbox-btn vsbox-btn-negative w-100 text-normal mt-3`} onClick={() => {
            onDelete()
        }}>
            Excluir
        </button>
        <button className={`vsbox-btn vsbox-btn-warning w-100 text-normal mt-3`} onClick={() => {
            onCancel()
        }}>
            Cancelar
        </button>
    </div>
)

const AppointmentFormAnamnesisFormatPreview = (props) => {

    const {
        aiCache,
        onSaveFormat,
        onClose
    } = props

    const response = aiCache?.get("response")
    const text = response?.text

    const sanitizeText = data => {
        if (typeof data !== 'string') {
            return ""
        }
        if (!data) {
            return ""
        }

        return data
                .replace(/[ ]{2,}/g, " ")
                .split('**').join('')
                .trim()
    }
    
    const [ editedText, setEditedText ] = useState(sanitizeText(text))
    const [ isEditingText, setIsEditingText ] = useState(false)
    const [ confirmationscreen, setConfirmationScreen ] = useState(null)

    // console.log('AppointmentFormAnamnesisFormatPreview', editedText)

    useEffect(() => {
        setEditedText(sanitizeText(text))
    }, [ aiCache ])

    useEffect(() => {
        const vsDrawerInfo = document.querySelector('.vsdrawer-info')
        if (vsDrawerInfo) {
            vsDrawerInfo.scrollTop = 0
        }
    }, [ confirmationscreen ])

    if(!response || Object.keys(response).length <= 0){
        return (
            <VSEmpty
                icon={<IconPatientAppointment width="32px" height="32px" />}
                title="Esta consulta ainda não tem nenhuma informação para organizar"
                text="Escreva alguma informação de anamnese e tente novamente"
                className="anamnesis-format-preview"
            >
            </VSEmpty>
        )
    }

    const renderText = data => {
        return sanitizeText(data)
                .split('\n')
                .map(line => <div className="anamnesis-format-text pt-2">{line}</div>)
    }

    const stickyButtonsBottomStyle = document.querySelector('.vsdrawer-footer')?.offsetHeight + 'px'

    return (
        <div className={`anamnesis-format-preview row flex-column align-items-end gx-0 ${confirmationscreen ? 'position-static' : ''}`}>
            {confirmationscreen}
            {/* <div className="col-12 ia-warning-text px-3 py-2">
                <IconSimpleWarning className="me-2" width="16" height="16" fill="#666666" />
                A IA pode produzir resultados imprecisos. <a className="link" href="https://pl-vetsmart.zendesk.com/hc/pt-br/articles/29652456409883-Assistente-Vetsmart" target="_blank">Saiba mais</a>.
            </div> */}
            <div className={`col-12 px-3 flex-grow-1 anamnesis-format-wrap ${false ? 'blur-elements' : ''}`}>
                {/* {hasDifferentialDiagnosisChanged && (
                    <div className="differential-diagnosis-changed-box">
                        <div>Novas informações foram incluídas na Anamnese</div>
                        <button className={`vsbox-btn vsbox-btn-primary w-100 mt-2 text-normal text-start`} onClick={() => {
                            getDifferentialDiagnosis()
                        }}>
                            <IconIAGenerate className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                            Gerar Novo Resultado
                        </button>
                    </div>
                )} */}
                <div className="anamnesis-format-title">Anamnese organizada</div>

                {isEditingText ? (
                    <>
                        <TextField
                            className="mt-4"
                            placeholder="Anamnese"
                            value={editedText}
                            onChange={({ target: { value } }) => setEditedText(sanitizeText(value))}
                            size="small"
                            variant="outlined"
                            multiline
                            fullWidth
                        />
                        <div 
                            className="row pt-4 flex-grow-1 align-items-end gx-md-5 gx-4"
                            style={{
                                position: 'sticky',
                                bottom: stickyButtonsBottomStyle
                            }}
                        >
                            <div className="appointment-fixed-buttons col-12 py-3">
                                <div className="row">
                                    <div className="col-6 pe-0">
                                        <button className={`vsbox-btn vsbox-btn-warning px-0 w-100 text-normal`} onClick={() => {
                                            setEditedText(sanitizeText(text))
                                            setIsEditingText(false)
                                        }}>
                                            Cancelar edição
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button className={`vsbox-btn vsbox-btn-save px-0 w-100 text-normal`} onClick={() => {
                                            // onSaveFormat(editedText)
                                            setIsEditingText(false)
                                        }}>
                                            Salvar edição
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {renderText(editedText)}
                        <div 
                            className="row pt-4 flex-grow-1 align-items-end gx-md-5 gx-4"
                            style={{
                                position: 'sticky',
                                bottom: stickyButtonsBottomStyle
                            }}
                        >
                            <div className="appointment-fixed-buttons col-12 py-3">
                                <div className="row">
                                    <div className="col pe-0">
                                        <button className={`vsbox-btn vsbox-btn-dark px-0 w-100 text-normal`} onClick={() => {
                                            setIsEditingText(true)
                                        }}>
                                            <IconPrimaryEdit className="me-2" width="24" height="24" />
                                            Editar
                                        </button>
                                    </div>
                                    <div className="col pe-0">
                                        <button className={`vsbox-btn vsbox-btn-save px-0 w-100 text-normal`} onClick={() => {
                                            setConfirmationScreen(
                                                <AppointmentFormAnamnesisFormatSaveConfirmation
                                                    onConfirm={() => {
                                                        onSaveFormat(editedText)
                                                        setConfirmationScreen(null)
                                                    }}
                                                    onCancel={() => setConfirmationScreen(null)}
                                                />
                                            )
                                        }}>
                                            <IconSave className="me-2" width="24" height="24" fill="#ffffff" />
                                            Salvar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button className={`vsbox-btn vsbox-btn-warning px-2 w-100 text-normal`} onClick={() => {
                                            setConfirmationScreen(
                                                <AppointmentFormAnamnesisFormatDeleteConfirmation
                                                    onDelete={() => {
                                                        onClose()
                                                        setConfirmationScreen(null)
                                                    }}
                                                    onCancel={() => setConfirmationScreen(null)}
                                                />
                                            )
                                        }}>
                                            <IconRecordDelete width="24" height="24" fill="var(--vsprontuario-warning-color)" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default AppointmentFormAnamnesisFormatPreview