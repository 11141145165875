import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import { ReactComponent as IconHeaderIA } from "../../../../assets/icon-header-ia.svg"

import { addEvent, dayjs, saveOnTracker, useQuery } from '../../../../utils'
import { getPatient } from '../../Patient'
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';
import { appointmentTypeOptions, getAppointment, CONSULTA_DETALHADA, CONSULTA_PADRAO } from './Appointment';

import VSRadio from '../../../../components/vsRadio/VSRadio';
import DateInput from '../../../../components/dateInput/DateInput';
import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit';
import { PatientQueryToggle } from '../../PatientWideInfo';
import VSError from '../../../../components/vsError/VSError';
import AppointmentFormVirtualAssistantPreview from './AppointmentFormVirtualAssistantPreview';
import VirtualAssistantDrawerInfoFreePreview from '../../../virtualAssistant/VirtualAssistantDrawerInfoFreePreview';
import VirtualAssistantDrawerInfoLegacyPreview from '../../../virtualAssistant/VirtualAssistantDrawerInfoLegacyPreview';

import './AppointmentForm.scss'

export const PARAM_AI_DIFFERENTIAL_DIAGNOSIS = "diagnosticoDiferencial"
export const PARAM_AI_ANAMNESIS_RECORD = "gravarAnamnese"
export const PARAM_AI_ANAMNESIS_FORMAT = "organizarAnamnese"

const AppointmentForm = (props) => {

    const {
        id,
        id_consulta
    } = useParams()

    const params = useQuery()

    const {
        history,
        clinic,
        permissions,
        features
    } = props

    const update = id_consulta !== "novo"
    const drawerInfoRef = useRef(null)
    const AppointmentFormVirtualAssistantPreviewRef = useRef(null)
    const [ cachedRef, setCachedRef ] = useState(null)

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ patient, setPatient ] = useState(null)
    const [ appointment, setAppointment ] = useState(null)

    const [ appointmentType, setAppointmentType ] = useState(CONSULTA_PADRAO)

    const [ _return, set_Return ] = useState('0')
    const [ anamnesis, setAnamnesis ] = useState('')
    const [ physicalExam, setPhysicalExam ] = useState('')
    const [ diagnosis, setDiagnosis ] = useState('')
    const [ treatment, setTreatment ] = useState('')
    const [ nextStep, setNextStep ] = useState('')

    const defaultDate = dayjs()
    const [ documentDate, setDocumentDate ] = useState(defaultDate.toDate())
    const [ documentDateText, setDocumentDateText ] = useState(defaultDate.format("DD/MM/YYYY"))
    const [ errorDocumentDate, setErrorDocumentDate ] = useState(false)
    const [ helperDocumentDate, setHelperDocumentDate ] = useState(<span>Campo <b>Obrigatório</b></span>)
    const [ notes, setNotes ] = useState('')

    const [ differentialDiagnosis, setDifferentialDiagnosis ] = useState([])
    const [ hasDrawerInfoForAI, setHasDrawerInfoForAI ] = useState(
        features?.differential_diagnosis && params.get(PARAM_AI_DIFFERENTIAL_DIAGNOSIS) !== null || 
        features?.appointment_text_format && params.get(PARAM_AI_ANAMNESIS_FORMAT) !== null || 
        features?.audio_transcription && params.get(PARAM_AI_ANAMNESIS_RECORD) !== null
    )
    const [ AIButtonOnHover, setAIButtonOnHover ] = useState(false)
    const [ hasGeneratedAI, setHasGeneratedAI ] = useState(false)
    
    const handleDocumentDate = (_date) => {
        setDocumentDateText(_date)

        if (_date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g)) {
            let newDate = dayjs(_date, "DD/MM/YYYY")
            if (newDate.isValid()) {
                setDocumentDate(newDate.toDate())
                return
            }
        }
        setDocumentDate(null)
    }

    const validateOnTouch = () => {
        if (hasSubmited && (documentDate || documentDateText || notes)) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (error?.message) {
            isValid = false
        }

        const _documentDate = dayjs(documentDateText, "DD/MM/YYYY")
        if (_documentDate.isValid()) {
            if (_documentDate.format("YYYY") < 1971) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode ser menor que 1971")
            } else if (_documentDate.diff(dayjs(), "years") > 5) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode exceder 5 anos")
            } else {
                setErrorDocumentDate(false)
                setHelperDocumentDate(<span>Campo <b>Obrigatório</b></span>)
            }
        } else {
            isValid = false
            setErrorDocumentDate(true)
            setHelperDocumentDate("A data informada não é válida")
        }

        return isValid
    }

    const isValid = () => !loading && !errorDocumentDate

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Salvando a consulta")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorDocumentDate) {
            errors.push("Data da consulta")
        }
        if (!permissions?.check(ACL.APPOINTMENT, ACL.Rule.EDIT, appointment)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id, clinic.object)
        .then(_patient => {
            setPatient(_patient)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getPatientOrError"
                })
            })
        })
    }

    const getAppointmentOrError = () => {
        if (permissions?.check(ACL.APPOINTMENT, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", "Consulta", update ? id_consulta : "")
            if (update) {
                setLoading(true)
                setError(null)
    
                getAppointment(id_consulta, patient, true)
                .then(_appointment => {
                    setAppointment(_appointment)
                    setDocumentDate(_appointment.get("documentDate"))
                    setDocumentDateText(dayjs(_appointment.get("documentDate")).format("DD/MM/YYYY"))
                    setAppointmentType(_appointment.get("appointmentType") ? _appointment.get("appointmentType") : CONSULTA_PADRAO)
                    setAnamnesis(_appointment.get("anamnesis"))
                    set_Return(_appointment.get("return") ? "1" : "0")
                    setPhysicalExam(_appointment.get("physicalExam"))
                    setDiagnosis(_appointment.get("diagnosis"))
                    setTreatment(_appointment.get("treatment"))
                    setNextStep(_appointment.get("nextStep"))
                    setNotes(_appointment.get("notes"))
                    setDifferentialDiagnosis(_appointment.get("differentialDiagnosis") || [])
                    setLoading(false)

                    if(features?.differential_diagnosis && _appointment.get("differentialDiagnosis") && _appointment.get("differentialDiagnosis").length > 0){
                        setHasDrawerInfoForAI(true)
                    }
                })
                .catch(error => {
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getAppointmentOrError"
                        })
                    })
                })
            } else {
                let newAppointment = new Parse.Object("Appointment")
                newAppointment.setACL(clinic.ACL)
                newAppointment.set("clinic", clinic.object)
                newAppointment.set("createdBy", Parse.User.current())
                newAppointment.set("createdApp", APP_NAME)
                newAppointment.set("createdDevice", APP_DEVICE)
                newAppointment.set("createdAppVersion", APP_VERSION)
                newAppointment.set("isDeleted", false)
                newAppointment.set("patient", patient)
                setAppointment(newAppointment)
                setLoading(false)
            }
        }
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (validate()) {
                let promise = Promise.resolve()
                if(features?.differential_diagnosis){
                    promise = promise.then(() => {
                        return AppointmentFormVirtualAssistantPreviewRef?.current?.onSave().then(() => {
                            setLoading(true)
                        })
                    })
                }
                
                promise.then(() => {
                    appointment.set('documentDate', documentDate);
                    appointment.set('appointmentType', appointmentType);
                    appointment.set('anamnesis', anamnesis);
                    appointment.set('return', _return == "1");
                    appointment.set('physicalExam', physicalExam);
                    appointment.set('diagnosis', diagnosis);
                    appointment.set('treatment', treatment);
                    appointment.set('nextStep', nextStep);
                    appointment.set('notes', notes);
                    if(differentialDiagnosis && differentialDiagnosis.length > 0){
                        appointment.set('differentialDiagnosis', differentialDiagnosis);
                    }
                    appointment.set("changedAt", new Date())
                    appointment.set("changedBy", Parse.User.current())
                    appointment.set("changedApp", APP_NAME)
                    appointment.set("changedDevice", APP_DEVICE)
                    appointment.set("changedAppVersion", APP_VERSION)
                    appointment.save()
                    .then(_appointment => {
                        saveOnTracker("Concluiu", "Consulta", _appointment.id)
                        addEvent("TourAppointment__registerYourFirstAppointment")
                        resolve(_appointment)
                    })
                    .catch(error => {
                        setLoading(false)
                        console.error(error)
                        Swal.fire(
                            'Desculpe',
                            `Ocorreu algum erro ao tentar ${appointment.id ? "editar" : "cadastrar"} a consulta do animal`,
                            'error'
                        )
                        update && appointment.revert()
                        reject()
                    })
                })
            } else {
                reject()
            }
        })
    }

    useEffect(() => {
        validateOnTouch()
    }, [ documentDate, documentDateText, appointmentType, anamnesis, _return, physicalExam, diagnosis, treatment, nextStep, notes ])

    useEffect(() => {
        if (appointment && !permissions?.check(ACL.APPOINTMENT, ACL.Rule.EDIT, appointment)) {
            permissions?.error()
        }
    }, [ appointment ])
    
    useEffect(() => {
        if (patient) {
            getAppointmentOrError()
        }
    }, [ patient ])

    useEffect(() => {
        drawerInfoRef?.current?.onOpen(hasDrawerInfoForAI)
    }, [ hasDrawerInfoForAI ])

    useEffect(() => {
        if (id) {
            getPatientOrError()
        }
    }, [])

    const errors = fieldsWithErrorText()
    
    return (
        <VSDrawer
            id="appointment-form" 
            width={hasDrawerInfoForAI ? "80%" : "60%"}
            title={update ? "Editar Consulta" : "Nova Consulta"}
            cancel={update ? <span>Cancelar Registro<span className="d-none d-md-inline"> de Consulta</span></span> : <span>Cancelar Registro<span className="d-none d-md-inline"> de Nova Consulta</span></span>}
            submit={isValid ? (update ? <span>Salvar Alterações<span className="d-none d-md-inline"> na Consulta</span></span> : <span>Salvar Registro<span className="d-none d-md-inline"> de Nova Consulta</span></span>) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.replace(`/animal/${patient.id}/consulta`)}
            withVSDrawerInfo={hasDrawerInfoForAI}
            onAfterSave={(object) => {
                history.replace(`/animal/${patient.id}/consulta`)
                addEvent("Appointment__getList", {})
            }}
            VSDrawerSuccessProps={{
                title: "Consulta salva com Sucesso",
                text: ""
            }}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar o registro de consulta?",
                confirm: "Cancelar Registro de Consulta",
                cancel: "Voltar para Edição"
            }}
        >
            <PatientQueryToggle
                patient={patient}
                onClick={() => history.push(`/animal/selecionar/consulta`)}
            />
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            case "getAppointmentOrError":
                                getAppointmentOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <div className="row">
                    <div className="col">
                        <div className="row align-items-center mb-3">
                            <div className="col-12 col-xl">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            Tipo de Consulta
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <VSRadio
                                            horizontal={true}
                                            options={appointmentTypeOptions}
                                            value={appointmentType}
                                            onChange={(_, value) => {
                                                setAppointmentType(value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            É retorno?
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <VSRadio
                                            horizontal={true}
                                            options={[
                                                {
                                                    label: "Sim",
                                                    value: "1"
                                                },
                                                {
                                                    label: "Não",
                                                    value: "0"
                                                }
                                            ]}
                                            value={_return}
                                            onChange={(_, value) => {
                                                set_Return(value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-auto-xl" style={{ width: '200px' }}>
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            Data
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <DateInput
                                            className="m-0"
                                            placeholder="00/00/0000"
                                            value={documentDateText || null}
                                            error={errorDocumentDate}
                                            helperText={null}
                                            onChange={(value) => handleDocumentDate(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </div>
                            { errorDocumentDate && (
                                <div className="col-12 text-right">
                                    <small className="text-danger">{ helperDocumentDate }</small>
                                </div>
                            )}
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Motivo/Anamnese
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={anamnesis}
                                    placeholder="Escreva o motivo/anamnese aqui"
                                    onChange={({ target: { value } }) => setAnamnesis(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                        { appointmentType == CONSULTA_DETALHADA && (
                            <>
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            Exame Físico
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            value={physicalExam}
                                            placeholder="Escreva os detalhes do exame físico aqui"
                                            onChange={({ target: { value } }) => setPhysicalExam(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            Diagnóstico
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            value={diagnosis}
                                            placeholder="Escreva o diagnóstico aqui"
                                            onChange={({ target: { value } }) => setDiagnosis(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            Tratamento
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            value={treatment}
                                            placeholder="Escreva o tratamento aqui"
                                            onChange={({ target: { value } }) => setTreatment(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            Próximos Passos
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            value={nextStep}
                                            placeholder="Escreva os próximos passos aqui aqui"
                                            onChange={({ target: { value } }) => setNextStep(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="row">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Observações Internas
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={notes}
                                    placeholder="Escreva suas observações aqui"
                                    onChange={({ target: { value } }) => setNotes(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col input-info">
                                As observações são anotações particulares. <b>Elas nunca serão compartilhadas com o tutor e também não são usadas para gerar um Diagnóstico Diferencial</b>
                            </div>
                        </div>
                        <button
                            className={`btn-differential-diagnosis mb-3 ${hasGeneratedAI ? "active" : ""}`}
                            onClick={_ => {
                                setHasDrawerInfoForAI(!hasDrawerInfoForAI)
                                if(window.innerWidth > 768 || !hasDrawerInfoForAI){
                                    saveOnTracker("Clicou", "Assistente", "Assistente Vetsmart", hasDrawerInfoForAI ? "Fechou" : "Abriu", "Consulta", null, null, {
                                        appointmentId: appointment?.id ? appointment?.id : 'novo',
                                        patientId: patient?.id || patient?.objectId
                                    })
                                }
                            }}
                            onMouseEnter={() => setAIButtonOnHover(true)}
                            onMouseLeave={() => setAIButtonOnHover(false)}
                        >
                            <IconHeaderIA width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                            <span className={AIButtonOnHover ? `ms-2` : `ms-0`}>Assistente Vetsmart</span>
                        </button>
                        <VSMedicalRecordsAudit parseObject={appointment} />
                    </div>
                    {features?.ai ? (
                        <AppointmentFormVirtualAssistantPreview 
                            ref={AppointmentFormVirtualAssistantPreviewRef}
                            clinic={clinic}
                            patient={patient}
                            appointment={appointment}
                            documentDate={documentDate}
                            anamnesis={anamnesis}
                            onSaveAnamnesis={(_anamnesis, replace) => setAnamnesis(
                                (!replace && anamnesis ? anamnesis + '\n' : '') + _anamnesis
                            )}
                            appointmentType={appointmentType}
                            physicalExam={physicalExam}
                            diagnosis={diagnosis}
                            treatment={treatment}
                            nextStep={nextStep}
                            hasDrawerInfoForAI={hasDrawerInfoForAI}
                            setHasDrawerInfoForAI={_hasDrawerInfoForAI => {
                                setHasDrawerInfoForAI(_hasDrawerInfoForAI)
                                history.replace({
                                    search: "" // remove openwith from url when close
                                })
                            }}
                            hasGeneratedAI={hasGeneratedAI}
                            setHasGeneratedAI={setHasGeneratedAI}
                            differentialDiagnosisList={differentialDiagnosis}
                            setDifferentialDiagnosisList={setDifferentialDiagnosis}
                            openWith={
                                params.get(PARAM_AI_DIFFERENTIAL_DIAGNOSIS) !== null || differentialDiagnosis && differentialDiagnosis.length > 0 ? 
                                PARAM_AI_DIFFERENTIAL_DIAGNOSIS : 
                                params.get(PARAM_AI_ANAMNESIS_FORMAT) !== null && anamnesis ? PARAM_AI_ANAMNESIS_FORMAT :
                                params.get(PARAM_AI_ANAMNESIS_RECORD) !== null ? PARAM_AI_ANAMNESIS_RECORD :
                                null
                            }
                            drawerInfoRef={drawerInfoRef}
                        />
                    ) : features?.isLegacy ? (
                        <VirtualAssistantDrawerInfoLegacyPreview
                            clinic={clinic}
                            features={features}
                            history={history}
                            hasDrawerInfoForAI={hasDrawerInfoForAI}
                            setHasDrawerInfoForAI={setHasDrawerInfoForAI}
                            drawerInfoRef={drawerInfoRef}
                        />
                    ) : (
                        <VirtualAssistantDrawerInfoFreePreview
                            clinic={clinic}
                            features={features}
                            history={history}
                            hasDrawerInfoForAI={hasDrawerInfoForAI}
                            setHasDrawerInfoForAI={setHasDrawerInfoForAI}
                            drawerInfoRef={drawerInfoRef}
                        />
                    )}
                </div>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions,
        features: state.features
    };
};
  
export default connect(mapStateToProps)(AppointmentForm)