import React, { useState } from "react"
import { connect } from "react-redux"

import { ReactComponent as IconAdd } from "../../assets/icon-add.svg"
import { ReactComponent as IconPrimaryUserO } from "../../assets/icon-primary-user-o.svg"
import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"
import BoxOwnerPatient from "./BoxOwnerPatient"
import HealthTag from "../health/HealthTag"

import { CircularProgress } from "@mui/material"
import { ACL } from "../../constants"

import "./BoxOwner.scss"
const BoxOwner = (props) => {

    const {
        owner,
        clinic,
        history,
        actions,
        onNewPatient,
        onSelectPatient,
        boxPatientProps,
        showAll = false,
        hideIcon,
        hideContactInfo,
        hideAnimals,
        permissions,
        handleOwnerPatients
    } = props
    
    const [ patients, setPatients ] = useState(null)
    const [ loadingPatients, setLoadingPatients ] = useState(true)

    const getPatients = (owner) => {
        if (!patients) {
            setLoadingPatients(true)
    
            var queryPatients = new Parse.Query("Patient")
            queryPatients.equalTo("isDeleted", false)
            queryPatients.equalTo("clinic", clinic.object)
            queryPatients.equalTo("owner", owner)
            queryPatients.find()
            .then(_patients => {
                if(handleOwnerPatients){
                    handleOwnerPatients(_patients)
                }
                
                const moreTwo = _patients.length - 2
                if (!showAll && moreTwo > 0) {
                    _patients.length = 2
                    _patients.push({
                        total: moreTwo
                    })
                }
                setPatients(_patients)
                setLoadingPatients(false)
            })
            .catch(error => {
                setLoadingPatients(false)
            })
        }
    }

    return (
        <div id="box-owner" className="vsbox">
            <div className="vsbox-body pb-1">
                <div className="row align-items-md-center align-items-start mb-3 flex-md-row flex-column">
                    { !hideIcon && (
                        <div className="col-auto mb-md-2 mb-lg-0 pe-0">
                            <div className="card-icon">
                                <IconPrimaryUserO width="55" height="55" />
                            </div>
                        </div>
                    )}
                    <div className="col px-2 mb-md-2 mb-lg-0 ms-1">
                        <div className="row align-items-center">
                            <div className="col-12">
                                { owner?.get("petloveSaudeId") && (
                                    <div className="row mb-1">
                                        <div className="col-auto">
                                            <HealthTag />
                                        </div>
                                    </div>
                                )}
                                <h5
                                    className="vsbox-title vsbox-link mb-1"
                                    onClick={() => history.push(`/tutor/${ owner.id }`)}
                                >
                                    { owner.get("name") }
                                </h5>
                                <div className="vsbox-text">
                                    CPF { owner.get("cpf") || "NÃO INFORMADO" }
                                    { " - " }
                                    RG { owner.get("rg") || "NÃO INFORMADO" }
                                </div>
                            </div>
                        </div>
                    </div>
                    {actions &&
                    <div className="col-auto mt-3 mt-md-0 align-self-stretch align-self-md-center">
                        <div className="row align-items-center">
                            { actions }
                        </div>
                    </div>}
                </div>
                { !hideContactInfo && (
                    <>
                        <div className="vsbox-separator"></div>
                        <div className="row">
                            <div className="col-12 vsbox--info">
                                <VSAccordionSimple
                                    header={(expanded) => (
                                        `${ expanded ? "Ocultar" : "Ver" } Informações de Contato`
                                    )}
                                >
                                    <div className="row mb-3">
                                        <div className="col-6 col-md-auto">
                                            <label className="vsbox-info">
                                                Celular
                                            </label>
                                            <div className="vsbox-info-text">
                                                { owner.get("phone") || "Não informado" }
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-auto">
                                            <label className="vsbox-info">
                                                Telefone
                                            </label>
                                            <div className="vsbox-info-text">
                                                { owner.get("landlinePhone") || "Não informado" }
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-auto">
                                            <label className="vsbox-info">
                                                E-mail
                                            </label>
                                            <div className="vsbox-info-text">
                                                { owner.get("email") || "Não informado" }
                                            </div>
                                        </div>
                                    </div>
                                </VSAccordionSimple>
                            </div>
                        </div>
                    </>
                )}
                { !hideAnimals && permissions?.check(ACL.PATIENT, ACL.Rule.VIEW) && (
                    <>
                        <div className="vsbox-separator"></div>
                        <div className="row">
                            <div className="col-12 vsbox--info">
                                <VSAccordionSimple
                                    header={(expanded) => (
                                        `${ expanded ? "Ocultar" : "Ver" } Animais do(a) Tutor(a)`
                                    )}
                                    onOpen={() => getPatients(owner)}
                                >
                                    { loadingPatients ? (
                                        <div className="data-loading">
                                            <CircularProgress color="secondary" />
                                        </div>
                                    ) : (
                                        <div className="row mb-3 px-2 flex-column flex-md-row">
                                            { patients && patients.length > 0 && (
                                                <div className={`patients-wrap col ${patients.length == 1 ? 'patient-1' : (patients.length == 2 || showAll ? 'patient-2' : 'patient-many')}`}>
                                                    <div className="row g-3 flex-column flex-md-row">
                                                        { patients.map((patient, index) => (
                                                            !showAll && patient.total ? (
                                                                <div className="col-auto" key={index}>
                                                                    <div
                                                                        className={`d-table box-total ${boxPatientProps && boxPatientProps.noAction ? "no-action" : ""}`}
                                                                        onClick={() => (!boxPatientProps || !boxPatientProps.noAction) && history.push(`/tutor/${ owner.id }`)}
                                                                    >
                                                                        <div className="d-table-cell align-middle">
                                                                            +{ patient.total }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className={`col`} style={{ minWidth: "256px" }} key={index}>
                                                                    <BoxOwnerPatient
                                                                        patient={patient}
                                                                        history={history}
                                                                        onSelected={onSelectPatient}
                                                                        {...boxPatientProps}
                                                                    />
                                                                </div>
                                                            )
                                                        )) }
                                                    </div>
                                                </div>
                                            )}
                                            { (!boxPatientProps || !boxPatientProps.noAction) && permissions?.check(ACL.PATIENT, ACL.Rule.EDIT) && (
                                                <div className="col-auto px-2 mt-4 mt-md-0">
                                                    <button
                                                        className="btn-dashed h-100"
                                                        onClick={() => onNewPatient ? onNewPatient() : history.push(`/tutor/${owner.id}/animal/novo`)}
                                                    >
                                                        <IconAdd width="20px" height="20px" />
                                                        Cadastrar Novo Animal
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </VSAccordionSimple>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(BoxOwner);