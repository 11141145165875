import { ReactComponent as Step1 } from "../../assets/health/step1.svg"
import { ReactComponent as Step2 } from "../../assets/health/step2.svg"
import { ReactComponent as Step3 } from "../../assets/health/step3.svg"
import { ReactComponent as Step4 } from "../../assets/health/step4.svg"
import { ReactComponent as Step5 } from "../../assets/health/step5.svg"
import { ReactComponent as Step6 } from "../../assets/health/step6.svg"

export const steps = [
    {
        image: <Step1 />,
        title: "Bem-vindo ao Onboarding do Prontuário PRO",
        text: (
            <>
                Olá! Estamos felizes por você ter escolhido o <b>Prontuário PRO da Vetsmart</b>. Aqui, você aprenderá como maximizar sua eficiência no gerenciamento, atualização de cadastros e obter informações sobre a elegibilidade do plano de saúde Petlove.<br/><br/>
                
                Ao final deste processo, você estará pronto para:<br/><br/>
                <ul>
                    <li>
                        <b>Importar cadastros de tutores e animais</b>, direto da base do Plano de Saúde da Petlove;
                    </li>
                    <li>
                        <b>Unificar e atualizar cadastros existentes</b>, garantindo registros sempre precisos;
                    </li>
                    <li>
                        <b>Consultar o status do plano de saúde Petlove</b> dos animais sem sair da plataforma Vetsmart;
                    </li>
                </ul>
                Vamos ao passo a passo!
            </>
        ),
        submit: "Ok, Vamos lá"
    },
    {
        image: <Step2 />,
        title: "Importar dados como novo cadastro",
        text: (
            <>
                No Prontuário Vetsmart, você pode criar um novo cadastro usado os dados de cadastro da base do Plano de Saúde Petlove:<br/><br/>

                <ol>
                    <li>
                        Na lista de tutores / animais, clique em <b>Cadastrar Novo Tutor / Animal</b>
                    </li>
                    <li>
                        <b>Preencha o campo de CPF / Microchip</b> no formulário de cadastro
                    </li>
                    <li>
                        Caso exista uma cadastro na base do Plano de Saúde Petlove com esses dados, <b>mostraremos um indicador com a opção de importar os dados</b>
                    </li>
                </ol>
                Também é possível iniciar um novo cadastro através da ação de Adicionar Cadastro no cabeçalho (representada pelo ícone de + no acesso pelo celular)
            </>
        ),
        submit: "Continuar"
    },
    {
        image: <Step3 />,
        title: "Atualizar dados de cadastro existentes",
        text: (
            <>
                No Prontuário Vetsmart, iremos identificar quando um cadastro já existente também possuem um registro na base do Plano de Saúde Petlove. Nesses caso, você terá a opção de atualizar o cadastro existente no prontuário com essas informações<br/><br/>

                <ol>
                    <li>
                        No perfil do Tutor ou do Animal existirá a mensagem ”Identificamos informações atualizadas sobre o cadastro na base do Plano de Saúde Petlove. ”Atualizar cadastro”.
                    </li>
                    <li>
                        Iremos identificar como ”Informações divergentes” os campos que tiverem dados diferentes entre o cadastro no Prontuário Vetsmart e o cadastro na base do Plano de Saúde Petlove
                    </li>
                    <li>
                        Selecione qual das opções de registro você deseja manter no cadastro do tutor / pet
                    </li>
                </ol>
            </>
        ),
        submit: "Continuar"
    },
    {
        image: <Step4 />,
        title: "Importação de dados de tutor",
        text: (
            <>
                No caso da importação de dados de tutor tanto para um novo cadastro como para atualização de um cadastro existente, também iremos identificar os animais registrados para esse tutor na base do Plano de Saúde Petlove. Nesses casos você poderá<br/><br/>

                <ul>
                    <li>
                        Importar os dados do animal da base do Plano de Saúde Petlove como um novo cadastro
                    </li>
                    <li>
                        Unificar os dados do dados do animal da base do Plano de Saúde Petlove com um registro de animal já existente
                    </li>
                    <li>
                        Não importar os dados do animal
                    </li>
                </ul>
            </>
        ),
        submit: "Continuar"
    },
    {
        image: <Step5 />,
        title: "Revisão dos Dados",
        text: (
            <>
                Após importar ou unificar os cadastros, revise os dados apresentados e confirme as alterações.<br/><br/>

                <b>Observação Importante:</b><br/>
                As atualizações realizadas <b>são restritas aos dados cadastrais</b>. O histórico de registros clínicos dos animais no estabelecimento <b>não será alterado e o histórico de atendimentos na base do Plano de Saúde Petlove não será importado</b>
            </>
        ),
        submit: "Continuar"
    },
    {
        image: <Step6 />,
        title: "Consultando o Status do Plano de Saúde Petlove",
        text: (
            <>
                Dentro do perfil do animal, você terá acesso direto ao <b>status do plano de saúde</b>, sem a necessidade de navegar para outra tela.<br/><br/>

                No perfil, será exibido um aviso sobre o status do plano, acompanhado de um botão <b>"Ver Detalhes"</b>. Ao clicar no botão, você poderá ver informações como:  <b className="text-primary">Coberturas do plano</b>;  <b className="text-primary">Limites disponíveis e utilizados</b>;  <b className="text-primary">Coparticipações para cada tipo de procedimento</b>;  <b className="text-primary">Período de carência</b>.<br/><br/>

                <b>Não é preciso importar um cadastro da base do Plano de Saúde para ter acesso a essas informações</b>. Iremos identificar automaticamente os cadastros de animais que possuem o plano de saúde Petlove ativo! <br/><br/>

                <span className="text-secondary">Otimize seu fluxo de trabalho, oferecendo um atendimento ainda mais ágil e eficiente</span>. Clique em <b>"Começar"</b> para explorar! 
            </>
        ),
        submit: "Começar!"
    }
]