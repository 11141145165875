import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';

import { CircularProgress } from '@mui/material';
import TextFieldMask from '../../components/textFieldMask/TextFieldMask';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Autocomplete } from "@mui/lab";

import {
    ACL
} from '../../constants'
import { 
    getStateList,
    validateCPF,
    validateEmail,
    validatePhone,
    validatePostalCode,
    getAddressByZipCode,
    validateDate,
    dayjs,
    optionalField
} from '../../utils'

import VSDrawer from '../../components/vsDrawer/VSDrawer';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import { setHealthImportOwner } from '../../store/actions';

var currentZipCode = null

import './HealthOwnerForm.scss'
const HealthOwnerForm = (props) => {

    const {
        health,
        history,
        permissions
    } = props

    const divergenceHelper = "Informações divergentes"

    const isMerge = health?.vetsmart

    const [ name, setName ] = useState("")
    const [ nameOptions, setNameOptions ] = useState([])
    const [ errorName, setErrorName ] = useState(false)
    const [ divergenceName, setDivergenceName ] = useState(false)
    const [ helperName, setHelperName ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ cpf, setCpf ] = useState('')
    const [ cpfOptions, setCpfOptions ] = useState([])
    const [ errorCpf, setErrorCpf ] = useState(false)
    const [ divergenceCpf, setDivergenceCpf ] = useState(false)
    const [ helperCpf, setHelperCpf ] = useState(optionalField)

    const [ email, setEmail ] = useState('')
    const [ emailOptions, setEmailOptions ] = useState([])
    const [ errorEmail, setErrorEmail ] = useState(false)
    const [ divergenceEmail, setDivergenceEmail ] = useState(false)
    const [ helperEmail, setHelperEmail ] = useState(optionalField)

    const [ landlinePhone, setLandlinePhone ] = useState('')
    const [ landlinePhoneOptions, setLandlinePhoneOptions ] = useState([])
    const [ errorLandlinePhone, setErrorLandlinePhone ] = useState(false)
    const [ divergenceLandlinePhone, setDivergenceLandlinePhone ] = useState(false)
    const [ helperLandlinePhone, setHelperLandlinePhone ] = useState(optionalField)

    const [ phone, setPhone ] = useState('')
    const [ phoneOptions, setPhoneOptions ] = useState([])
    const [ errorPhone, setErrorPhone ] = useState(false)
    const [ divergencePhone, setDivergencePhone ] = useState(false)
    const [ helperPhone, setHelperPhone ] = useState(optionalField)

    const [ zipCode, setZipCode ] = useState('')
    const [ zipCodeOptions, setZipCodeOptions ] = useState([])
    const [ loadingZipCode, setLoadingZipCode ] = useState(false)
    const [ errorZipCode, setErrorZipCode ] = useState(false)
    const [ divergenceZipCode, setDivergenceZipCode ] = useState(false)
    const [ helperZipCode, setHelperZipCode ] = useState(optionalField)

    const [ birthdate, setBirthdate ] = useState(null)
    const [ birthdateOptions, setBirthdateOptions ] = useState([])
    const [ errorBirthdate, setErrorBirthdate ] = useState(false)
    const [ helperBirthdate, setHelperBirthdate ] = useState(optionalField)
    const [ divergenceBirthdate, setDivergenceBirthdate ] = useState(false)
    const [ birthdateText, setBirthdateText ] = useState('')

    const [ rg, setRg ] = useState('')
    const [ internationalId, setInternationalId ] = useState('')
    const [ address, setAddress ] = useState('')
    const [ addressOptions, setAddressOptions ] = useState([])
    const [ divergenceAddress, setDivergenceAddress ] = useState(false)
    const [ addressNumber, setAddressNumber ] = useState('')
    const [ addressNumberOptions, setAddressNumberOptions ] = useState([])
    const [ divergenceAddressNumber, setDivergenceAddressNumber ] = useState(false)
    const [ addressComplement, setAddressComplement ] = useState('')
    const [ addressComplementOptions, setAddressComplementOptions ] = useState([])
    const [ divergenceAddressComplement, setDivergenceAddressComplement ] = useState(false)
    const [ neighborhood, setNeighborhood ] = useState('')
    const [ neighborhoodOptions, setNeighborhoodOptions ] = useState([])
    const [ divergenceNeighborhood, setDivergenceNeighborhood ] = useState(false)
    const [ city, setCity ] = useState('')
    const [ cityOptions, setCityOptions ] = useState([])
    const [ divergenceCity, setDivergenceCity ] = useState(false)
    const [ stateId, setStateId ] = useState('')
    const [ stateIdOptions, setStateIdOptions ] = useState(getStateList(true))
    const [ divergenceStateId, setDivergenceStateId ] = useState(false)
    const [ notes, setNotes ] = useState('')
    const [ referredBy, setReferredBy ] = useState('')

    const handleReferredBy = (selected) => {
        const referredBy = selected
        setReferredBy(referredBy)
    }

    const validateOnTouch = () => {
        if (name || cpf || rg || email || phone || landlinePhone || zipCode || address || addressNumber || addressComplement || neighborhood || city || stateId || birthdateText) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (!name) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome do tutor é obrigatório")
        } else if (name.length < 3) {
            isValid = false
            setDivergenceName(false)
            setErrorName(true)
            setHelperName("Por favor, preencha o nome completo do tutor")
        } else {
            setErrorName(false)
            setDivergenceName(false)
            setHelperName(<span>Campo <b>Obrigatório</b></span>)
        }

        let unmaskedCpf = cpf && cpf.replace(/[^0-9]/g, '')
        if (!unmaskedCpf) {
            setErrorCpf(false)
            setHelperCpf(optionalField)
        } else if (!validateCPF(unmaskedCpf)) {
            isValid = false
            setDivergenceCpf(false)
            setErrorCpf(true)
            setHelperCpf("O CPF informado não é válido")
        } else {
            setDivergenceCpf(false)
            setErrorCpf(false)
            setHelperCpf(optionalField)
        }

        if (!email) {
            setErrorEmail(false)
            setHelperEmail(optionalField)
        } else if (!validateEmail(email)) {
            isValid = false
            setDivergenceEmail(false)
            setErrorEmail(true)
            setHelperEmail("O e-mail informado não é válido")
        } else {
            setDivergenceEmail(false)
            setErrorEmail(false)
            setHelperEmail(optionalField)
        }

        let unmaskedLandlinePhone = landlinePhone && landlinePhone.replace(/[^0-9]/g, '')
        if (!unmaskedLandlinePhone) {
            setErrorLandlinePhone(false)
            setHelperLandlinePhone(optionalField)
        } else if (!validatePhone(landlinePhone)) {
            isValid = false
            setDivergenceLandlinePhone(false)
            setErrorLandlinePhone(true)
            setHelperLandlinePhone("O número de telefone informado não é válido")
        } else {
            setDivergenceLandlinePhone(false)
            setErrorLandlinePhone(false)
            setHelperPhone(optionalField)
        }

        let unmaskedPhone = phone && phone.replace(/[^0-9]/g, '')
        if (!unmaskedPhone) {
            setErrorPhone(false)
            setHelperPhone(optionalField)
        } else if (!validatePhone(phone)) {
            isValid = false
            setDivergencePhone(false)
            setErrorPhone(true)
            setHelperPhone("O número de celular informado não é válido")
        } else {
            setDivergencePhone(false)
            setErrorPhone(false)
            setHelperPhone(optionalField)
        }

        let unmaskedZipCode = zipCode && zipCode.replace(/[^0-9]/g, '')
        if (!unmaskedZipCode) {
            setErrorZipCode(false)
            setHelperZipCode(optionalField)
        } else if (!validatePostalCode(zipCode)) {
            isValid = false
            setDivergenceZipCode(false)
            setErrorZipCode(true)
            setHelperZipCode("O CEP informado não é válido")
        } else {
            setDivergenceZipCode(false)
            setErrorZipCode(false)
            setHelperZipCode(optionalField)
        }

        if (birthdateText) {
            setDivergenceBirthdate(false)
            if (validateDate(birthdateText)) {
                setErrorBirthdate(false)
                setHelperBirthdate(optionalField)
            } else {
                isValid = false
                setErrorBirthdate(true)
                setHelperBirthdate("A data de nascimento não é válida")
            }
        } else {
            setErrorBirthdate(false)
            setHelperBirthdate(optionalField)
        }

        if (address) {
            setDivergenceAddress(false)
        }

        if (addressNumber) {
            setDivergenceAddressNumber(false)
        }

        if (addressComplement) {
            setDivergenceAddressComplement(false)
        }

        if (neighborhood) {
            setDivergenceNeighborhood(false)
        }

        if (city) {
            setDivergenceCity(false)
        }

        if (stateId) {
            setDivergenceStateId(false)
            if (stateIdOptions.length == 2) {
                setStateIdOptions(getStateList(true))
            }
        }

        return isValid
    }

    const isValid = () => !errorName && !divergenceName && !errorCpf && !divergenceCpf && !errorEmail && !divergenceEmail && !errorPhone && !divergencePhone && !errorLandlinePhone && !divergenceLandlinePhone && !errorZipCode && !divergenceZipCode && !errorBirthdate && !divergenceBirthdate && !divergenceAddress && !divergenceAddressNumber && !divergenceAddressComplement && !divergenceNeighborhood && !divergenceCity && !divergenceStateId

    const fieldsWithErrorText = () => {
        let errors = []
        if (errorName || divergenceName) {
            errors.push("Nome")
        }
        if (errorCpf || divergenceCpf) {
            errors.push("CPF")
        }
        if (errorEmail || divergenceEmail) {
            errors.push("E-mail")
        }
        if (errorLandlinePhone || divergenceLandlinePhone) {
            errors.push("Telefone")
        }
        if (errorPhone || divergencePhone) {
            errors.push("Celular")
        }
        if (errorZipCode || divergenceZipCode) {
            errors.push("CEP")
        }
        if (errorBirthdate || divergenceBirthdate) {
            errors.push("Data de nascimento")
        }
        if (divergenceAddress) {
            errors.push("Endereço")
        }
        if (divergenceAddressNumber) {
            errors.push("Número do endereço")
        }
        if (divergenceAddressComplement) {
            errors.push("Complemento do endereço")
        }
        if (divergenceNeighborhood) {
            errors.push("Bairro do endereço")
        }
        if (divergenceCity) {
            errors.push("Cidade do endereço")
        }
        if (divergenceStateId) {
            errors.push("Estado do endereço")
        }
        if (!permissions?.check(ACL.OWNER, ACL.Rule.EDIT)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            if (validate()) {
                const owner = {
                    id: health?.vetsmart?.id,
                    name: name,
                    birthdate: birthdate,
                    cpf: cpf,
                    landlinePhone: landlinePhone,
                    phone: phone,
                    email: email,
                    rg: rg,
                    internationalId: internationalId,
                    zipCode: zipCode,
                    addressStreet: address,
                    addressNumber: addressNumber,
                    addressComplement: addressComplement,
                    neighborhood: neighborhood,
                    city: city,
                    stateId: stateId,
                    notes: notes,
                    referredBy: referredBy,
                    petloveSaudeId: health?.petlove?.public_id,
                    petloveSaudeData: health?.petlove,
                    selected: "unify"
                }
                props.dispatch(setHealthImportOwner(owner))
                return resolve(owner)
            } else {
                reject()
            }
        })
    }

    const uniqueNonEmptyOptions = (...options) => {
        const unique = [...new Set(options)]
        return unique.filter(x => x)
    }

    const setField = (options, setOptions, setField, setDivergence) => {
        if (options.length == 2) {
            setOptions(options)
            setDivergence(true)
        } else {
            setField(options.find(x => x) || "")
        }
    }

    useEffect(() => {
        setBirthdate(validateDate(birthdateText || ""))
    }, [ birthdateText ])

    useEffect(() => {
        validateOnTouch()
    }, [ name, cpf, email, phone, landlinePhone, birthdateText, zipCode, address, addressNumber, addressComplement, neighborhood, city, stateId ])

    useEffect(() => {
        if(currentZipCode != zipCode) {
            setLoadingZipCode(true)
            getAddressByZipCode(zipCode)
            .then(data => {
                currentZipCode = zipCode
                setLoadingZipCode(false)
                setAddress(data.address)
                setNeighborhood(data.neighborhood)
                setCity(data.city)
                setStateId(data.state_id)
            })
            .catch(_ => {
                setLoadingZipCode(false)
            })
        }
    }, [ zipCode ])

    useEffect(() => {
        if (health?.petlove) {
            const _nameOptions = uniqueNonEmptyOptions(health?.vetsmart?.get("name"), health?.petlove?.name)
            setField(_nameOptions, setNameOptions, setName, setDivergenceName)
            const _cpfOptions = uniqueNonEmptyOptions(health?.vetsmart?.get("cpf"), health?.petlove?.cpf)
            setField(_cpfOptions, setCpfOptions, setCpf, setDivergenceCpf)
            const birthdateVetsmartFormatted = health?.vetsmart?.get("birthdate") ? dayjs(health?.vetsmart?.get("birthdate")).format("DD/MM/YYYY") : ""
            const _birthdateOptions = uniqueNonEmptyOptions(birthdateVetsmartFormatted, health?.petlove?.birth_date)
            setField(_birthdateOptions, setBirthdateOptions, setBirthdateText, setDivergenceBirthdate)
            const _phoneOptions = uniqueNonEmptyOptions(health?.vetsmart?.get("phone"), health?.petlove?.cellphone)
            setField(_phoneOptions, setPhoneOptions, setPhone, setDivergencePhone)
            const _landlinePhoneOptions = uniqueNonEmptyOptions(health?.vetsmart?.get("landlinePhone"), health?.petlove?.phone)
            setField(_landlinePhoneOptions, setLandlinePhoneOptions, setLandlinePhone, setDivergenceLandlinePhone)
            const _emailOptions = uniqueNonEmptyOptions(health?.vetsmart?.get("email"), health?.petlove?.email)
            setField(_emailOptions, setEmailOptions, setEmail, setDivergenceEmail)
            const _zipCodeOptions = uniqueNonEmptyOptions(health?.vetsmart?.get("zipCode"), health?.petlove?.address?.cep)
            setField(_zipCodeOptions, setZipCodeOptions, setZipCode, setDivergenceZipCode)
            const _addressOptions = uniqueNonEmptyOptions(health?.vetsmart?.get("addressStreet"), health?.petlove?.address?.address)
            setField(_addressOptions, setAddressOptions, setAddress, setDivergenceAddress)
            const _addressNumberOptions = uniqueNonEmptyOptions(health?.vetsmart?.get("addressNumber"), health?.petlove?.address?.number)
            setField(_addressNumberOptions, setAddressNumberOptions, setAddressNumber, setDivergenceAddressNumber)
            const _addressComplementOptions = uniqueNonEmptyOptions(health?.vetsmart?.get("addressComplement"), health?.petlove?.address?.additional)
            setField(_addressComplementOptions, setAddressComplementOptions, setAddressComplement, setDivergenceAddressComplement)
            const _neighborhoodOptions = uniqueNonEmptyOptions(health?.vetsmart?.get("neighborhood"), health?.petlove?.address?.neighborhood)
            setField(_neighborhoodOptions, setNeighborhoodOptions, setNeighborhood, setDivergenceNeighborhood)
            const _cityOptions = uniqueNonEmptyOptions(health?.vetsmart?.get("city"), health?.petlove?.address?.city)
            setField(_cityOptions, setCityOptions, setCity, setDivergenceCity)
            const _stateOptions = uniqueNonEmptyOptions(health?.vetsmart?.get("stateId"), health?.petlove?.address?.uf)
            setField(_stateOptions, setStateIdOptions, setStateId, setDivergenceStateId)
        }
    }, [ health ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="health-owner-form"
            title={`${isMerge ? "Atualizar" : "Editar" } Cadastro de Tutor(a)`}
            cancel={"Cancelar Alterações"}
            submit={isValid ? "Avançar" : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            timeout={0}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={_ => {
                history.push(`/petlove-saude/animal`)
            }}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar a operação?",
                text: "As informações digitadas não serão salvas e esta operação não poderá ser desfeita.",
                confirm: "Cancelar Alterações",
                cancel: `Voltar para ${ isMerge ? "Atualizar" : "Editar"} Cadastro`
            }}
        >
            <div className="row">
                { isMerge && (
                    <>
                        <div className="col-12 mb-3">
                            <div className="input-title">
                                Selecione quais Informações do Tutor deseja manter
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <p className="text-default">
                                Se houver diferenças nas informações, 
                                os campos serão destacados em vermelho no formulário. 
                                Escolha quais informações deseja manter no cadastro.
                            </p>
                        </div>
                    </>
                )}
                <div className="col-12 col-md-8 mb-3">
                    <Autocomplete
                        inputValue={name}
                        options={nameOptions}
                        filterOptions={(options) => options}
                        onChange={(_, selected) => setName(selected || "")}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Nome Completo"
                                label={`${divergenceName ? "Selecione o " : ""}Nome do tutor`}
                                onChange={({ target: { value } }) => setName(value)}
                                error={divergenceName || errorName}
                                helperText={divergenceName ? divergenceHelper : helperName}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        disableClearable
                        openOnFocus
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4 mb-3">
                    <Autocomplete
                        inputValue={birthdateText}
                        options={birthdateOptions}
                        filterOptions={(options) => options}
                        onChange={(_, selected) => setBirthdateText(selected || "")}
                        renderInput={(params) => (
                            <TextFieldMask
                                {...params}
                                type="tel"
                                mask='00/00/0000'
                                placeholder="00/00/0000"
                                label={`${divergenceBirthdate ? "Selecione a " : ""}Data de nascimento`}
                                onChange={({ target: { value } }) => setBirthdateText(value)}
                                onAccept={(value) => setBirthdateText(value)}
                                error={divergenceBirthdate || errorBirthdate}
                                helperText={divergenceBirthdate ? divergenceHelper : helperBirthdate}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        disableClearable
                        openOnFocus
                    />
                </div>
                <div className="col-12 col-sm-6  col-md-4 mb-3">
                    <Autocomplete
                        inputValue={cpf}
                        options={cpfOptions}
                        filterOptions={(options) => options}
                        onChange={(_, selected) => setCpf(selected || "")}
                        renderInput={(params) => (
                            <TextFieldMask
                                {...params}
                                type="tel"
                                mask='000.000.000-00'
                                placeholder="000.000.000-00"
                                label={`${divergenceCpf ? "Selecione o " : ""}CPF`}
                                onChange={({ target: { value } }) => setCpf(value)}
                                onAccept={(value) => setCpf(value)}
                                error={divergenceCpf || errorCpf}
                                helperText={divergenceCpf ? divergenceHelper : helperCpf}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        disableClearable
                        openOnFocus
                    />
                </div>
                <div className="col-12 col-sm-6  col-md-4 mb-3">
                    <Autocomplete
                        inputValue={landlinePhone}
                        options={landlinePhoneOptions}
                        filterOptions={(options) => options}
                        onChange={(_, selected) => setLandlinePhone(selected || "")}
                        renderInput={(params) => (
                            <TextFieldMask
                                {...params}
                                type="tel"
                                mask="(00) 0000-0000"
                                placeholder="(00) 0000-0000"
                                label={`${divergenceLandlinePhone ? "Selecione o " : ""}Telefone`}
                                onChange={({ target: { value } }) => setLandlinePhone(value)}
                                onAccept={(value) => setLandlinePhone(value)}
                                error={divergenceLandlinePhone || errorLandlinePhone}
                                helperText={divergenceLandlinePhone ? divergenceHelper : helperLandlinePhone}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        disableClearable
                        openOnFocus
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4 mb-3">
                    <Autocomplete
                        inputValue={phone}
                        options={phoneOptions}
                        filterOptions={(options) => options}
                        onChange={(_, selected) => setPhone(selected || "")}
                        renderInput={(params) => (
                            <TextFieldMask
                                {...params}
                                type="tel"
                                mask={phone?.length <= 14 ? '(00) 0000-0000[0]' : '(00) 00000-0000'}
                                placeholder="(00) 00000-0000"
                                label={`${divergencePhone ? "Selecione o " : ""}Celular`}
                                onChange={({ target: { value } }) => setPhone(value)}
                                onAccept={(value) => setPhone(value)}
                                error={divergencePhone || errorPhone}
                                helperText={divergencePhone ? divergenceHelper : helperPhone}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        disableClearable
                        openOnFocus
                    />
                </div>
                <div className="col-12">
                    <Autocomplete
                        inputValue={email}
                        options={emailOptions}
                        filterOptions={(options) => options}
                        onChange={(_, selected) => setEmail(selected || "")}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                type="email"
                                placeholder="exemplo@email.com"
                                label={`${divergenceEmail ? "Selecione o " : ""}E-mail`}
                                onChange={({ target: { value } }) => setEmail(value)}
                                error={divergenceEmail || errorEmail}
                                helperText={divergenceEmail ? divergenceHelper : helperEmail}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        disableClearable
                        openOnFocus
                    />
                </div>
            </div>
            <hr className="my-2" />
            <div className="row">
                <div className="col">
                    <VSAccordionSimple
                        expanded={true}
                        header={(_) => (
                            "Informações complementares do(a) tutor(a)"
                        )}
                    >
                        <div className="row">
                            <div className="col-6 col-md-4 mb-3">
                                <TextField
                                    placeholder="00000000-0"
                                    label="RG"
                                    value={rg}
                                    onChange={({ target: { value } }) => setRg(value)}
                                    helperText={<span>Campo Opcional</span>}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-6 col-md-4 mb-3">
                                <TextField
                                    placeholder="Ex: AAA-GG-SSSS"
                                    label="Identidade Estrangeira"
                                    value={internationalId}
                                    onChange={({ target: { value } }) => setInternationalId(value)}
                                    helperText={<span>Campo Opcional</span>}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-3 mb-3">
                                <Autocomplete
                                    inputValue={zipCode}
                                    options={zipCodeOptions}
                                    filterOptions={(options) => options}
                                    onChange={(_, selected) => setZipCode(selected || "")}
                                    renderInput={(params) => (
                                        <TextFieldMask
                                            {...params}
                                            type="tel"
                                            mask="00000-000"
                                            placeholder="00000-000"
                                            label={`${divergenceZipCode ? "Selecione o " : ""}CEP`}
                                            onChange={({ target: { value } }) => setZipCode(value)}
                                            onAccept={(value) => setZipCode(value)}
                                            error={divergenceZipCode || errorZipCode}
                                            helperText={divergenceZipCode ? divergenceHelper : helperZipCode}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        { loadingZipCode && (
                                                            <CircularProgress size={16} />
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                    disableClearable
                                    openOnFocus
                                />
                            </div>
                            <div className="col-12 col-md-8 col-lg-9 mb-3">
                                <Autocomplete
                                    inputValue={address}
                                    options={addressOptions}
                                    filterOptions={(options) => options}
                                    onChange={(_, selected) => setAddress(selected || "")}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Rua, avenida, alameda, etc."
                                            label={`${divergenceAddress ? "Selecione o " : ""}Endereço`}
                                            onChange={({ target: { value } }) => setAddress(value)}
                                            error={divergenceAddress}
                                            helperText={divergenceAddress ? divergenceHelper : optionalField}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    disableClearable
                                    openOnFocus
                                />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-3">
                                <Autocomplete
                                    inputValue={addressNumber}
                                    options={addressNumberOptions}
                                    filterOptions={(options) => options}
                                    onChange={(_, selected) => setAddressNumber(selected || "")}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={divergenceAddressNumber ? "Selecione o Número" : "000"}
                                            label="Número"
                                            onChange={({ target: { value } }) => setAddressNumber(value)}
                                            error={divergenceAddressNumber}
                                            helperText={divergenceAddressNumber ? divergenceHelper : optionalField}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    disableClearable
                                    openOnFocus
                                />
                            </div>
                            <div className="col-6 col-md-9 col-lg-10 mb-3">
                                <Autocomplete
                                    inputValue={addressComplement}
                                    options={addressComplementOptions}
                                    filterOptions={(options) => options}
                                    onChange={(_, selected) => setAddressComplement(selected || "")}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Torre Oceania, Apartamento 12"
                                            label={`${divergenceAddressComplement ? "Selecione o " : ""}Complemento`}
                                            onChange={({ target: { value } }) => setAddressComplement(value)}
                                            error={divergenceAddressComplement}
                                            helperText={divergenceAddressComplement ? divergenceHelper : optionalField}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    disableClearable
                                    openOnFocus
                                />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-3">
                                <TextField
                                    placeholder="São Paulo"
                                    label="Estado"
                                    value={stateId}
                                    onChange={({ target: { value } }) => setStateId(value)}
                                    error={divergenceStateId}
                                    helperText={divergenceStateId ? divergenceHelper : optionalField}
                                    select
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                >
                                    { 
                                        getStateList()
                                        .filter(stateItem => stateIdOptions.indexOf(stateItem.value) != -1)
                                        .map(stateItem => (
                                            <MenuItem key={stateItem.value} value={stateItem.value}>
                                                { stateItem.text }
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>
                            <div className="col-6 col-md-5 col-lg-5 mb-3">
                                <Autocomplete
                                    inputValue={city}
                                    options={cityOptions}
                                    filterOptions={(options) => options}
                                    onChange={(_, selected) => setCity(selected || "")}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="São Paulo"
                                            label={`${divergenceCity ? "Selecione a " : ""}Cidade`}
                                            onChange={({ target: { value } }) => setCity(value)}
                                            error={divergenceCity}
                                            helperText={divergenceCity ? divergenceHelper : optionalField}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    disableClearable
                                    openOnFocus
                                />
                            </div>
                            <div className="col-12 col-md-4 col-lg-5 mb-3">
                                <Autocomplete
                                    inputValue={neighborhood}
                                    options={neighborhoodOptions}
                                    filterOptions={(options) => options}
                                    onChange={(_, selected) => setNeighborhood(selected || "")}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Jardim das Flores"
                                            label={`${divergenceNeighborhood ? "Selecione o " : ""}Bairro`}
                                            onChange={({ target: { value } }) => setNeighborhood(value)}
                                            error={divergenceNeighborhood}
                                            helperText={divergenceNeighborhood ? divergenceHelper : optionalField}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    disableClearable
                                    openOnFocus
                                />
                            </div>
                            <div className="col-12">
                                <TextField
                                    className="vsmui-disable-ellipsis"
                                    placeholder="Anotações particulares (temperamento, característica, etc.)"
                                    label="Outras informações"
                                    value={notes}
                                    onChange={({ target: { value } }) => setNotes(value)}
                                    size="small"
                                    variant="outlined"
                                    multiline
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 input-info mb-3">
                                As observações são anotações particulares. <b>Elas nunca serão compartilhadas com o tutor!</b>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <Autocomplete
                                inputValue={referredBy}
                                options={["Internet", "Redes sociais", "Indicação", "Fachada da loja", "Panfleto"]}
                                onChange={(_, selected) => handleReferredBy(selected || "")}
                                onInputChange={(event, selected) => event && handleReferredBy(selected)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Digite..."
                                        label="Onde nos conheceu?"
                                        helperText={<span>Campo Opcional<br/></span>}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                                openOnFocus
                            />
                        </div>
                    </VSAccordionSimple>
                </div>
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        health: state.health,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(HealthOwnerForm);