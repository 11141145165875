import React, { useEffect } from "react"
import { connect } from "react-redux";

const HealthValidate = (props) => {

    const {
        health,
        history
    } = props

    useEffect(() => {
        if (!health?.petlove) {
            Swal.fire(
                'Oops...',
                `Não foi possível prosseguir com a importação`,
                'error'
            )
            .then(_ => {
                history.push("/")
            })
        }
    }, [ health ])

    return null
}


const mapStateToProps = state => {
    return {
        health: state.health
    };
};

export default connect(mapStateToProps)(HealthValidate)