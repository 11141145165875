import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Dialog } from "@mui/material";

import { ReactComponent as IconCloseO } from "../../assets/icon-close-o.svg"
import { ReactComponent as IconArrowRight } from "../../assets/icon-arrow-right.svg"

import { steps } from "./VSOnboardingSteps";

export const getLocalStorageHealthOnboardingKey = (clinicId) => {
    return `vsprontuario-${clinicId}-health-onboarding`
}

import "./VSOnboarding.scss"
import { setHealthOnboarding } from "../../store/actions";
const VSOnboarding = (props) => {

    const {
        clinic,
        showOnboardingHealth
    } = props

    const LOCALSTORAGE_HEALTH_ONBOARDING = getLocalStorageHealthOnboardingKey(clinic?.objectId)

    const [ step, setStep ] = useState(1)
    const [ closed, setClosed ] = useState(true)
    const data = steps[step - 1]

    const onClose = () => {
        localStorage.setItem(LOCALSTORAGE_HEALTH_ONBOARDING, true)
        props.dispatch(setHealthOnboarding(false))
        setClosed(true)
    }

    useEffect(() => {
        if (clinic?.object) {
            const needOpen = clinic?.object?.get("petloveSaudeId") && !localStorage.getItem(LOCALSTORAGE_HEALTH_ONBOARDING)
            setClosed(!needOpen)
            setStep(1)
        }
    }, [ clinic, showOnboardingHealth ])


    return closed ? null : (
        <Dialog
            id="vsonboarding"
            open={open}
            className="vsonboarding-dialog"
        >
            <div style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                        <div className="vsonboarding-image">
                            { data.image }
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                        <div className="vsonboarding-sticky">
                            <div className="vsonboarding-content">
                                <div className="vsonboarding-head">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <div className="vsonboarding-title">
                                                { data.title }
                                            </div>
                                        </div>
                                        <div
                                            className="col-auto vsonboarding-close"
                                            onClick={onClose}
                                        >
                                            <IconCloseO width={40} height={40} fill="var(--vsprontuario-primary-color)" />
                                        </div>
                                    </div>
                                </div>
                                <div className="vsonboarding-body">
                                    <div className="row">
                                        <div className="col-12">
                                            { data.text }
                                        </div>
                                    </div>
                                </div>
                                <div className="vsonboarding-actions">
                                    <div className="row justify-content-end">
                                        { step != 1 && (
                                            <div className="col">
                                                <button
                                                    className="btn-link"
                                                    onClick={() => {
                                                        setStep(step - 1)
                                                    }}
                                                >
                                                    <IconArrowRight width={16} height={16} fill="var(--vsprontuario-primary-color)" style={{ transform: "rotate(180deg)" }} />
                                                    Voltar
                                                </button>
                                            </div>
                                        )}
                                        <div className="col-auto">
                                            <button
                                                className="btn-submit"
                                                onClick={() => {
                                                    if (step == 6) {
                                                        onClose()
                                                    } else {
                                                        setStep(step + 1)
                                                    }
                                                }}
                                            >
                                                { data.submit }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        showOnboardingHealth: state.showOnboardingHealth,
    };
};

export default connect(mapStateToProps)(VSOnboarding)