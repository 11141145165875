import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { useLocation } from "react-router-dom";

import { CircularProgress } from '@mui/material';
import { ReactComponent as IconHeaderIA } from "../../../../assets/icon-header-ia.svg"
import { ReactComponent as IconSimpleWarning } from "../../../../assets/icon-simple-warning.svg"
import { ReactComponent as IconIA } from "../../../../assets/icon-ia.svg"
import { ReactComponent as IconClose } from "../../../../assets/icon-close.svg"
import { ReactComponent as IconVirtualAssistantSelect } from "../../../../assets/virtual-assistant-select.svg"
import { ReactComponent as IconVirtualAssistantDiagnosis } from "../../../../assets/icon-virtual-assistant-diagnosis.svg"
import { ReactComponent as IconVirtualAssistantAnamnesisFormat } from "../../../../assets/icon-virtual-assistant-anamnesis-format.svg"
import { ReactComponent as IconVirtualAssistantAnamnesisRecord } from "../../../../assets/icon-virtual-assistant-anamnesis-record.svg"

import { saveOnTracker } from '../../../../utils'
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';
import { CONSULTA_DETALHADA } from './Appointment';

import VSDrawerInfo from '../../../../components/vsDrawer/VSDrawerInfo';
import VSTooltip from '../../../../components/vsTooltip/VSTooltip';
import { PARAM_AI_DIFFERENTIAL_DIAGNOSIS, PARAM_AI_ANAMNESIS_RECORD, PARAM_AI_ANAMNESIS_FORMAT } from './AppointmentForm';
import AppointmentFormDifferentialDiagnosisPreview from './AppointmentFormDifferentialDiagnosisPreview';
import AppointmentFormAnamnesisRecordPreview from './AppointmentFormAnamnesisRecordPreview';
import AppointmentFormAnamnesisFormatPreview from './AppointmentFormAnamnesisFormatPreview';

const AppointmentFormVirtualAssistantPreview = forwardRef((props, ref) => {

    const { state } = useLocation()

    const {
        clinic,
        patient,
        appointment,
        documentDate,
        anamnesis,
        onSaveAnamnesis,
        appointmentType,
        physicalExam,
        diagnosis,
        treatment,
        nextStep,
        differentialDiagnosisList,
        setDifferentialDiagnosisList,
        hasDrawerInfoForAI,
        setHasDrawerInfoForAI,
        hasGeneratedAI,
        setHasGeneratedAI,
        openWith,
        drawerInfoRef
    } = props

    const [ currentAISelected, setCurrentAISelected ] = useState(openWith)
    const [ currentAICache, setCurrentAICache ] = useState(null)
    const [ loadingAssistantPreview, setLoadingAssistantPreview ] = useState(false)
    const [ showDifferentialDiagnosisTooltip, setShowDifferentialDiagnosisTooltip ] = useState(false)
    const [ hasDifferentialDiagnosisChanged, setHasDifferentialDiagnosisChanged ] = useState(false)
    const [ showFormatAnamnesisTooltip, setShowFormatAnamnesisTooltip ] = useState(false)

    const [ trackerSource, setTrackerSource ] = useState(state?.trackerSource ? state?.trackerSource : "")
    const [ trackerSourceDetail, setTrackerSourceDetail ] = useState(state?.trackerSourceDetail ? state?.trackerSourceDetail : "")

    const isDifferentialDiagnosisEnabled = (
        appointmentType == CONSULTA_DETALHADA ? 
        (anamnesis || physicalExam || diagnosis || treatment || nextStep) :
        anamnesis
    )

    useImperativeHandle(ref, () => ({
        onSave: getDifferentialDiagnosisIfNeeded,
    }))
    
    const getDifferentialDiagnosisIfNeeded = () => {
        if (differentialDiagnosisList && differentialDiagnosisList.length > 0 && isDifferentialDiagnosisEnabled && hasDifferentialDiagnosisChanged) {
            return getDifferentialDiagnosis()
        }
        return Promise.resolve()
    }

    const setDifferentialDiagnosis = (index, value) => {
        let newDifferensetDifferentialDiagnosisList = [...differentialDiagnosisList]
        newDifferensetDifferentialDiagnosisList.splice(index, 0, value)
        setDifferentialDiagnosisList(newDifferensetDifferentialDiagnosisList)
    }

    const getDifferentialDiagnosis = () => {
        setLoadingAssistantPreview(true)
        return Parse.Cloud.run("aiDifferentialDiagnosis", {
            clinicId: clinic.id || clinic.objectId,
            patientId: patient.id,
            appointment: {
                documentDate: documentDate,
                anamnesis: anamnesis,
                physicalExam: appointmentType == CONSULTA_DETALHADA ? physicalExam : undefined,
                diagnosis: appointmentType == CONSULTA_DETALHADA ? diagnosis : undefined,
                treatment: appointmentType == CONSULTA_DETALHADA ? treatment : undefined,
                nextStep: appointmentType == CONSULTA_DETALHADA ? nextStep : undefined
            },
            appVersionName: APP_VERSION,
            deviceType: APP_DEVICE,
            appContentType: APP_NAME
        }).then(aiCache => {
            setDifferentialDiagnosis(0, aiCache)
            setLoadingAssistantPreview(false)
            setHasDifferentialDiagnosisChanged(false)
            setHasGeneratedAI(true)

            saveOnTracker("Concluiu", "Assistente", "Diagnóstico Diferencial", null, trackerSource ? trackerSource : "Consulta", null, trackerSourceDetail, JSON.stringify({
                patientId: patient?.id || patient?.objectId,
                appointmentId: appointment?.id ? appointment?.id : 'novo',
                aiCacheId: aiCache?.id,
                cached: aiCache?.get("cached")
            }))
        })
    }

    const getAnamnesisFormat = () => {
        setLoadingAssistantPreview(true)
        return Parse.Cloud.run("aiAppointmentTextFormat", {
            clinicId: clinic.id || clinic.objectId,
            patientId: patient.id,
            appointment: {
                documentDate: documentDate,
                anamnesis: anamnesis
            },
            appVersionName: APP_VERSION,
            deviceType: APP_DEVICE,
            appContentType: APP_NAME
        }).then(aiCache => {
            setLoadingAssistantPreview(false)
            setHasGeneratedAI(true)
            setCurrentAICache(aiCache)

            saveOnTracker("Concluiu", "Assistente", "Organizar Anamnese", null, trackerSource ? trackerSource : "Consulta", null, trackerSourceDetail, JSON.stringify({
                patientId: patient?.id || patient?.objectId,
                appointmentId: appointment?.id ? appointment?.id : 'novo',
                aiCacheId: aiCache?.id,
                cached: aiCache?.get("cached")
            }))
        })

    }

    useEffect(() => {
        if (differentialDiagnosisList && differentialDiagnosisList.length > 0) {
            setCurrentAICache(differentialDiagnosisList[0])
        }
    }, [ differentialDiagnosisList ])

    useEffect(() => {
        if(currentAISelected == PARAM_AI_DIFFERENTIAL_DIAGNOSIS) {
            getDifferentialDiagnosis()
        } else if (currentAISelected == PARAM_AI_ANAMNESIS_FORMAT) {
            getAnamnesisFormat()
        }
    }, [ currentAISelected ])

    useEffect(() => {
        setHasDifferentialDiagnosisChanged(true)
    }, [ anamnesis, physicalExam, diagnosis, treatment, nextStep ])

    if(!hasDrawerInfoForAI){
        return <></>
    }

    const title = currentAISelected == PARAM_AI_DIFFERENTIAL_DIAGNOSIS ?  "Diagnóstico Diferencial" : 
                    currentAISelected == PARAM_AI_ANAMNESIS_RECORD ? "Gravar Anamnese" : 
                    currentAISelected == PARAM_AI_ANAMNESIS_FORMAT ? "Organizar Anamnese" : <>Assistente Vetsmart</>

    const onClose = _ => {
        setCurrentAISelected(null)
        setHasDrawerInfoForAI(false)
    }

    const defaultFooter = (
        <div className="vsdrawer-footer px-3 py-3 pb-md-0">
            <p><b>Não encontrou a condição que esperava?</b> Nos ajude a melhorar as nossas respostas. <a href="https://petlove-vetsmart-vetus.typeform.com/to/qLaJ9vR9" target="_blank">Enviar feedback</a></p>
        </div>
    )

    const anamnesisRecordFooter = (
        <div className="vsdrawer-footer px-3 py-3">
            <p>Para melhores resultados, recomendamos <b>apenas áudio em português</b> e <b>evite ruídos</b> que possam comprometer o reconhecimento da gravação, pois o Assistente Vetsmart <b>não processa as gravações em tempo real</b>.</p>
        </div>
    )

    return (
        <VSDrawerInfo close={title} ref={drawerInfoRef} onClose={() => setHasDrawerInfoForAI(false)}>
            <div className="vsdrawer-info-header d-none d-md-inline">
                {title}
                {!loadingAssistantPreview && 
                <button className="vsdrawer-info-close d-none d-md-inline" onClick={onClose}>
                    <IconClose fill="rgba(0, 0, 0, 0.8)" width="20" height="20" />
                </button>}
            </div>
            {currentAISelected && !loadingAssistantPreview && (
                <div className="row vsdrawer-info-subheader flex-between gx-0">
                    <div className="vsdrawer-info-subheader-button col-auto d-flex p-3" onClick={() => {
                        setCurrentAISelected(null)
                        saveOnTracker("Clicou", "Assistente", "Voltar para opções", null, "Consulta", currentAISelected == PARAM_AI_DIFFERENTIAL_DIAGNOSIS ?  "Diagnóstico Diferencial" : null, null, {
                            appointmentId: appointment?.id ? appointment?.id : 'novo',
                            patientId: patient?.id || patient?.objectId
                        })
                    }}>
                        <IconHeaderIA className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                        <span>Voltar para opções</span>
                    </div>
                    {/* <div className="col-6">

                    </div> */}
                </div>
            )}
            {currentAISelected == PARAM_AI_DIFFERENTIAL_DIAGNOSIS ? (
                loadingAssistantPreview ? (
                    <div className="row data-loading pt-5 w-auto">
                        <div className="col-12">
                            <CircularProgress />
                        </div>
                        <div className="col-12 px-4">
                            <div className="row">
                                <div className="loading-title col-12">
                                    Gerando diagnóstico diferencial
                                </div>
                                <div className="loading-subtitle col-12">
                                    Por favor, aguarde alguns segundos enquanto analisamos todos os registros.
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <AppointmentFormDifferentialDiagnosisPreview 
                            aiCache={currentAICache} 
                            hasDifferentialDiagnosisChanged={hasDifferentialDiagnosisChanged} 
                            getDifferentialDiagnosis={getDifferentialDiagnosis}
                        />
                        {defaultFooter}
                    </>
                )
            ) : currentAISelected == PARAM_AI_ANAMNESIS_RECORD ? (
                <>
                    <AppointmentFormAnamnesisRecordPreview
                        clinic={clinic}
                        patient={patient}
                        appointment={appointment}
                        onSaveRecording={transcribedText => {
                            onSaveAnamnesis(transcribedText, false)
                            setCurrentAISelected(null)
                        }}
                        trackerSource={trackerSource}
                        trackerSourceDetail={trackerSourceDetail}
                    />
                    {anamnesisRecordFooter}
                </>
            ) : currentAISelected == PARAM_AI_ANAMNESIS_FORMAT ? (
                loadingAssistantPreview ? (
                    <div className="row data-loading pt-5 w-auto">
                        <div className="col-12">
                            <CircularProgress />
                        </div>
                        <div className="col-12 px-4">
                            <div className="row">
                                <div className="loading-title col-12">
                                    Organizando anamnese
                                </div>
                                <div className="loading-subtitle col-12">
                                    Por favor, aguarde alguns segundos enquanto organizamos os dados da sua anamnese
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <AppointmentFormAnamnesisFormatPreview
                            aiCache={currentAICache}
                            onSaveFormat={text => {
                                onSaveAnamnesis(text, true)
                                setCurrentAISelected(null)
                            }}
                            onClose={() => setCurrentAISelected(null)}
                        />
                        {defaultFooter}
                    </>
                )
            ) : (
                <>
                    <div className="row flex-grow-1 gx-0">
                        <div className="col-12">
                            <div className="p-3">
                                <div className="text-center mx-auto mb-4">
                                    <IconVirtualAssistantSelect className="mb-4" />
                                    <p className="virtual-assistant-intro-text">Agora a Vetsmart possui um assistente digital que pode te ajudar a encontrar diagnósticos e tratamentos para os seus pacientes.</p>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h3 className="virtual-assistant-select-title">Selecione o que deseja fazer:</h3>
                                        <VSTooltip
                                            placement="top-end"
                                            open={showDifferentialDiagnosisTooltip}
                                            onOpen={() => {
                                                if (!isDifferentialDiagnosisEnabled) {
                                                    setShowDifferentialDiagnosisTooltip(true)
                                                }
                                            }}
                                            onClose={() => setShowDifferentialDiagnosisTooltip(false)}
                                            title="Preencha mais informações sobre a consulta para gerar um Diagnóstico Diferencial."
                                        >
                                            <button className={`vsbox-btn vsbox-btn-primary w-100 mt-2 text-normal text-start ${isDifferentialDiagnosisEnabled ? '' : 'disabled'}`} onClick={() => {
                                                if (!isDifferentialDiagnosisEnabled) {
                                                    return;
                                                }
                                                setCurrentAISelected(PARAM_AI_DIFFERENTIAL_DIAGNOSIS)
                                                saveOnTracker("Clicou", "Assistente", "Diagnóstico Diferencial", null, "Consulta", null, null, {
                                                    appointmentId: appointment?.id ? appointment?.id : 'novo',
                                                    patientId: patient?.id || patient?.objectId
                                                })
                                            }}>
                                                <IconVirtualAssistantDiagnosis className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                                Gerar Diagnóstico diferencial
                                            </button>
                                        </VSTooltip>
                                        <button className={`vsbox-btn vsbox-btn-primary w-100 mt-2 text-normal text-start ${true ? '' : 'disabled'}`} onClick={() => {
                                            // if(!isDifferentialDiagnosisEnabled){
                                            //     return;
                                            // }
                                            setCurrentAISelected(PARAM_AI_ANAMNESIS_RECORD)
                                            saveOnTracker("Clicou", "Assistente", "Gravar Anamnese", null, "Consulta", null, null, {
                                                appointmentId: appointment?.id ? appointment?.id : 'novo',
                                                patientId: patient?.id || patient?.objectId
                                            })
                                        }}>
                                            <IconVirtualAssistantAnamnesisRecord className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                            Gravar Anamnese
                                        </button>
                                        <VSTooltip
                                            placement="bottom-end"
                                            open={showFormatAnamnesisTooltip}
                                            onOpen={() => {
                                                if (!anamnesis) {
                                                    setShowFormatAnamnesisTooltip(true)
                                                }
                                            }}
                                            onClose={() => setShowFormatAnamnesisTooltip(false)}
                                            title="Preencha mais informações sobre a consulta para organizar uma anamnese."
                                        >
                                            <button className={`vsbox-btn vsbox-btn-primary w-100 mt-2 text-normal text-start ${anamnesis ? '' : 'disabled'}`} onClick={() => {
                                                if(!anamnesis){
                                                    return;
                                                }
                                                setCurrentAISelected(PARAM_AI_ANAMNESIS_FORMAT)
                                                saveOnTracker("Clicou", "Assistente", "Organizar Anamnese", null, "Consulta", null, null, {
                                                    appointmentId: appointment?.id ? appointment?.id : 'novo',
                                                    patientId: patient?.id || patient?.objectId
                                                })
                                            }}>
                                                <IconVirtualAssistantAnamnesisFormat className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                                Organizar Anamnese
                                            </button>
                                        </VSTooltip>
                                        {/* <div className="soon-text mt-3">
                                            <IconIA className="me-2" width="24" height="24" fill="#FF8A00" />
                                            Em breve novas funcionalidades
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="vsdrawer-footer flex-shrink-0 p-3" style={{ background: '#E6E6E6' }}>
                        <div className="row">
                            <div className="col-auto">
                                <IconSimpleWarning width="16" height="16" fill="#666666" />
                            </div>
                            <div className="col ps-0">
                                <p className="virtual-assistant-footer-text mb-0">
                                    O Assistente Vetsmart é uma ferramenta de suporte para seus atendimentos clínicos e não deve ser usada como diagnóstico definitivo.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </VSDrawerInfo>
    )
})

export default AppointmentFormVirtualAssistantPreview