import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom'
import { getPatient } from '../../../patient/Patient';

import { ReactComponent as IconPatientAppointment } from '../../../../assets/icon-patient-appointment.svg'
import { ReactComponent as IconAdd } from "../../../../assets/icon-primary-add-o.svg"

import { CircularProgress } from "@mui/material";
import PatientSelectQuery from '../../../patient/PatientSelectQuery';
import { PatientQueryToggle } from '../../../patient/PatientWideInfo';
import VSError from '../../../../components/vsError/VSError';
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';
import { dayjs, saveOnTracker } from '../../../../utils';
import VSList from '../../../../components/vsList/VSList';
import { AIAppointmentView } from '../../../patient/tabs/appointment/AppointmentView';
import VSEmpty from '../../../../components/vsEmpty/VSEmpty';

import './VirtualAssistantAnamnesisRecord.scss';
const VirtualAssistantAnamnesisRecord = props => {

    const {
        id_animal
    } = useParams()

    const { state } = useLocation()

    const {
        user,
        clinic,
        history,
        permissions,
        setDrawerError
    } = props

    // const isAnimalSelected = id_animal != "selecionar"

    const filter = useRef(null)
    const isMounted = useRef(false)
    const rowsPerPage = 5

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ patient, setPatient ] = useState(null)

    const [ trackerSource, setTrackerSource ] = useState(state?.trackerSource ? state?.trackerSource : "")
    const [ trackerSourceDetail, setTrackerSourceDetail ] = useState("")

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (patient && !permissions?.check(ACL.PATIENT, ACL.Rule.EDIT, patient)) { // TODO: view or edit permission?
            permissions?.error()
        }
        if (permissions && !permissions.check(ACL.APPOINTMENT, ACL.Rule.EDIT)) {
            permissions?.error()
        }
    }, [ patient, permissions ])

    useEffect(() => {
        if(state?.trackerSource){
            setTrackerSource(state?.trackerSource)
        }
    }, [ state ])

    return (
        <div id="virtual-assistant-anamnesis-record">
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        // switch(error.function) {
                        //     case "getPatientOrError":
                        //         getPatientOrError()
                        //         break
                        //     default:
                        // }
                    }}
                />
            ) : (
                <PatientSelectQuery
                    getNextRoute={(_patient, source) => {
                        history.replace(`/animal/${_patient.id}/consulta/novo?gravarAnamnese`)
                        
                        const _trackerSource = "Busca por Animal ou Tutor"
                        const _trackerSourceDetail = source == "BoxOwner" ? "Tutor" : source == "BoxPatient" ? "Animal" : ""
                        setTrackerSource(_trackerSource)
                        setTrackerSourceDetail(_trackerSourceDetail)
                        saveOnTracker("Clicou", "Assistente", "Gravar Anamnese", null, _trackerSource, null, _trackerSourceDetail, { patientId: _patient?.id })
                    }}
                    // appendContentToBoxPatient={(_patient, _history) => 
                    //     <div className="row mt-3">
                    //         <div className="col">
                    //             <div className="va-differential-diagnosis-summary-box-patient-append">
                    //                 Este animal é paciente desde {dayjs(_patient?.get("createdAt")).format("YYYY")}{getAppointmentCountTextForPatient(_history)}.
                    //             </div>
                    //         </div>
                    //     </div>
                    // }
                    // includePatientHistory
                    selectPatientLabel="Nova Consulta"
                    boxPatientCallOnSelectByBoxClick
                    history={history}
                />
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(VirtualAssistantAnamnesisRecord)