import React, { useEffect, useRef, useState } from 'react'
import Cropper from 'react-cropper';
import Compressor from 'compressorjs';

import 'cropperjs/dist/cropper.css';
import "./VSImage.scss"
import { CircularProgress } from '@mui/material';
import { toBase64 } from '../../utils';

const VSImage = (props) => {

    const {
        label,
        text,
        image,
        imageUrl,
        children,
        getImage,
        disabled,
        className,
        cropperOptions
    } = props

    const uploader = useRef()
    const cropper = useRef()

    const [ uploaded, setUploaded ] = useState(image)
    const [ loading, setLoading ] = useState(false)
    const [ hasCropped, setHasCropped ] = useState(true)

    const handleUpload = () => {
        if (uploader.current) {
            uploader.current.click()
        }
    }

    const onDelete = () => {
        if (uploader.current) {
            uploader.current.value = null
        }
        setUploaded(null)
        setHasCropped(true)
        setLoading(false)
        getImage && getImage(null)
    }
    
	const onUpload = () => {
        if (uploader && uploader.current) {
            const uploaderObj = uploader.current

            var filename = uploaderObj.value || "";
            var lastIndex = filename.lastIndexOf("\\");
            if (lastIndex >= 0) {
                filename = filename.substring(lastIndex + 1);
            }
    
            if (uploaderObj.files && uploaderObj.files[0]) {
                setUploaded("")
                setLoading(true)
                setHasCropped(false)
    
                new Compressor(uploaderObj.files[0], {
                    maxHeight: 1280,
                    maxWidth: 1280,
                    quality: 0.6,
                    success(blob) {
                        toBase64(blob)
                        .then(resizedBase64 => {
                            saveOnParse(resizedBase64, filename)
                            .then(image => {
                                setLoading(false)
                                setUploaded(image)
                                getImage && getImage(image)
                            })
                        })
                    },
                    error(error) {
                        setLoading(false)
                        console.error(error)
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Não foi possível ajustar a imagem para os padrões do formulário"
                        })
                    }
                })
            }
        }
    }
    
    const saveOnParse = (base64Image, filename = "image") => {
        var parseFile = new Parse.File("image.png", { base64: base64Image });
        return new Promise((resolve, reject) => 
            parseFile.save()
            .then((obj) => {
                obj._filename = filename
                return resolve(obj)
            })
            .catch((error) => {
                console.error(error)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Não foi possível salvar a imagem no formulário"
                })
                parseFile.revert()
                return reject(error)
            })
        )
    }

    const getCroppedCanvas = () => {
        if (cropper && cropper.current && cropper.current.cropper) {
            const crop = cropper.current.cropper
            const canvas = crop.getCroppedCanvas()
            if (canvas) {
                return canvas.toDataURL()
            }
        }
        return {}
    }

    useEffect(() => {
        setUploaded(image)
    }, [ image ])

    useEffect(() => {
        if (imageUrl) {
            setUploaded({
                __type: "File",
                name: "image.png",
                url: imageUrl,
                _url: imageUrl
            })
        }
    }, [ imageUrl ])

    return (
        <div className={`image-upload ${uploaded ? "is-uploaded" : ""} ${className || ""}`}>
            <input ref={uploader} type="file" accept="image/jpeg, image/png" onChange={onUpload} className="d-none" />
            <div className="upload-preview">
                { label && (<h3 className="upload-title mb-2">{ label }</h3>)}
                { text && (<p className="upload-text mb-2">{ text }</p>)}
                { !uploaded ? (
                    <button
                        className="btn-dashed"
                        onClick={() => handleUpload()}
                    >
                        { loading ? (
                            <CircularProgress size={28} thickness={2} />
                        ) : 
                            children
                        }
                    </button>
                ) : (
                    cropperOptions && !hasCropped ? (
                        <div className="d-flex d-md-block">
                            <Cropper
                                ref={cropper}
                                className="upload-image mb-md-3"
                                src={uploaded._url}
                                autoCropArea={1}
                                aspectRatio={cropperOptions.aspectRatio || 1 / 1}
                                guides={false}
                                crop={() => getCroppedCanvas()}
                            />
                            <div className="row flex-grow-1 justify-content-center align-items-center">
                                <div className="col-auto col-md pe-0">
                                    <button 
                                        className="vsbox-btn vsbox-btn-save w-100" 
                                        onClick={() => {
                                            setUploaded(null)
                                            setLoading(true)
                                            saveOnParse(getCroppedCanvas())
                                                .then(image => {
                                                    setHasCropped(true)
                                                    setUploaded(image)
                                                    setLoading(false)
                                                    getImage && getImage(image)
                                                })
                                        }}
                                    >
                                        Adicionar
                                    </button>
                                </div>
                                <div className="col-auto col-md">
                                    <button 
                                        className="vsbox-btn vsbox-btn-alert w-100" 
                                        onClick={onDelete}
                                        disabled={disabled || false}
                                    >
                                        Cancelar    
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex d-md-block">
                            { uploaded._url && (
                                <div
                                    className="uploaded-image mb-md-3"
                                    style={{
                                        backgroundImage: `url("${uploaded._url}")`
                                    }}
                                />
                            )}
                            <div className="row flex-grow-1 justify-content-center align-items-center">
                                <div className="col-auto col-md">
                                    <button 
                                        className="vsbox-btn vsbox-btn-alert w-100" 
                                        onClick={() => onDelete()}
                                        disabled={disabled || false}
                                    >
                                        Excluir    
                                    </button>
                                </div>
                                <div className="col-auto col-md ps-0">
                                    <button 
                                        className="vsbox-btn vsbox-btn-save w-100" 
                                        onClick={() => handleUpload()}
                                        disabled={disabled || false}
                                    >
                                        Substituir
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    )
}

export default VSImage;