import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import axios from 'axios';

import { CircularProgress, Radio, Switch } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';

import { ReactComponent as IconAdd } from "../../assets/icon-add.svg"
import { ReactComponent as IconWarning } from "../../assets/icon-warning.svg"

import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../constants'
import { getSelectedOptionLabel, dayjs, optionalField, saveFilesOnParse, toBase64, urlToBase64, imageUrlToParseFile, showSnackbar } from '../../utils'
import { IconRadioChecked, IconRadioUnchecked } from '../../components/vsRadio/VSRadio';
import { getBreeds, getGenders, getPelages, getSpecies, getTemperaments, months, sizeOptions, years } from '../patient/Patient';

import VSDrawer from '../../components/vsDrawer/VSDrawer';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import VSImage from '../../components/vsImage/VSImage';
import HealthAlert from './HealthAlert';
import TextFieldMask from '../../components/textFieldMask/TextFieldMask';
import PatientPhoto from '../../components/patientPhoto/PatientPhoto';
import VSError from '../../components/vsError/VSError';
import { resetHealthImport, setHealthImportPatient } from '../../store/actions';

import './HealthPatientForm.scss'
const HealthPatientForm = (props) => {

    const {
        clinic,
        history,
        health,
        permissions
    } = props

    const {
        pet,
        petlove,
        patient,
        patients,
        importPatientOnly
    } = health
    const owner = pet?.owner

    const isMerge = patient?.id

    const divergenceHelper = "Informações divergentes"

    const [ error, setError ] = useState(null)
    const [ saveLoading, setSaveLoading ] = useState(false)

    const [ name, setName ] = useState("")
    const [ nameOptions, setNameOptions ] = useState([])
    const [ divergenceName, setDivergenceName ] = useState(false)
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ specie, setSpecie ] = useState('')
    const [ species, setSpecies ] = useState([])
    const [ specieOptions, setSpecieOptions ] = useState([])
    const [ divergenceSpecie, setDivergenceSpecie ] = useState(false)
    const [ errorSpecie, setErrorSpecie ] = useState(false)
    const [ helperSpecie, setHelperSpecie ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ breed, setBreed ] = useState('')
    const [ breedOptions, setBreedOptions ] = useState([])
    const [ divergenceBreed, setDivergenceBreed ] = useState(false)
    const [ breeds, setBreeds ] = useState([])

    const [ gender, setGender ] = useState('')
    const [ genderOptions, setGenderOptions ] = useState([])
    const [ divergenceGender, setDivergenceGender ] = useState(false)
    const [ genders, setGenders ] = useState([])

    const [ pelage, setPelage ] = useState('')
    const [ pelages, setPelages ] = useState([])

    const [ temperament, setTemperament ] = useState('')
    const [ temperaments, setTemperaments ] = useState([])

    const [ castrated, setCastrated ] = useState('')
    const castratedOptions = [
        {
            value: "0",
            label: "Não"
        },
        {
            value: "1",
            label: "Sim"
        },
        {
            value: "2",
            label: "Indefinido"
        }
    ]

    const [ deceased, setDeceased ] = useState("0")

    const [ rga, setRga ] = useState('')
    const [ microchip, setMicrochip ] = useState('')
    const [ microchipOptions, setMicrochipOptions ] = useState([])
    const [ divergenceMicrochip, setDivergenceMicrochip ] = useState(false)

    const [ birthdate, setBirthdate ] = useState(null)
    const [ errorBirthdate, setErrorBirthdate ] = useState(false)
    const [ helperBirthdate, setHelperBirthdate ] = useState(optionalField)
    const [ birthdateText, setBirthdateText ] = useState('')
    const [ birthdateOptions, setBirthdateOptions ] = useState([])
    const [ divergenceBirthdate, setDivergenceBirthdate ] = useState(false)
    const [ ageYear, setAgeYear ] = useState('')
    const [ ageMonth, setAgeMonth ] = useState('')
    const [ size, setSize ] = useState('')
    const [ otherInfo, setOtherInfo ] = useState('')
    const [ notes, setNotes ] = useState('')
    const [ image, setImage ] = useState(null)
    const [ imageVetsmart, setImageVetsmart ] = useState(null)
    const [ imagePetlove, setImagePetlove ] = useState(null)
    const [ hasUploadedImage, setHasUploadedImage ] = useState(false)
    const [ selectedImage, setSelectedImage ] = useState(null)

    const handleSpecie = (selected) => {
        const specie = selected
        setSpecie(specie)
    }

    const handleBirthdate = (_date) => {
        setBirthdateText(_date)

        if (_date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g)) {
            let newDate = dayjs(_date, "DD/MM/YYYY")
            if (newDate.isValid()) {
                setBirthdate(newDate.toDate())
                const age = dayjs.duration(dayjs().diff(newDate))
                if (age.years() > 0 || age.months() > 0) {
                    setAgeYear(String(age.years()))
                    setAgeMonth(String(age.months()))
                }
                return
            }
        }
        setBirthdate(null)
        setAgeMonth('')
        setAgeYear('')
    }

    const handleAge = (_years, _months) => {
        const years = parseInt(_years) || 0
        const months = parseInt(_months) || 0
        const date = dayjs().subtract(years, 'years').subtract(months, 'months')
        setAgeYear(String(years))
        setAgeMonth(String(months))
        if (date.isValid()) {
            setBirthdate(date.toDate())
            setBirthdateText(date.format("DD/MM/YYYY"))
        } else {
            setBirthdate(null)
            setBirthdateText("")
        }
    }

    const validateOnTouch = () => {
        if (name || specie || birthdateText || ageYear || ageMonth || gender || pelage || temperament || microchip || castrated || notes) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (saveLoading) {
            isValid = false
        }
        if (error?.message) {
            isValid = false
        }

        if (!name) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome do animal é obrigatório")
        } else {
            setDivergenceName(false)
            setErrorName(false)
            setHelperName(<span>Campo <b>Obrigatório</b></span>)
        }

        if (!specie) {
            isValid = false
            setErrorSpecie(true)
            setHelperSpecie("A espécie do animal é obrigatória")
        } else {
            if (specieOptions.length == 2) {
                setSpecieOptions(species)
            }
            setDivergenceSpecie(false)
            setErrorSpecie(false)
            setHelperSpecie(<span>Campo <b>Obrigatório</b></span>)
        }

        const _birthdate = dayjs(birthdate)
        if (birthdate || birthdateText.replace(/[^0-9]/g, '').length > 0 || ageYear.length > 0 || ageMonth.length > 0) {
            setDivergenceBirthdate(false)
            if (_birthdate.isValid()) {
                setErrorBirthdate(false)
                setHelperBirthdate(optionalField)
            } else {
                isValid = false
                setErrorBirthdate(true)
                setHelperBirthdate("A data informada não é válida")
            }
        } else {
            setErrorBirthdate(false)
            setHelperBirthdate(optionalField)
        }

        if (breed) {
            setDivergenceBreed(false)
            if (breedOptions.length == 2 && breeds.length) {
                setBreedOptions(breeds)
            }
        }

        if (microchip) {
            setDivergenceMicrochip(false)
        }

        if (gender) {
            setDivergenceGender(false)
            if (genderOptions.length == 2) {
                setGenderOptions(genders)
            }
        }

        return isValid
    }

    const isValid = () => !saveLoading && !errorName && !divergenceName && !errorSpecie && !divergenceSpecie && !errorBirthdate && !divergenceBreed && !divergenceMicrochip && !divergenceGender

    const fieldsWithErrorText = () => {
        let errors = []
        if (saveLoading) {
            errors.push("Salvando o animal")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorName || divergenceName) {
            errors.push("Nome")
        }
        if (errorSpecie || divergenceSpecie) {
            errors.push("Espécie")
        }
        if (errorBirthdate || divergenceBirthdate) {
            errors.push("Data de nascimento")
        }
        if (divergenceBreed) {
            errors.push("Raça")
        }
        if (divergenceGender) {
            errors.push("Sexo")
        }
        if (divergenceMicrochip) {
            errors.push("Microchip")
        }
        if (!permissions?.check(ACL.PATIENT, ACL.Rule.EDIT, patient)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = async () => {
        return new Promise(async (resolve, reject) => {
            if (validate()) {
                const data = {
                    id: patient?.id,
                    name: name,
                    specie: specie,
                    breed: breed,
                    microchip: microchip,
                    birthdate: birthdate,
                    gender: gender,
                    temperament: temperament,
                    size: size,
                    pelage: pelage,
                    castrated: castrated,
                    deceased: deceased,
                    rga: rga,
                    otherInfo: otherInfo,
                    notes: notes,
                    image: selectedImage == "petlove" ? imagePetlove : (selectedImage == "vetsmart" ? imageVetsmart : image),
                    selectedImage: selectedImage,
                    patient: patient,
                    selected: patient ? "unify" : "new",
                    petloveSaudeId: pet?.id,
                    petloveSaudeData: pet
                }
                if (importPatientOnly) {
                    setError(null)
                    setSaveLoading(true)
                    const saveData = {...data}
                    delete saveData.patient
                    delete saveData.selected
                    delete saveData.selectedImage
                    if (patient?.image && typeof patient.image == "string") {
                        patient.image = await imageUrlToParseFile(patient.image)
                    }
                    const params = {
                        data: {
                            ...saveData,
                            objectId: patient?.id,
                            image: patient?.image,
                            petloveSaudeId: String(saveData?.petloveSaudeId)
                        },
                        clinicId: clinic.objectId,
                        ownerId: health?.owner?.id,
                        appVersionName: APP_VERSION,
                        deviceType: APP_DEVICE,
                        appContentType: APP_NAME
                    }
                    Parse.Cloud.run("savePatientForOwner", params)
                    .then(_patient => {
                        setSaveLoading(false)
                        showSnackbar(`Cadastro ${patient?.id ? "atualizado" : "realizado"} com sucesso!`)
                        history.push(`/animal/${_patient?.id}`)
                        props.dispatch(resetHealthImport())
                        return reject() // prevent onAfterSave
                    })
                    .catch(error => {
                        console.error(error)
                        const errorMessage = error?.message || `Não foi possível ${patient?.id ? "atualizar" : "cadastrar"} o animal`
                        setSaveLoading(false)
                        setError({
                            message: errorMessage,
                            function: "save"                
                        })
                        return reject()
                    })
                } else {
                    const index = petlove.pets.findIndex(_pet => _pet?.id == pet?.id)
                    const newPatients = [...patients || []]
                    newPatients[index] = data

                    props.dispatch(setHealthImportPatient(newPatients))
                    return resolve(data)
                }
            } else {
                reject()
            }
        })
    }

    const uniqueNonEmptyOptions = (...options) => {
        const unique = [...new Set(options)]
        return unique.filter(x => x)
    }

    const setField = (options, setOptions, setField, setDivergence) => {
        setOptions(options)
        if (options.length == 2) {
            setDivergence(true)
        } else {
            setField(options.find(x => x) || "")
        }
    }

    useEffect(async () => {
        if (specie) {
            const _breeds = await getBreeds(specie)
            setBreeds(_breeds)
            if (breedOptions.length != 2) {
                setBreedOptions(_breeds)
            }
        }
    }, [ specie ])

    useEffect(() => {
        validateOnTouch()
    }, [ name, specie, breed, gender, birthdate, birthdateText, ageYear, ageMonth, microchip ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            getPelages().then(_pelages => setPelages(_pelages))
            getTemperaments().then(_temperaments => setTemperaments(_temperaments))
        }
    }, [ clinic ])

    useEffect(async () => {
        const _species = await getSpecies()
        setSpecies(_species)
        const _genders = await getGenders()
        setGenders(_genders)
        if (patient) {
            const _nameOptions = uniqueNonEmptyOptions(patient?.get("name"), pet?.name)
            setField(_nameOptions, setNameOptions, setName, setDivergenceName)
            const _speciesOptions = uniqueNonEmptyOptions(patient?.get("specie"), pet?.specie)
            setField(_speciesOptions.length == 2 ? _speciesOptions : _species, setSpecieOptions, setSpecie, setDivergenceSpecie)
            const _breedOptions = uniqueNonEmptyOptions(patient?.get("breed"), pet?.race)
            setField(_breedOptions, setBreedOptions, setBreed, setDivergenceBreed)
            const _genderOptions = uniqueNonEmptyOptions(patient?.get("gender"), pet?.gender)
            setField(_genderOptions.length == 2 ? _genderOptions : _genders, setGenderOptions, setGender, setDivergenceGender)
            const _microchipOptions = uniqueNonEmptyOptions(patient?.get("microchip"), pet?.num_chip)
            setField(_microchipOptions, setMicrochipOptions, setMicrochip, setDivergenceMicrochip)
            const birthdateVetsmartFormatted = patient?.get("birthdate") ? dayjs(patient?.get("birthdate")).format("DD/MM/YYYY") : ""
            const birthdatePetloveFormatted = pet?.dt_birthday ? dayjs(pet?.dt_birthday).format("DD/MM/YYYY") : ""
            const _birthdateOptions = uniqueNonEmptyOptions(birthdateVetsmartFormatted, birthdatePetloveFormatted)
            setField(_birthdateOptions, setBirthdateOptions, handleBirthdate, setDivergenceBirthdate)
            const vetsmartImage = patient?.get("image")
            const petloveImage = pet?.avatar
            if (vetsmartImage) {
                setImageVetsmart(vetsmartImage)
                if (!petloveImage || pet?.selectedImage == "vetsmart") {
                    setSelectedImage("vetsmart")
                }
            }
            if (petloveImage) {
                setImagePetlove(petloveImage)
                if (!vetsmartImage || pet?.selectedImage == "petlove") {
                    setSelectedImage("petlove")
                }
            }
        } else if (pet) {
            setName(pet?.name || "")
            setSpecie(pet?.specie || "")
            setSpecieOptions(_species)
            setBreed(pet?.race || "")
            setGender(pet?.gender || "")
            setGenderOptions(_genders)
            setMicrochip(pet?.num_chip || "")
            handleBirthdate(pet?.dt_birthday ? dayjs(pet?.dt_birthday).format("DD/MM/YYYY") : "")
            if (pet?.avatar) {
                setImagePetlove(pet?.avatar)
                setSelectedImage("petlove")
            }
        }
        setDeceased(pet?.deceased || "0")
        setRga(pet?.rga || "")
        setPelage(pet?.pelage || "")
        setCastrated(pet?.castrated || "")
        setTemperament(pet?.temperament || "")
        setSize(pet?.size || "")
        setOtherInfo(pet?.otherInfo || "")
        setNotes(pet?.notes || "")
    }, [ health ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="health-patient-form"
            title={isMerge ? "Atualizar Cadastro de Animal" : "Novo Cadastro de Animal"}
            cancel={"Cancelar Alterações"}
            submit={"Avançar"}
            errors={errors}
            timeout={0}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => history.replace(`/petlove-saude/animal`)}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar a operação?",
                confirm: "Cancelar Alterações",
                cancel: "Voltar para o Cadastro"
            }}
        >
            { saveLoading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "save":
                                save()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <>
                    { isMerge ? (
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="input-title">
                                    2 - Selecione quais Informações do animal deseja manter
                                </div>
                            </div>
                            <div className="col-12">
                                <p className="text-default">
                                    Se houver diferenças nas informações, 
                                    os campos serão destacados em vermelho no formulário. 
                                    Escolha quais informações deseja manter no cadastro.
                                </p>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="vssearch">
                                <div className="vssearch-area">
                                    <div className="row flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="col">
                                            <div className="vssearch-text">
                                                { owner?.name }
                                            </div>
                                            <div className="vssearch-result">
                                                CPF: { owner?.cpf || "Não informado" }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col">
                                    <p className="my-3" style={{ 
                                        textTransform: "uppercase",
                                        fontWeight: 600
                                    }}>
                                        Animal está vivo?
                                    </p>
                                </div>
                                <div className="col-auto">
                                    <Switch
                                        checked={deceased != "1"}
                                        onChange={({ target: { checked } }) => setDeceased(checked ? "0" : "1")}
                                        color="primary"
                                    />
                                </div>
                            </div>
                            { deceased == "1" && deceased != patient?.get("deceased") && (
                                <div className="vsalert vsalert-warning mb-3">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <IconWarning width="24px" height="24px" />
                                        </div>
                                        <div className="col ps-0">
                                            <h4 className="vsalert-title">Você definiu que o animal não esta vivo.</h4>
                                            <p className="vsalert-text">Ao salvar essa alteração, todas as programações de vacinas e as próximas vacinas vinculadas a este animal serão excluídas. Elas não poderão ser recuperadas e esta operação não poderá ser desfeita.</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr/>
                        </>
                    )}
                    <div className="row mt-2">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Identificação"
                                )}
                            >
                                <div className={`row ${isMerge ? "" : "flex-nowrap flex-column flex-md-row"}`}>
                                    { isMerge && (imagePetlove || imageVetsmart) ? (
                                        <div className="col-12 mb-3">
                                            <div className="row">
                                                { !hasUploadedImage && (
                                                    <>
                                                        { imagePetlove && (
                                                            <div className="col col-md-auto mb-3">
                                                                <div
                                                                    className="row"
                                                                    onClick={() => {
                                                                        setSelectedImage("petlove")
                                                                    }}
                                                                >
                                                                    <div className="col-auto pe-1">
                                                                        <Radio
                                                                            checkedIcon={IconRadioChecked(false)}
                                                                            icon={IconRadioUnchecked(false)}
                                                                            checked={selectedImage == "petlove"}
                                                                        />
                                                                    </div>
                                                                    <div className="col ps-0">
                                                                        <PatientPhoto patientImage={pet?.avatar} patientSpecie={pet?.specie} width={100} height={100} withBorder />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        { imageVetsmart && (
                                                            <div className="col col-md-auto mb-3">
                                                                <div
                                                                    className="row"
                                                                    onClick={() => {
                                                                        setSelectedImage("vetsmart")
                                                                    }}
                                                                >
                                                                    <div className="col-auto pe-1">
                                                                        <Radio
                                                                            checkedIcon={IconRadioChecked(false)}
                                                                            icon={IconRadioUnchecked(false)}
                                                                            checked={selectedImage == "vetsmart"}
                                                                        />
                                                                    </div>
                                                                    <div className="col ps-0">
                                                                        <PatientPhoto patient={patient} width={100} height={100} withBorder />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                <div className="col-12 col-md mb-3">
                                                    <VSImage
                                                        className={image ? "mx-auto" : "image-merge"}
                                                        getImage={(image) => {
                                                            setHasUploadedImage(true)
                                                            setSelectedImage(null)
                                                            setImage(image)
                                                        }}
                                                        onDelete={() => {
                                                            setHasUploadedImage(false)
                                                            setImage(null)
                                                        }}
                                                        image={image}
                                                        cropperOptions={{
                                                            aspectRatio: 1 / 1
                                                        }}
                                                        style={{
                                                            image: {
                                                                width: '82px',
                                                                height: '82px',
                                                                borderRadius: '50%'
                                                            },
                                                            buttons: {
                                                                width: 'calc(100% - 168px)',
                                                                float: 'right',
                                                                height: '148px',
                                                                alignItems: 'center',
                                                                changeButton: {
                                                                    paddingLeft: 0
                                                                },
                                                                deleteButton: {
                                                                    paddingLeft: 0
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <IconAdd width="24" height="24" />
                                                        Adicionar Foto de Perfil<br/> 
                                                        (opcional)
                                                    </VSImage>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-12 col-md-auto flex-shrink-0 mb-3">
                                            <VSImage
                                                className="w-100"
                                                getImage={(image) => {
                                                    setSelectedImage(null)
                                                    setImage(image)
                                                }}
                                                image={image}
                                                imageUrl={imagePetlove}
                                                cropperOptions={{
                                                    aspectRatio: 1 / 1
                                                }}
                                                style={{
                                                    image: {
                                                        width: '82px',
                                                        height: '82px',
                                                        borderRadius: '50%'
                                                    },
                                                    buttons: {
                                                        width: 'calc(100% - 168px)',
                                                        float: 'right',
                                                        height: '148px',
                                                        alignItems: 'center',
                                                        changeButton: {
                                                            paddingLeft: 0
                                                        },
                                                        deleteButton: {
                                                            paddingLeft: 0
                                                        }
                                                    }
                                                }}
                                            >
                                                <IconAdd width="24" height="24" />
                                                Adicionar<br/> 
                                                Foto de Perfil<br/> 
                                                (opcional)
                                            </VSImage>
                                        </div>
                                    )}
                                    <div className={`col-12 ${isMerge ? "" : "col-md-auto flex-shrink-1"}`}>
                                        <div className="row">
                                            <div className="col-12 col-sm-7 col-xl-12 order-1 mb-3">
                                                <Autocomplete
                                                    inputValue={name}
                                                    options={nameOptions}
                                                    filterOptions={(options) => options}
                                                    onChange={(_, selected) => setName(selected || "")}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Nome do animal"
                                                            label={`${divergenceName ? "Selecione o " : ""}Nome do animal`}
                                                            onChange={({ target: { value } }) => setName(value)}
                                                            error={divergenceName || errorName}
                                                            helperText={divergenceName ? divergenceHelper : helperName}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    disableClearable
                                                    openOnFocus
                                                />
                                            </div>
                                            <div className="col-12 col-sm-5 col-xl-4 order-2 mb-3">
                                                <Autocomplete
                                                    inputValue={microchip}
                                                    options={microchipOptions}
                                                    filterOptions={(options) => options}
                                                    onChange={(_, selected) => setMicrochip(selected || "")}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="000000000000000"
                                                            label={`${divergenceMicrochip ? "Selecione o " : ""}Microchip`}
                                                            onChange={({ target: { value } }) => setMicrochip(value)}
                                                            error={divergenceMicrochip}
                                                            helperText={divergenceMicrochip ? divergenceHelper : optionalField}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    disableClearable
                                                    openOnFocus
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6 col-xl-4 order-4 order-xl-3 mb-3">
                                                <Autocomplete
                                                    inputValue={specie}
                                                    options={specieOptions}
                                                    onChange={(_, selected) => handleSpecie(selected || "")}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Espécie do animal"
                                                            label={`${divergenceSpecie ? "Selecione a " : ""}Espécie`}
                                                            onChange={({ target: { value } }) => handleSpecie(value)}
                                                            error={divergenceSpecie || errorSpecie}
                                                            helperText={divergenceSpecie ? divergenceHelper : helperSpecie}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                />
                                            </div>
                                            <div className="col-12 col-sm-12 col-xl-4 order-5 order-sm-6 order-xl-4 mb-3">
                                                <Autocomplete
                                                    inputValue={breed}
                                                    options={breedOptions}
                                                    onChange={(_, selected) => setBreed(selected || '')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Raça do animal"
                                                            label={`${divergenceBreed ? "Selecione a " : ""}Raça`}
                                                            onChange={({ target: { value } }) => setBreed(value)}
                                                            error={divergenceBreed}
                                                            helperText={divergenceBreed ? divergenceHelper : optionalField}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                />
                                            </div>
                                            { health && (
                                                <div className="col-12 order-3 order-xl-5 mb-3">
                                                    <HealthAlert
                                                        type="success"
                                                        pet={pet}
                                                    />
                                                </div>
                                            )}
                                            <div className="col-12 col-xl-3 col-xxl-4 order-6 order-sm-5 order-xl-6 mb-3">
                                                <Autocomplete
                                                    value={gender}
                                                    options={genderOptions}
                                                    onChange={(_, selected) => setGender(selected || '')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Sexo do animal"
                                                            label={`${divergenceGender ? "Selecione o " : ""}Sexo`}
                                                            error={divergenceGender}
                                                            helperText={divergenceGender ? divergenceHelper : optionalField}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                />
                                            </div>
                                            <div className="col-12 col-sm order-7 mb-3">
                                                <Autocomplete
                                                    inputValue={birthdateText}
                                                    options={birthdateOptions}
                                                    filterOptions={(options) => options}
                                                    onChange={(_, selected) => handleBirthdate(selected || "")}
                                                    renderInput={(params) => (
                                                        <TextFieldMask
                                                            {...params}
                                                            type="tel"
                                                            mask='00/00/0000'
                                                            placeholder="00/00/0000"
                                                            label={`${divergenceBirthdate ? "Selecione a " : ""}Data de nascimento`}
                                                            onChange={({ target: { value } }) => handleBirthdate(value)}
                                                            onAccept={(value) => handleBirthdate(value)}
                                                            error={divergenceBirthdate || errorBirthdate}
                                                            helperText={divergenceBirthdate ? divergenceHelper : helperBirthdate}
                                                            style={{
                                                                minWidth: "140px"
                                                            }}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    disableClearable
                                                    openOnFocus
                                                />
                                            </div>
                                            <div className="col-2 col-sm-auto order-8 mb-3 text-center px-0">
                                                <div className="separator-text">OU</div>
                                            </div>
                                            <div className="col-10 col-sm order-9">
                                                <div className="row">
                                                    <div className="col-6 pe-1 mb-3">
                                                        <Autocomplete
                                                            value={ageYear}
                                                            options={years}
                                                            onChange={(_, selected) => handleAge(selected, ageMonth)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    type="tel"
                                                                    placeholder="0"
                                                                    label="Anos"
                                                                    size="small"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            )}
                                                            openOnFocus
                                                        />
                                                    </div>
                                                    <div className="col-6 ps-1 mb-3">
                                                        <Autocomplete
                                                            value={ageMonth}
                                                            options={months}
                                                            onChange={(_, selected) => handleAge(ageYear, selected)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    type="tel"
                                                                    placeholder="0"
                                                                    label="Meses"
                                                                    size="small"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            )}
                                                            openOnFocus
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Dados Complementares"
                                )}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6 order-1 mb-3">
                                                <Autocomplete
                                                    inputValue={temperament}
                                                    options={temperaments}
                                                    onChange={(_, selected) => setTemperament(selected || '')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Temperamento do animal"
                                                            label="Temperamento"
                                                            onChange={({ target: { value } }) => setTemperament(value)}
                                                            helperText={<span>Campo Opcional<br/></span>}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                />
                                            </div>
                                            <div className="col-12 order-3 mb-3">
                                                <Autocomplete
                                                    inputValue={pelage}
                                                    options={pelages}
                                                    onChange={(_, selected) => setPelage(selected || '')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Pelagem do animal"
                                                            label="Pelagem"
                                                            onChange={({ target: { value } }) => setPelage(value)}
                                                            helperText={<span>Campo Opcional<br/></span>}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                />
                                            </div>
                                            <div className="col-6 order-2 mb-3">
                                                <Autocomplete
                                                    value={size || null}
                                                    options={sizeOptions}
                                                    onChange={(_, selected) => setSize(selected || '')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Porte do animal"
                                                            label="Porte"
                                                            helperText={optionalField}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                />
                                            </div>
                                            <div className="col-6 order-4 mb-3">
                                                <Autocomplete
                                                    value={castrated}
                                                    options={castratedOptions}
                                                    getOptionLabel={(option) => getSelectedOptionLabel(option, castratedOptions, castrated)}
                                                    getOptionSelected={(option) => option.value === castrated}
                                                    onChange={(_, selected) => setCastrated(selected?.value || '')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="O animal foi castrado?"
                                                            label="Castrado"
                                                            helperText={<span>Campo Opcional<br/></span>}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                />
                                            </div>
                                            <div className="col-6 order-5">
                                                <TextField
                                                    placeholder="0.000.000"
                                                    label="RGA"
                                                    value={rga}
                                                    onChange={({ target: { value } }) => setRga(value)}
                                                    helperText={optionalField}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3 mt-md-0 mb-3">
                                        <TextField
                                            placeholder="Outros elementos de identificação do animal"
                                            label="Outras informações"
                                            value={otherInfo}
                                            onChange={({ target: { value } }) => setOtherInfo(value)}
                                            helperText={optionalField}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col-12 mb-3">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Observações Internas"
                                )}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <TextField
                                            placeholder="Anotações particulares (temperamento, característica, etc.)"
                                            label="Observações do animal"
                                            value={notes}
                                            onChange={({ target: { value } }) => setNotes(value)}
                                            helperText={<span>Campo Opcional<br/></span>}
                                            size="small"
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col input-info">
                                        As observações são anotações particulares suas sobre o animal,
                                        como temperamento, alguma característica específica do animal, etc. <b>Elas nunca serão compartilhadas com o tutor!</b>
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                        { patient && (
                        <div className="col-12">
                            <HealthAlert
                                type="info"
                                text="As informações unificadas se restringem a parte de cadastro, o histórico de registros dos animais cadastrados na clínica não sofrerão alterações."
                            />
                        </div>
                        )}
                    </div>
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        health: state.health,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(HealthPatientForm);