import React from "react"
import { connect } from "react-redux"

import HealthTag from "./HealthTag"
import PatientPhoto from "../../components/patientPhoto/PatientPhoto"

import "./HealthPatientsCard.scss"
const HealthPatientsCard = (props) => {

    const {
        health
    } = props

    const total = health?.petlove?.pets?.length

    return (
        <div id="health-patients-card">
            <div className="card">
                <div className="card-owner">
                    <div className="row">
                        <div className="col-auto mb-3">
                            <HealthTag />
                        </div>
                        <div className="col-12 mb-1">
                            <b>{ health?.petlove?.name }</b>
                        </div>
                        <div className="col-12">
                            CPF { health?.petlove?.cpf }
                        </div>
                    </div>
                </div>
                <div className="card-patients">
                    <div className="row">
                        <div className="col-auto">
                            <div className="avatar-stack">
                                { health?.petlove?.pets?.map((pet, index) => {
                                    if (index < 3) {
                                        return <PatientPhoto key={index} patientImage={pet.avatar} patientSpecie={pet.specie} width={40} height={40} className="avatar" withBorder />
                                    } else if (index == 3) {
                                        return (
                                            <span key={index} className="avatar avatar-custom">+{ health.petlove.pets.length - 3 }</span>
                                        )
                                    }
                                    return
                                }) }
                            </div>
                        </div>
                        <div className="col">
                            Total de { total } anima{ total == 1 ? "l" : "is" }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        health: state.health
    };
};

export default connect(mapStateToProps)(HealthPatientsCard)