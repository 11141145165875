import React from "react"
import { dayjs } from "../../utils"

import { ReactComponent as IconWarning } from "../../assets/icon-simple-warning.svg"
import { ReactComponent as IconCheckO } from "../../assets/icon-primary-check-o.svg"

import "./HealthAlert.scss"
const HealthAlert = (props) => {

    const {
        type = "warning",
        text,
        buttonText,
        pet,
        onClick
    } = props

    return (
        <div id="health-alert" className={`alert alert-${type}`}>
            <div className="row align-items-baseline align-items-lg-center">
                <div className="col-auto pe-2">
                    { type == "success" ? (
                        <IconCheckO width="20" height="20" />
                    ) : (
                        <IconWarning width="20" height="20" />
                    )}
                </div>
                <div className="col ps-1">
                    <div className="row align-items-center">
                        { type == "success" ? (
                            <div className="col-12 col-lg">
                                { text || (
                                    <>
                                        Petlove Saúde: <b>{ pet?.plan?.name }</b>
                                        { pet?.chip_temporary_expire_at && (
                                            <>
                                                &nbsp;| Microchip provisório • Expira em: <b>{ dayjs(pet?.chip_temporary_expire_at).format("DD/MM/YYYY") }</b>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        ) : (
                            <>
                                <div className="col-12 col-lg">
                                    { text || (
                                        <>
                                            Encontramos um cadastro semelhante na Petlove Saúde.&nbsp;
                                            { type == "warning" ? "Confira e escolha as informações que deseja manter." : "Deseja importar as informações de cadastro?" }
                                        </>
                                    )}
                                </div>
                                { onClick && (
                                    <div className="col-auto">
                                        <div
                                            className="alert-button"
                                            onClick={() => onClick()}
                                        >
                                            { buttonText || (type == "warning" ? "Ver duplicidade" : "Importar cadastro") }
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HealthAlert